import {
  BuriedOrderStatus,
  CommoditySku,
  Element,
  BrowseAndLeavePage,
  GetCoupon,
  Order,
  OuterService,
  OuterServiceType,
  PageShare,
  Search,
  SkuToCart,
  Component
} from '../mall-tracking/types'
import { Chan, chanPropMap } from './types/chan'

import { DataAdapter } from './types/dataAdapter'
import { SendKeyEnum } from './type-map/index'
import { User } from './types/user'
import { srInstance } from '../mall-tracking-sr/sr-sdk-instance'
import { fenToYuan, filterEmptyValue, isInApp } from '@/utils'
import { aegis } from '@/common/aegis'

const srAdapter: DataAdapter = {
  start() {
    srInstance.startReport()
  },
  setUser(user?: User) {
    srInstance.setUser(user)
  },

  /**
   * 设置渠道信息
   *
   * @param {Chan} chan
   */
  setChan(chan: Chan) {
    const srChan: any = {}
    const chanKeys = Object.keys(chan)
    chanKeys.forEach((prop) => {
      const realProp = chanPropMap[prop]
      if (realProp) {
        srChan[realProp] = (chan[prop] ?? '').toString()
      }
    })
    if (chan.chanCustom) {
      srChan.chan_custom = {
        // 要求四级渠道齐全
        chan_custom_id: chan.chanCustom.chanCustomId,
        chan_custom_id_desc: chan.chanCustom.chanCustomIdDesc,
        chan_custom_cat_3: chan.chanCustom.chanCustomIdCat1,
        chan_custom_cat_3_desc: chan.chanCustom.chanCustomIdCat1Desc,
        chan_custom_cat_2: chan.chanCustom.chanCustomIdCat2,
        chan_custom_cat_2_desc: chan.chanCustom.chanCustomIdCat2Desc,
        chan_custom_cat_1: chan.chanCustom.chanCustomIdCat3,
        chan_custom_cat_1_desc: chan.chanCustom.chanCustomIdCat3Desc
      }
    } else {
      // 无自定义渠道信息时也要上报字段
      srChan.chan_custom = {
        chan_custom_id: 'default'
      }
    }
    // 无extra字段时 默认上报{}
    if (!chan.extra) {
      srChan.extra = {}
    }
    srInstance.setChan(srChan)
  },
  setContext(obj: Record<string, any>) {
    srInstance.setContext(obj)
  },

  /**
   * 封装send函数，转换传参格式
   *
   * @param {string} type
   * @param {Record<string, any>} props
   * @param {Record<string, any>} extProps
   */
  // eslint-disable-next-line complexity
  send(type: string, props: Record<string, any>, extProps: Record<string, any>) {
    let trackProps = {}
    switch (type) {
      case SendKeyEnum.PageShareAppMessage:
        trackProps = translatePageShareAppMessage(props as PageShare)
        break
      case SendKeyEnum.Element:
        trackProps = translateElement(props as Element)
        break
      case SendKeyEnum.WebBrowsePage:
      case SendKeyEnum.BrowseWxappPage:
        trackProps = translateBrowsePage(props as BrowseAndLeavePage)
        break
      case SendKeyEnum.LeavePage:
      case SendKeyEnum.LeaveWxappPage:
        trackProps = translateLeavePage(props as BrowseAndLeavePage)
        break
      case SendKeyEnum.BrowseSkuPage:
        trackProps = translateBrowserSkuPage(props as CommoditySku)
        break
      case SendKeyEnum.ExposeSkuComponent:
        if (extProps.isComponent) {
          // isComponent代表是组件曝光事件，不是商品曝光事件
          trackProps = translateExposeComponent(props as Component)
        } else {
          // todo：任务组件的曝光使用的是这个名称SendKeyEnum.ExposeSkuComponent，无需转换参数格式，后续有商品组件曝光时再做判断
          if (props.isGoodsExpose) {
            trackProps = translateExposeSkuComponent(props as CommoditySku)
            console.log(trackProps)
          } else {
            trackProps = props
          }
        }
        break
      case SendKeyEnum.TriggerSkuComponent:
        trackProps = translateTriggerSkuComponent(props as CommoditySku)
        break
      case SendKeyEnum.AddToCart:
        trackProps = translateAddToCart(props as SkuToCart)
        break
      case SendKeyEnum.GetCoupon:
        trackProps = translateGetCoupon(props as GetCoupon)
        break
      case SendKeyEnum.CustomOrder:
        trackProps = translateCustomOrder(props as Order)
        break
      case SendKeyEnum.Search:
        trackProps = translateSearch(props as Search)
        break

      default:
        trackProps = props
        break
    }

    srInstance.track(type, {
      page_id: 'default',
      page_type: 'default',
      event_name: 'default', // 没有event_name的默认上报default
      page: window.location.href,
      timeIn: window._page_start_time ? window._page_start_time : Date.now(),
      ...extProps,
      ...trackProps,
      log_id: getLogId() // 新增log_id字段，会话id，在一次会话中按事件发生顺序取值，从1开始
    })

    try {
      if (isInApp()) {
        if (type === SendKeyEnum.BrowseWxappPage) {
          const stacks = srInstance.queue.getItems()
          const obj = stacks.filter((item: any) => item.type === SendKeyEnum.BrowseWxappPage)?.[0]
          obj &&
            aegis.report({
              msg: {
                ...obj?.props,
                ...srInstance.context,
                type: SendKeyEnum.BrowseWxappPage
              }
            })
        } else if (type === SendKeyEnum.LeaveWxappPage) {
          const stacks = srInstance.queue.getItems()
          const obj = stacks.filter((item: any) => item.type === SendKeyEnum.LeaveWxappPage)?.[0]
          const key = 'leave_page_id'
          const dataStr = sessionStorage.getItem(key) || '{}'
          if (dataStr !== '{}') {
            // 已经上报过了 不要重复上报
            sessionStorage.setItem(key, '{}')
          } else {
            sessionStorage.setItem(key, '{ success: true }')
            aegis.report({
              msg: {
                ...obj?.props,
                ...srInstance.context,
                type: SendKeyEnum.LeaveWxappPage
              }
            })
          }
        }
      }
    } catch (err) {
      console.error(err)
    }
  }
}

/**
 * 根据tracking_id生成log_id
 * @returns number
 */
function getLogId() {
  const trackingId = srInstance.tracking_id
  const key = 'sr_log_id_obj'
  try {
    const dataStr = sessionStorage.getItem(key) || '{}'
    const data = JSON.parse(dataStr)
    if (data[trackingId]) {
      data[trackingId]++
    } else {
      data[trackingId] = 1
    }
    sessionStorage.setItem(key, JSON.stringify(data))
    return data[trackingId]
  } catch (error) {
    sessionStorage.setItem(key, JSON.stringify({ [trackingId]: 1 }))
  }
}

// 页面分享调用函数
function translatePageShareAppMessage(data: PageShare): Record<string, any> {
  return {
    from_type: data.formType,
    share_title: data.shareTitle,
    share_path: data.sharePath ?? '',
    share_image_url: data.shareImageUrl ?? '',
    share_to: data.shareTo
  }
}

function translateExposeComponent(exposeData: Component): Record<string, any> {
  return {
    event_tracking: exposeData.eventTrackingId,
    time: new Date().valueOf(),
    component: {
      component_id: exposeData.componentId,
      component_name: exposeData.componentName,
      component_position: exposeData.componentPosition,
      position_id: exposeData.positionId
    }
  }
}

// 元素事件调用函数
function translateElement(data: Element): Record<string, any> {
  const params: Record<string, any> = {
    type: data.type ?? 'tap',
    element_id: data.elementId,
    index: data.index ?? '',
    name: data.name ?? '',
    event_tracking_id: data.eventTrackingId ?? '',
    activityInfo: data.activityInfo || '',
    page_id: data.pageId || '',
    page_title: data.pageTitle || '',
    page_type: data.pageType || '',
    event_name: data.eventName || ''
  }

  if (data.componentId || data.componentName) {
    params.component = {
      component_id: data.componentId || '',
      component_name: data.componentName || ''
    }
  }
  return filterEmptyValue(params)
}

// 浏览页面
function translateBrowsePage(data: BrowseAndLeavePage): Record<string, any> {
  return {
    name: data.name ?? '',
    activityInfo: data.activityInfo || '',
    refer_page: data.refer_page || 'default',
    is_newly_open: data.is_newly_open
  }
}

// 离开页面
function translateLeavePage(data: BrowseAndLeavePage): Record<string, any> {
  return {
    name: data.name ?? '',
    activityInfo: data.activityInfo || '',
    timeOut: Date.now(),
    stay_time: data.stay_time || 'default'
  }
}

// 第三方服务信息格式转换
function translateOuterService(data: OuterService | undefined): Record<string, any> {
  if (data === undefined) return {}
  if (data.type === OuterServiceType.outerServiceInfo) {
    return {
      outer_service: {
        service_type: data.serviceType,
        scene_id: data.sceneId,
        algid: data.algid
      }
    }
  }
  if (data.type === OuterServiceType.activityInfo) {
    return {
      activity_info: {
        activity_type: data.activityType,
        activity_id: data.activityId,
        activity_name: data.activityName
      }
    }
  }
  return {}
}

// 商品页浏览调用函数
function translateBrowserSkuPage(browserData: CommoditySku): Record<string, any> {
  const outerServiceInfo = translateOuterService(browserData.outerService)
  return {
    sku: {
      sku_id: browserData.id,
      sku_name: browserData.name
    },
    spu: {
      spu_id: browserData.spuId,
      spu_name: browserData.spuName
    },
    // sku_category: Object.assign({}, data.skuCategory),
    shipping_shop: {
      shipping_shop_id: browserData?.storeId && browserData?.storeId.toString(),
      shipping_shop_name: browserData.storeName
    },
    sale: {
      current_price: Number(browserData.currentPrice ?? 0) / 100, // 字符串转数字
      original_price: Number(browserData.originalPrice ?? 0) / 100
    },
    primary_image_url: browserData.primaryImageUrl,
    ...outerServiceInfo
  }
}

// 商品卡曝光调用函数
function translateExposeSkuComponent(exposeData: CommoditySku): Record<string, any> {
  const outerServiceInfo = translateOuterService(exposeData.outerService)
  const shopName = exposeData?.storeName ? { shipping_shop_name: exposeData?.storeName } : {}

  const result: Record<string, any> = {
    sku: {
      sku_id: exposeData.id,
      sku_name: exposeData.name
    },
    spu: {
      spu_id: exposeData.spuId,
      spu_name: exposeData.spuName
    },
    component: {
      component_id: exposeData?.componentId,
      component_name: exposeData?.componentName,
      component_position: exposeData?.componentPosition,
      component_template: exposeData?.componentTemplate,
      position_id: exposeData?.positionId
    },

    page_id: exposeData?.pageId,
    page_title: exposeData?.pageTitle,
    page_type: exposeData?.pageType,
    event_name: exposeData?.eventName,
    content_type: exposeData?.contentType,
    primary_image_url: exposeData.primaryImageUrl,
    ...outerServiceInfo
  }

  if (exposeData?.storeId) {
    result['shipping_shop'] = {
      shipping_shop_id: exposeData?.storeId?.toString(),
      ...shopName
    }
  }
  if (exposeData.currentPrice || exposeData.originalPrice) {
    result['sale'] = {
      current_price: exposeData?.currentPrice ? fenToYuan(exposeData?.currentPrice) : '0.00', // 字符串转数字
      original_price: exposeData?.originalPrice ? fenToYuan(exposeData?.originalPrice) : '0.00'
    }
  }

  return result
}

// 商品卡触发（即点击）调用函数
function translateTriggerSkuComponent(triggerData: CommoditySku): Record<string, any> {
  const outerServiceInfo = translateOuterService(triggerData.outerService)
  const shopName = triggerData.storeName ? { shipping_shop_name: triggerData.storeName } : {}
  return {
    sku: {
      sku_id: triggerData.id,
      sku_name: triggerData.name
    },
    spu: {
      spu_id: triggerData.spuId,
      spu_name: triggerData.spuName
    },
    // sku_category: Object.assign({}, data.skuCategory),
    shipping_shop: {
      shipping_shop_id: triggerData?.storeId?.toString(),
      ...shopName
    },
    sale: {
      current_price: triggerData.currentPrice ? fenToYuan(triggerData.currentPrice) : '0.00', // 字符串转数字
      original_price: triggerData.originalPrice ? fenToYuan(triggerData.originalPrice) : '0.00'
    },
    component: {
      component_id: triggerData?.componentId,
      component_name: triggerData?.componentName,
      component_position: triggerData?.componentPosition,
      component_template: triggerData?.componentTemplate,
      position_id: triggerData?.positionId
    },

    page_id: triggerData?.pageId,
    page_title: triggerData?.pageTitle,
    page_type: triggerData?.pageType,
    event_name: triggerData?.eventName,
    content_type: triggerData?.contentType,
    primary_image_url: triggerData.primaryImageUrl,
    ...outerServiceInfo
  }
}

// 加购调用函数
function translateAddToCart(data: SkuToCart): Record<string, any> {
  const outerServiceInfo = translateOuterService(data.outerService)
  const shopName = data.storeName ? { shipping_shop_name: data.storeName } : {}
  return {
    action_type: data.cartActionType,
    sku: {
      sku_id: data.skuId.toString(),
      sku_name: data.skuName
    },
    spu: {
      spu_id: data.spuId,
      spu_name: data.spuName
    },
    // sku_category: Object.assign({}, data.skuCategory),
    shipping_shop: {
      shipping_shop_id: data.storeId,
      ...shopName
    },
    sale: {
      current_price: Number(data.currentPrice ?? 0) / 100, // 字符串转数字
      original_price: Number(data.originalPrice ?? 0) / 100
    },
    sku_num: data.skuNum,
    ...outerServiceInfo
  }
}

// 领取优惠券埋点调用函数
function translateGetCoupon(data: GetCoupon): Record<string, any> {
  return {
    coupon: {
      coupon_id: data.couponId,
      coupon_name: data.couponName
      // external: true,
    },
    coupon_batch: {
      coupon_batch_id: data.couponInsId,
      coupon_batch_name: data.couponInsName
    }
  }
}

// 订单状态变更（前端上报下单行为）调用函数
function translateCustomOrder(data: Order): Record<string, any> {
  const now = new Date().getTime()
  const newData: any = {
    order: {
      order_id: data.orderId ? data.orderId.toString() : '', // 防止orderId为null时，出现Bug
      order_status: data.buriedOrderStatus,
      order_time: Number(data.orderCreateTime),
      order_type: data.orderType || ''
    },
    sub_orders: [
      {
        sub_order_id: data.orderId ? data.orderId.toString() : '',
        order_amt: data.orderAmt ? fenToYuan(data.orderAmt) : 'default',
        pay_amt: data.payAmt ? fenToYuan(data.payAmt) : 'default'
      }
    ]
  }
  switch (data.buriedOrderStatus) {
    case BuriedOrderStatus.CancelPay:
      newData.order.cancel_pay_time = now
      break
    case BuriedOrderStatus.CancelGive:
      newData.order.cancel_time = now
      break
    case BuriedOrderStatus.Pay:
      newData.order.pay_time = now
      break
    case BuriedOrderStatus.Refund:
      newData.order.refund_time = now
      break
  }
  return newData
}

// 搜索调用函数
function translateSearch(data: Search): Record<string, any> {
  return {
    keyword: data.keyword
  }
}

export { srAdapter, srInstance }
