import { UID_KEY } from '@/config/constants'
import Aegis from 'aegis-web-sdk'
// import Aegis from './aegis.min.js'
import Cookies from 'js-cookie'

export const aegis = new Aegis({
  id: String(import.meta.env.VITE_AEGIS_ID), // 项目ID，即上报key
  uin: '', // 用户唯一 ID（可选）
  reportApiSpeed: true, // 接口测速
  reportAssetSpeed: true, // 静态资源测速
  spa: false,
  beforeReport(log: { level: string; msg: string }) {
    if (log.msg && log.msg.indexOf('Script error') !== -1) {
      // Script error错误不影响业务功能，可以降为一般日志定期检查
      log.level = '2'
      return log
    }
    if (log.msg && log.msg.indexOf('window["callback_callback') !== -1) {
      /* 
      TypeError: window["callback_callback_3"] is not a function.
      这个问题是终端jsbridge回调方式不对，终端已在5.0.63及之后的版本修复，所以不继续在h5项目上报
      */
      return false
    }
    return log
  },
  delay: 0
})

export function updateAegisConfig() {
  const uid = Cookies.get(UID_KEY)
  if (uid) {
    aegis.setConfig({
      uin: uid
    })
  }
}
