import { makeAutoObservable } from 'mobx'
import Cookies from 'js-cookie'
import { LAN_KEY } from '@/config'

class CommonStore {
  theme = 'defaultTheme'
  isEn = false

  constructor() {
    makeAutoObservable(this)
    this.theme = 'defaultTheme'
    this.isEn = Cookies.get(LAN_KEY) === 'EN'
  }

  setTheme(theme: string) {
    console.log('commonStore setTheme')
    this.theme = theme
  }
}

export default new CommonStore()
