import { SystemInfo } from './system'

// 渠道相关属性map值
export const chanPropMap: { [x: string]: string } = {
  chanWxappScene: 'chan_wxapp_scene',
  chanId: 'chan_id',
  chanReferAppId: 'chan_refer_app_id',
  chanShopId: 'chan_shop_id',
  chanShopName: 'chan_shop_name',
  chanCustomize: 'chan_customize',
  pushId: 'push_id',
  apppushmsgtaskid: 'apppushmsgtaskid',
  pmId: 'pm_id',
  systemmsgtasksubid: 'systemmsgtasksubid',
  shortMessageTaskId: 'shortMessageTaskId',
  shortTemplateNumber: 'shortTemplateNumber',
  chanCustom: 'chan_custom',
  extra: 'extra',
  chan_wxapp_scene: 'chan_wxapp_scene'
}

export interface SrChan {
  chan_wxapp_scene?: number // 小程序场景值
  chan_id?: string // 引流渠道的标识符
  chan_refer_app_id?: string // 来源小程序或公众号appid 例wx9d6f5f6gea059654
  chan_shop_id?: string // 门店ID，若需要计算门店业务则必填
  chan_shop_name?: string // 用户在有数的门店名称
  chan_customize?: string // 渠道定制参数
}

// 渠道相关属性
export interface Chan {
  chanWxappScene?: number // 小程序场景值
  chanId?: string // 引流渠道的标识符
  chanCustom?: ChanCustom // 渠道Id
  chanReferAppId?: string // 来源小程序或公众号appid 例wx9d6f5f6gea059654
  chanShopId?: string // 门店ID，若需要计算门店业务则必填
  chanShopName?: string // 用户在有数的门店名称
  [x: string]: any
}
// 自定义渠道
export interface ChanCustom {
  chanCustomId: string // 4级自定义渠道的标识符
  chanCustomIdDesc: string // 4级自定义渠道的描述
  chanCustomIdCat3: string // 3级自定义渠道的标识符
  chanCustomIdCat3Desc: string // 3级自定义渠道的描述
  chanCustomIdCat2: string // 2级自定义渠道的标识符
  chanCustomIdCat2Desc: string // 2级自定义渠道的描述
  chanCustomIdCat1: string // 1级自定义渠道的标识符
  chanCustomIdCat1Desc: string // 1级自定义渠道的描述
}

// 通用上下文内容
export interface Context {
  appVer?: string
  fromAppId?: string // 来源appId
  page_title?: string // 页面标题
  page?: string // 页面url 默认default
  system_info?: SystemInfo
  platform?: string // 当前事件所在的用户访问终端
  sdk_version?: string
  page_id?: string // 页面的唯一id 默认default
  page_type?: string // 自定义页面类型，默认上报为"default"
  abtest_code?: any[] // ab test实验组信息
  is_newly_open?: boolean // 表示用户进入页面时的场景 true 首次进入，false 返回进入
  refer?: {
    ref_page_type: string // 上级页面的页面类型
    ref_page_title: string // 上级页面的页面标题
    ref_page_id: string
  }
  wxSystemInfo?: {
    brand: string // 手机品牌
    model: string
    version: string // 微信版本号
    system: string // 操作系统及版本
    platform: string // 客户端平台
    SDKVersion: string
  }
  chan?: any // 设置渠道
  tracking_id?: string // 会话id
}
