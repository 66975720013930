// 插入同盾参数
import { createReportParams, tongdunApiList, TONGDUN_REPORT_KEY } from '@/utils/tongdun'
import { IInterceptor } from '@tencent/retailwe-common-libs-http-client'

export default function tongdunHandler(): IInterceptor {
  return {
    name: 'tongdunHandler',
    request: async (request) => {
      console.log(request, request.url)
      if (request.url && tongdunApiList.includes(request.url)) {
        // 同盾相关的接口插入同盾的参数
        const tongdunParams = await createReportParams(request.body)
        if (!request.body) {
          request.body = {}
        }
        request.body[TONGDUN_REPORT_KEY] = tongdunParams
      }
      return request
    }
  }
}
