// 年度账单
import dayjs from 'dayjs'

import { makeAutoObservable } from 'mobx'
import Swiper from 'swiper'

import { apis } from '@/common/http-client'
import { getAppDataByBridge } from '@/common/jsBridge/jsBridgeApi'
import { browseWxappPage, LeaveWxappPage } from '@/common/mall-tracking'
import { Toast } from '@/components/Toast'
import { RESPONSE_CODE } from '@/config/constants'
import { MemType } from '@/config/interface'
import { formatPriceWithCommas, randomNum, sleep, strProcess } from '@/utils'

enum MemberGrade {
  PLUS = 'PLUS', // 卓越
  CLUB = 'CLUB' // 普通
}

type AllPage =
  | 'page2'
  | 'page3'
  | 'page4'
  | 'page5'
  | 'page6'
  | 'page7'
  | 'page8'
  | 'page9'
  | 'generating'
  | 'report'

interface PageData {
  memberGrade: MemType // 会籍
  memberName: string // 会员姓名
  // p2
  appInitiationDate: string // 入会日期
  indate: -1 | 0 | 1 // 过期未续费，0 有效期1年以内，1 大于一年
  membershipDays: number // 入会天数
  membershipYear: number // 入会年数
  // p3
  totalAmt: number // 总消费金额(分)
  totalOrderCnt: number // 总消费单数
  totalItemCnt: number // 总消费单品种类数
  totalItemQty: number // 总消费商品数
  freqItemNameTop1: number // 最常购买商品top1 商品名
  freqItemQtyTop1: number // 最常购买商品top1 商品件数
  freqItemAmtTop1: number // 最常购买商品top1 商品花费金额
  freqItemNameTop2: number
  freqItemQtyTop2: number
  freqItemAmtTop2: number
  freqItemNameTop3: number
  freqItemQtyTop3: number
  freqItemAmtTop3: number
  // p4
  storeOrderCnt: number // 门店消费单数
  onlineOrderCnt: number // 线上消费单数
  // p5
  newProductItemCnt: number // 购买新品种类数
  // p6
  groupCode: 0 | 1 | 2 | 3 | 4 // 1露营 2宠物 3美护 4母婴 0无
  groupItemName: string // 类目单品金额最高商品名
  groupItemPayDate: string // 类目单品交易购买日期
  babyItemQty: number // 母婴商品交易件数
  // p8
  plusRebateAmt: number // 卓越返利金额
  plusTransVoucherAmt: number // 卓越运费券金额
  plusExclusiveAmt: number // 卓越专享礼金额
  plusSavingAmt: number // 卓越省钱总金额
  upgradeDate: string // 升级卓越日期
  isPlusPtlc: 0 | 1 // 是否卓越高潜 1-是 0-否
  plusMembershipTerm: string // 卓越会籍服务周期
  plusPtlcSavingAmt: number // 卓越高潜会员预计省钱金额
  // p9
  longFeedbackItemTotalAmt: number // 长期回馈商品省钱金额
  multipleOfMembershipFee: number // 长期回馈省钱为年费倍数
  longFeedbackItemTotalQty: number // 长期回馈商品购买件数
  longFeedbackTop1ItemNbr: string
  longFeedbackTop1ItemName: string
  longFeedbackTop2ItemNbr: string
  longFeedbackTop2ItemName: string
  longFeedbackTop3ItemNbr: string
  longFeedbackTop3ItemName: string
  longFeedbackTop4ItemNbr: string
  longFeedbackTop4ItemName: string
  longFeedbackTop5ItemNbr: string
  longFeedbackTop5ItemName: string
  longFeedbackTop6ItemNbr: string
  longFeedbackTop6ItemName: string
}

enum CardType {
  MINE = 1, // 个人主卡
  OTHER = 2 // 其余卡
}

export enum Scene {
  SCENE_ONE = 'sceneOne',
  SCENE_TWO = 'sceneTwo',
  SCENE_THREE = 'sceneThree',
  SCENE_FOUR = 'sceneFour',
  SCENE_FIVE = 'sceneFive'
}

const scenarios = [
  {
    name: Scene.SCENE_ONE,
    condition: (storeRatio: number, onlineRatio: number) =>
      storeRatio > 40 && storeRatio <= 60 && onlineRatio >= 40 && onlineRatio < 60
  },
  {
    name: Scene.SCENE_TWO,
    condition: (storeRatio: number, onlineRatio: number) =>
      storeRatio > 0 && storeRatio <= 40 && onlineRatio >= 60 && onlineRatio < 100
  },
  {
    name: Scene.SCENE_THREE,
    condition: (storeRatio: number, onlineRatio: number) => storeRatio === 0 && onlineRatio === 100
  },
  {
    name: Scene.SCENE_FOUR,
    condition: (storeRatio: number, onlineRatio: number) =>
      storeRatio > 60 && storeRatio < 100 && onlineRatio > 0 && onlineRatio < 40
  },
  {
    name: Scene.SCENE_FIVE,
    condition: (storeRatio: number, onlineRatio: number) => storeRatio === 100 && onlineRatio === 0
  }
]

class BillStore {
  baseUrl = 'https://sam-retail-static-1302115363.file.myqcloud.com/miniapp/annual-bill-2023/' // 静态资源cos桶地址
  isEpr: boolean | undefined = undefined // 是否过期
  swiper: Swiper | null = null // swiper实例
  agree = false // 是否同意协议
  loadDataEnd = false // 数据加载完成，渲染swiper
  imgLoaded = false
  page = 1
  showBill = false // 开启年度账单
  isMember = false // 是否为会员
  isPlus = false // 是否卓越主卡
  swiperItem = []
  statusBarHeight = 0
  showTooltip = false
  shareBgIndex = 0 // 分享背景图片索引
  selectTabIndex = 0 // tab索引
  hideMoney = false // 隐藏价格
  pageData: PageData = ({} as unknown) as PageData
  channelScene: Scene | null = null // 全渠道消费场景
  savingScene: string | null = null // 省钱计算器场景
  longFeedbackScene: string | null = null // 长期回馈商品场景
  showAgreement = false
  allowTouchMove = true
  pointer = 0
  randomImageIndices: number[] = []

  constructor() {
    makeAutoObservable(this)
  }

  async isEprRes() {
    Toast.loading('')
    try {
      // 判断年度账单是否过期
      const isEprRes = await apis.annualData.isEpr()
      Toast.hide()
      const { code, data = false } = isEprRes
      if (code === RESPONSE_CODE.SUCCESS) {
        this.updateIsEpr(data)
        return
      }
      this.updateIsEpr(false)
    } catch (error) {
      Toast.hide()
      this.updateIsEpr(false)
    }
  }

  updateIsEpr(epr: boolean) {
    this.isEpr = epr
  }

  updateImgLoaded(loaded: boolean) {
    this.imgLoaded = loaded
  }

  updateShowAgreement(value: boolean) {
    this.showAgreement = value
  }

  async setStatusBarHeight() {
    const { statusBarHeight } = await getAppDataByBridge(['statusBarHeight'])
    statusBarHeight && (this.statusBarHeight = Number(statusBarHeight))
  }

  // 获取账单数据
  async queryBill(uid: string) {
    Toast.loading('')
    try {
      const params = uid ? { key: uid } : {}
      const res = await apis.annualData.get(params)
      // 停顿500毫秒 等待视频素材加载
      // await sleep(500)
      Toast.hide()
      const { code, data, msg } = res || {}
      if (code === RESPONSE_CODE.SUCCESS) {
        const length = Object.keys(data || {}).length
        if (length > 0) {
          const {
            storeOrderRatio,
            onlineOrderRatio,
            upgradeDate,
            memberGrade,
            memberRole,
            memberType,
            isPlusPtlc,
            longFeedbackItemTotalAmt,
            totalAmt,
            isActive,
            plusSavingTotalAmt,
            cardType
          } = data
          this.pageData = data
          this.updateIsMember(true)
          this.updateScenario(storeOrderRatio, onlineOrderRatio)
          this.updateSavingScene({
            totalAmt,
            upgradeDate,
            memberGrade,
            memberRole,
            isPlusPtlc,
            isActive,
            plusSavingTotalAmt
          })
          this.updateLongFeedbackScene(cardType, memberType, longFeedbackItemTotalAmt)
        } else {
          this.updateIsMember(false)
        }
        this.updateLoadEnd(true)
        this.updateShowBill(true)
        // 开启账单的时候上报一次第一页的浏览
        this.pageReport('browse', this.swiperItem[this.page - 1])
      } else {
        Toast.text(msg)
      }
    } catch (error) {
      console.log(error)
      Toast.hide()
    }
  }

  updateScenario(storeOrderRatio = 0, onlineOrderRatio = 0) {
    for (const scenario of scenarios) {
      if (scenario.condition(storeOrderRatio, onlineOrderRatio)) {
        this.channelScene = scenario.name
      }
    }
  }

  updateSavingScene({
    totalAmt = 0,
    upgradeDate = '',
    memberGrade = MemberGrade.PLUS,
    memberRole = 1,
    isPlusPtlc = 0,
    isActive = 1,
    plusSavingTotalAmt = 0
  }) {
    const comparisonDate = dayjs('2023-01-01')
    if (totalAmt === 0) return
    if (memberGrade === MemberGrade.PLUS && memberRole === 1 && plusSavingTotalAmt > 0) {
      if (upgradeDate !== '' && dayjs(Number(upgradeDate)).isAfter(comparisonDate))
        this.savingScene = Scene.SCENE_ONE
      else if (upgradeDate === '' || dayjs(Number(upgradeDate)).isBefore(comparisonDate))
        this.savingScene = Scene.SCENE_TWO
    }
    if (
      memberGrade !== MemberGrade.PLUS &&
      memberRole === 1 &&
      isPlusPtlc === 1 &&
      isActive === 1
    ) {
      this.savingScene = Scene.SCENE_THREE
    }
  }

  updateLongFeedbackScene(cardType = 1, memberType = MemType.NORMAL, longFeedbackItemTotalAmt = 0) {
    const totalAmt = longFeedbackItemTotalAmt / 100
    if (cardType === 1 && memberType === 2 && totalAmt >= 680) {
      // 卓越主卡会员 省钱金额≥680
      this.longFeedbackScene = Scene.SCENE_ONE
    } else if (cardType === 1 && memberType === 1 && totalAmt >= 260) {
      // 普通会员 省钱金额≥260
      this.longFeedbackScene = Scene.SCENE_TWO
    } else if (cardType === 2 && totalAmt > 260) {
      // 亲友卡 省钱金额≥260
      this.longFeedbackScene = Scene.SCENE_THREE
    }
  }

  swiperSlideNext() {
    // if (this.swiper) {
    //   this.swiper.allowSlideNext = true
    // }
    this.swiper?.slideNext()
    // if (this.swiper) {
    //   this.swiper.allowSlideNext = false
    // }
  }

  swiperRemoveSlide(index: number) {
    this.swiperItem.splice(index, 1)
  }
  setSwiper(swiper: Swiper | null) {
    this.swiper = swiper
  }
  updateLoadEnd(value: boolean) {
    this.loadDataEnd = value
  }

  updateIsMember(value: boolean) {
    this.isMember = value
  }

  updateShowBill(value: boolean) {
    this.showBill = value
  }

  updatePage(page: number) {
    this.page = page
  }
  // 是否同意协议
  updateAgree(value: boolean) {
    this.agree = value
  }
  // 是否展示tooltip
  updateShowTooltip(value: boolean) {
    this.showTooltip = value
  }
  // 更新新年灵感好运分享背景
  updateShareBgIndex(value: number) {
    this.shareBgIndex = value
  }
  // 更新tab索引
  updateSelectTabIndex(value: number) {
    this.selectTabIndex = value
  }
  // 隐藏金额
  updateHideMoney(value: boolean) {
    this.hideMoney = value
  }
  updateAllowTouchMove(value: boolean) {
    this.allowTouchMove = value
  }
  updateSwiperItem(item: any) {
    this.swiperItem = item
  }
  updatePointer(value: number) {
    this.pointer = value
  }
  updateRandomImageIndices(indices: number[]) {
    this.randomImageIndices = indices
  }
  // 页面的浏览和离开事件埋点
  pageReport(type: 'browse' | 'leave', swiperItem: any) {
    const reportAction = type === 'browse' ? browseWxappPage : LeaveWxappPage
    const { page, extraProps } = swiperItem
    const { isPage6 } = extraProps
    const { title: page_title, event_value } = this.getPageTraceInfo(page)
    reportAction(
      {},
      {
        page_title,
        page_type: '年度账单',
        event_value,
        event_name: `sam_H5_${type}_page`
      }
    )
  }
  getPageTraceInfo(page: AllPage) {
    const { indate, totalItemCnt, newProductItemCnt, groupCode, totalAmt } = this.pageData || {}
    const handlePage2 = () => {
      let value = 4
      if (!this.isMember) {
        return value
      }
      if (indate === 0) {
        value = 1
      } else if (indate === 1) {
        value = 2
      } else if (indate === -1) {
        value = 3
      }
      return value
    }

    const handlePage3 = () => {
      let value = 3
      if (totalItemCnt > 3) {
        value = 1
      } else if (totalItemCnt <= 3 && totalItemCnt > 0) {
        value = 2
      }
      return value
    }

    const handlePage4 = () => {
      if (this.channelScene) {
        const value = [
          Scene.SCENE_ONE,
          Scene.SCENE_TWO,
          Scene.SCENE_THREE,
          Scene.SCENE_FOUR,
          Scene.SCENE_FIVE
        ].indexOf(this.channelScene)
        if (value > -1) {
          return value + 1
        }
      }
    }

    const handlePage5 = () => {
      let value = 2
      if (newProductItemCnt >= 10) {
        value = 1
      }
      return value
    }

    const handlePage8 = () => {
      if (this.savingScene) {
        const value = ([Scene.SCENE_ONE, Scene.SCENE_TWO, Scene.SCENE_THREE] as string[]).indexOf(
          this.savingScene
        )
        if (value > -1) {
          return value + 1
        }
      }
    }

    const handlePage9 = () => {
      if (this.longFeedbackScene) {
        const value = ([Scene.SCENE_ONE, Scene.SCENE_TWO, Scene.SCENE_THREE] as string[]).indexOf(
          this.longFeedbackScene
        )
        if (value > -1) {
          return value + 1
        }
      }
    }

    switch (page) {
      case 'page2':
        return {
          title: '会籍情况页',
          event_value: {
            page_content_type: handlePage2()
          }
        }
      case 'page3':
        return {
          title: '总消费页',
          event_value: {
            page_content_type: handlePage3()
          }
        }
      case 'page4':
        return {
          title: '全渠道消费页',
          event_value: {
            page_content_type: handlePage4()
          }
        }
      case 'page5':
        return {
          title: '新品页',
          event_value: {
            page_content_type: handlePage5()
          }
        }
      case 'page6':
        return {
          title: '类目页',
          event_value: {
            page_content_type: groupCode
          }
        }
      case 'page7':
        return {
          title: '类目页',
          event_value: {
            page_content_type: this.isMember ? 5 : 6
          }
        }
      case 'page8':
        return {
          title: '会员权益页',
          event_value: {
            page_content_type: handlePage8()
          }
        }
      case 'page9':
        return {
          title: '长期回馈省钱页',
          event_value: {
            page_content_type: handlePage9()
          }
        }
      case 'generating':
        return {
          title: '账单生成过渡页'
        }
      case 'report':
        return {
          title: '年度报告页',
          event_value: {
            page_content_type: this.isMember
              ? totalAmt > 0
                ? '有消费'
                : '无消费'
              : '非会员+体验卡'
          }
        }
      default:
        return {
          title: 'default',
          event_value: {}
        }
    }
  }
}

export default new BillStore()
