import { getAppDataByBridge } from '@/common/jsBridge/jsBridgeApi'
import { isInMiniProgram, urlParse } from '@/utils'
import { wxAppTrack } from '@/common/mall-tracking'

/** 将小程序传递的数据解析后存到本地 */
function saveData() {
  const query = urlParse()
  try {
    const appData = JSON.parse(decodeURIComponent(query.appData))

    const { storeInfoList, refer } = appData
    if (!storeInfoList) {
      appData.storeInfoList = []
    }

    if (!refer) {
      console.log('未取到refer值，设置默认值', refer)
      appData.refer = {
        // @ts-ignore
        ref_page_type: 'default',
        ref_page_title: 'default',
        ref_page_id: 'default'
      }
    }

    // console.log('appData', decodeURIComponent(query.appData))
    console.log('appData', appData)
    sessionStorage.setItem('appData', JSON.stringify(appData))
  } catch (error) {}
}
async function setScene() {
  const { chan_wxapp_scene } = await getAppDataByBridge(['chan_wxapp_scene'])
  wxAppTrack.setChan({
    chan_wxapp_scene
  })
}

if (isInMiniProgram()) {
  /** 将小程序传递的数据解析后存到本地 */
  saveData()
  /** 设置小程序场景值 */
  setScene()
}
