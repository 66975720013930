import React from 'react'
import { RouteConfigComponentProps, renderRoutes } from 'react-router-config'
import ErrorBoundary from '@/components/ErrorBoundary'
import { useHistory } from 'react-router-dom'
import { isInWx, isInApp } from '@/utils'

/**
 * <Switch {...switchProps}>
  {routes.map((route, i) => (
    <Route
      key={route.key || i}
      path={route.path}
      exact={route.exact}
      strict={route.strict}
      render={props =>
        route.render ? (
          route.render({ ...props, ...extraProps, route: route })
        ) : (
          <route.component {...props} {...extraProps} route={route} />
        )
      }
    />
  ))}
</Switch>
 */
// 默认路由模板，会引入 js-sdk 文件，并且进行 wx.config 初始化操作
const Layout: React.FC<RouteConfigComponentProps> = React.memo(function Layout(props) {
  const history = useHistory()
  const { route } = props
  // 判断是否是app环境，跳转到错误提示页
  if (!isInApp()) {
    const text = encodeURIComponent('链接无法在浏览器打开 请在山姆APP中访问')
    history.replace(`/common/wx-page-error?text=${text}`)
  }
  return <ErrorBoundary>{renderRoutes(route?.routes)}</ErrorBoundary>
})

export const H5Layout: React.FC<RouteConfigComponentProps> = React.memo(function H5Layout(props) {
  const { route } = props
  const history = useHistory()
  // 判断是否是微信环境，跳转到错误提示页
  if (!isInWx()) {
    history.replace('/common/wx-page-error')
  }

  return <ErrorBoundary>{renderRoutes(route?.routes)}</ErrorBoundary>
})

export const CommonLayout: React.FC<RouteConfigComponentProps> = React.memo(function H5Layout(
  props
) {
  const { route } = props
  console.log('common layout')
  return <ErrorBoundary>{renderRoutes(route?.routes)}</ErrorBoundary>
})

export default Layout
