import React from 'react'
import { Toast as TeaToast } from '@tencent/tea-component-mobile'
import plaintImg from '@/assets/task/plaint.png'
import styles from './index.module.less'
export const Toast = {
  fail(content: string, duration = 2000) {
    TeaToast.show({
      content,
      duration,
      icon: { name: 'warning', color: '#ffffff' }
    })
  },
  success(content: string, duration = 2000) {
    TeaToast.show({
      content,
      duration,
      icon: { name: 'success', color: '#ffffff' }
    })
  },
  text(content: string, duration = 2000) {
    TeaToast.show({
      content,
      duration
    })
  },
  warning(content: string, duration = 2000) {
    TeaToast.show({
      content: (
        <div className={styles.toastContent}>
          <img src={plaintImg} alt="" className={styles.icon} />
          <div className={styles.contentText}>{content}</div>
        </div>
      ),
      duration
    })
  },

  hide() {
    TeaToast.hide()
  },
  //  默认loading 只有3s
  loading(content?: string, duration = 100000) {
    TeaToast.loading({ content: content || '加载中', duration })
  }
}
