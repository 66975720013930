import { NetStatus } from '@/config/constants'
import { isHttpUrl } from '@/utils'
import queryString from 'query-string'

import { GetAppKeys, ReturnAppDataType, ShareConfig, AddCartGoodsInfo } from './types'
import { wxAppTrack } from '../mall-tracking/wx-app-track'
interface NavigateToParams {
  url: string
  events?: string
  success?: () => void
  fail?: () => void
  complete?: () => void
}
/** 跳转页面 */
export async function navigateToByMiniProgram(params: NavigateToParams) {
  const { url } = params
  const nextRefer = wxAppTrack.miniProgramNextRefer
  if (isHttpUrl(url)) {
    // 如果是http链接则直接通过浏览器跳转
    window.location.href = url
  } else {
    const target = queryString.stringifyUrl({ url, query: { refer: JSON.stringify(nextRefer) } })
    params.url = target
    // 如果不是http链接则通过小程序跳转
    window.wx?.miniProgram.navigateTo(params)
  }
  console.log('小程序跳转', params, isHttpUrl(url))
}

export async function getAppDataByMiniProgram(keys: (keyof GetAppKeys)[]) {
  try {
    // 从sessionStorage中取出数据
    const appDataAll = JSON.parse(sessionStorage.getItem('appData') || '{}') as GetAppKeys
    // 根据传入的key筛选数据
    const appData: any = {}
    keys.forEach((key) => {
      appData[key] = appDataAll[key]
    })

    // 获取网络状态需要实时获取(页面刚加载时不需要实时获取，反而会拖慢页面加载速度)
    if (keys.includes('netStatus') && window.wx && window.WeixinJSBridge) {
      // 要获取这个状态要先初始化wx jssdk
      appData.netStatus = await getNetworkStatus()
    }

    console.log('小程序环境的appData', appData)
    return appData as ReturnAppDataType
  } catch (e) {
    console.error(e)
    return {} as ReturnAppDataType // eslint-disable-line
  }
}

export function setShareConfigByMiniProgram(params: ShareConfig) {
  const { shareTitle, sharePic } = params
  window.wx.miniProgram.postMessage({
    data: {
      type: 'share', // 与小程序端约定的type
      title: shareTitle,
      // imageUrl: sharePic
      imageUrl: isHttpUrl(sharePic) ? sharePic : ''
    }
  })
}

/** 获取实时网络状态 */
export async function getNetworkStatus() {
  await awaitWeixinJSBridgeReady()
  return new Promise<NetStatus>((resolve) => {
    window.wx.getNetworkType({
      success: (response) => {
        const { networkType = '' } = response
        resolve(networkType === 'wifi' ? NetStatus.WIFI : NetStatus.MOBILE)
      }
    })
  })
}

/** 等待WeixinJSBridge加载完 */
function awaitWeixinJSBridgeReady() {
  return new Promise<any>((resolve) => {
    if (window.wx && window.WeixinJSBridge) {
      resolve(null)
    } else {
      console.log('等待wxSdk加载')
      // 事件回调
      const callback = () => {
        console.log('wxSdk加载完成', window.WeixinJSBridge)
        resolve(null)
        document.removeEventListener('WeixinJSBridgeReady', callback)
      }
      document.addEventListener('WeixinJSBridgeReady', callback, false)
    }
  })
}
