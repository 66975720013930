import { api } from '@/config/api'
import { APIMethod } from '@tencent/retailwe-common-libs-http-client'

/**
 * MAP: 需要遍历的类型
 * MATCH: 断言类型
 * TYPE: 需要改变的类型
 */
export type DeepRecord<MAP, MATCH, TO> = {
  [P in keyof MAP]: MAP[P] extends MATCH ? TO : DeepRecord<MAP[P], MATCH, TO>
}

export type APIS = DeepRecord<typeof api, string | { url: string }, APIMethod>

export interface ApiResponse<T> {
  code: string
  msg: string
  data: T
  globalTicket: string
  monitorTrackId: string
}

export enum EnumRequestLevel {
  Key = 1, // 主要接口
  Minor = 2, // 普通接口
  Negligible = 3 // 可忽略接口
}
