import { showError } from '@/common/helpers'
import { Toast } from '@/components/Toast'
import { EnumHttpErrorType } from '@/config'
import { EnumRequestLevel, ApiResponse } from '../types'
import { multiTranslation } from '@/utils'

export const bizErrorHandle = (level: EnumRequestLevel, resp: ApiResponse<any>, context?: any) => {
  if (level === EnumRequestLevel.Key) {
    showError({
      type: EnumHttpErrorType.Code,
      responseText: multiTranslation(JSON.stringify(resp.data)),
      message: ''
    })
  } else if (level === EnumRequestLevel.Minor && resp.msg) {
    Toast.fail(multiTranslation(resp.msg))
  }
  return resp
}

// eslint-disable-next-line max-params
export function StatusErrorHandle(
  level: EnumRequestLevel,
  status: number,
  statusText: string,
  context?: any
) {
  if (level === EnumRequestLevel.Key) {
    showError({
      type: EnumHttpErrorType.Status,
      responseText: multiTranslation(statusText),
      message: ''
    })
  } else if (level === EnumRequestLevel.Minor) {
    Toast.fail(multiTranslation(`${status}:${statusText}`))
  }
}

export function SystemErrorHandle(level: EnumRequestLevel, text: string, context?: any) {
  if (level === EnumRequestLevel.Key) {
    showError({
      type: EnumHttpErrorType.System,
      responseText: multiTranslation(text),
      message: ''
    })
  } else if (level === EnumRequestLevel.Minor) {
    Toast.fail(multiTranslation(text))
  }
}
