/**
 * 注册拦截器
 */

import { HttpClient, interceptors } from '@tencent/retailwe-common-libs-http-client'
import statusHandler from './interceptors/status-handler'
import bizResHandler from './interceptors/biz-res-handler'
import auth from './interceptors/auth'
import safesignHandler from './interceptors/safesign-handler'
import trackInfoHandler from './interceptors/trackInfo-handler'
import { getEnvConfig } from '@/common/envConfig'
import {
  AUTH_KEY_NAME,
  H5_KEY,
  UID_KEY,
  DEVICE_KEY,
  HeaderType,
  LAN_KEY,
  EXT_LAN_KEY,
  TVERSION_KEY,
  REQUEST_CHANNEL_SOURCE,
  REQUEST_JS_VERSION,
  TRACE_ID,
  APP_VERSION_KEY
} from '@/config'
import Cookies from 'js-cookie'
import md5 from 'md5'
import { isInApp } from '@/utils'
import { v4 as uuidv4 } from 'uuid'
import tongdunHandler from './interceptors/tongdun-handler'

const { injectReqHeaders, baseUrl, httpLogger } = interceptors

export default function registerInterceptors(httpClient: HttpClient) {
  // 动态 baseURL
  httpClient.registerInterceptor(
    baseUrl(async (request) => {
      // 测试代码start
      const { url } = request as any
      if (
        import.meta.env.MODE === 'qa' &&
        url === '/v1/sams/sams-user/authorization/get_jsapi_ticket'
      ) {
        const _baseUrl = `https://api-sams.walmartmobile.cn/api`
        return _baseUrl
      }
      // 测试代码end
      const _baseUrl = `${String(import.meta.env.VITE_API_HOST)}/api`
      return _baseUrl
    })
  )

  // 业务 Resposne 协议对齐
  httpClient.registerInterceptor(bizResHandler())

  // Http Status 通用处理
  httpClient.registerInterceptor(statusHandler())

  // 鉴权
  httpClient.registerInterceptor(auth(httpClient))

  // 注入 Headers
  httpClient.registerInterceptor(
    injectReqHeaders(async (request: any) => {
      const envConfig = getEnvConfig()
      const token = Cookies.get(AUTH_KEY_NAME) ?? ''
      const uid = Cookies.get(UID_KEY) ?? ''
      const systemLanguage = Cookies.get(LAN_KEY) ?? ''
      const extraLanguage = Cookies.get(EXT_LAN_KEY) ?? ''
      const headers: HeaderType = {
        [AUTH_KEY_NAME]: token,
        [LAN_KEY]: systemLanguage,
        [H5_KEY]: md5(uid)
      }
      // 多语言翻译新增
      if (extraLanguage) headers[EXT_LAN_KEY] = extraLanguage
      // api配置里是否有自定义设备类型
      const apiDeviceType = request?.headers[DEVICE_KEY]
      // App 加设备类型 device-type
      if (isInApp()) {
        const deviceType = Cookies.get(DEVICE_KEY) ?? ''
        headers[DEVICE_KEY] = deviceType
        headers[LAN_KEY] = systemLanguage
        headers[REQUEST_CHANNEL_SOURCE] = '5' // app内部h5
        headers[APP_VERSION_KEY] = Cookies.get(APP_VERSION_KEY) ?? ''
      } else {
        headers[DEVICE_KEY] = 'h5'
        headers[REQUEST_CHANNEL_SOURCE] = '4' // 外部h5
      }
      // 如果有自定义设备类型，用api里配置的
      if (apiDeviceType) {
        headers[DEVICE_KEY] = apiDeviceType
      }

      // 线上正式版本不允许添加 tversion
      if (Cookies.get(TVERSION_KEY) || getEnvConfig()) {
        Object.assign(headers, {
          tversion: Cookies.get(TVERSION_KEY) || getEnvConfig()?.tversion
        })
      }
      // 敏感信息
      headers[REQUEST_JS_VERSION] = '1.0.0'
      // 增加traceId，方便调用链路分析
      headers[TRACE_ID] = `${uuidv4().replace(/[-]/g, '')}.301.${Date.now() * 10000}`

      return headers
    })
  )

  // 插入同盾参数
  httpClient.registerInterceptor(tongdunHandler())

  // 插入安全签名
  httpClient.registerInterceptor(safesignHandler())

  // 插入渠道信息
  httpClient.registerInterceptor(trackInfoHandler())

  // 日志打印
  httpClient.registerInterceptor(httpLogger())

  return httpClient
}
