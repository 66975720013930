// 插入校验头
import { IInterceptor } from '@tencent/retailwe-common-libs-http-client'
import { getSafeSign } from '@/utils'
// 短链相关的接口
const shortUrlApiList: string[] = [
  `/v1/sams/configuration/portal/shorturl/getShortUrl`,
  `/v1/sams/configuration/portal/shorturl/convert`
]
export default function safesignHandler(): IInterceptor {
  return {
    name: 'safesignHandler',
    request: async (request) => {
      if (request.url && shortUrlApiList.includes(request.url)) {
        if (!request.body) {
          request.body = {}
        }
        const biz_sign = getSafeSign(request.body)
        request.headers['biz_sign'] = biz_sign
      }
      return request
    }
  }
}
