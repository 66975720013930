interface IProps {
  namespace: string
  methods: string[]
  iosBridgeName?: string
  callbackNamespace?: string
  androidBridgeName?: string
  /**
   * @default window
   */
  context: any
}

enum DeviceType {
  Android = '安卓',
  Ios = 'ios'
}

class JsBridge {
  // 上下文对象，默认 window
  context: any = window || {}
  callbackNamespace = 'callback'
  // callback 命名空间引用
  _callback: any
  // 命名空间，支持多级 window.xxx = {}
  namespace = ''
  // 真正的命名空间：window.sr = {}
  _namespace: any
  // 协商支持的方法名数组，这里的方法名是 APP 端提供支持的
  declareMethods: string[]
  iosBridgeName = 'jsBridge'
  androidBridgeName = 'jsBridge'

  private callbackCounter = 0

  constructor(options: IProps) {
    const {
      namespace,
      methods,
      iosBridgeName,
      androidBridgeName,
      callbackNamespace,
      context
    } = options
    this.context = context
    this.namespace = namespace
    this.declareMethods = methods
    this.iosBridgeName = iosBridgeName ?? this.iosBridgeName
    this.androidBridgeName = androidBridgeName ?? this.androidBridgeName
    this.callbackNamespace = callbackNamespace ?? this.callbackNamespace
  }

  isAndroid() {
    return /android/i.test(navigator.userAgent)
  }

  isIOS() {
    return navigator.userAgent.includes('Mac OS X')
  }

  isHarmony() {
    return navigator.userAgent.includes('Harmony')
  }

  // 打印相关信息
  log(deviceType: DeviceType, method: string, params: Record<string, any> | string) {
    const isAndroid = deviceType === DeviceType.Android
    const jsBridgeName = isAndroid ? this.androidBridgeName : this.iosBridgeName
    const functionName = isAndroid ? 'method' : 'postNotification'
    console.log(deviceType, '方法名：', method)
    console.log(
      '调用路径：',
      `${this.context}.${jsBridgeName}${isAndroid ? '' : '.postNotification'}.${method}`
    )
    console.log('函数：', this.context[jsBridgeName][functionName])
    console.log('参数：', params)
    console.log('-----------分隔符----------')
  }

  // 绑定 jsBridge 方法，用来挂载接受 APP 端注入的方法
  bindMethods() {
    const isIos = this.isIOS()
    const isAndroid = this.isAndroid()
    const isHarmony = this.isHarmony()

    this.declareMethods.forEach((method) => {
      // 挂载 js 侧命名空间方法: window.xxx.[method] = () => {}
      this._namespace[method] = (params: any) => {
        if (isIos && this.context[this.iosBridgeName]) {
          this.context[this.iosBridgeName].postNotification(method, params)
        } else if ((isAndroid || isHarmony) && this.context[this.androidBridgeName]) {
          // 安卓序列化参数，APP 端统一反序列化解析
          const _strParams = JSON.stringify(params || {})
          // this.log(DeviceType.Android, method, _strParams) // 打印信息
          if (typeof this.context[this.androidBridgeName][method] === 'function') {
            this.context[this.androidBridgeName][method](_strParams)
          }
        } else {
          // 当在非app环境调用getAppData时，也执行callback，让流程继续
          const { callback } = params || {}
          if (
            // method === 'getAppData' &&
            callback &&
            typeof this._callback[callback] === 'function'
          ) {
            this._callback[callback]('{}')
          }
          console.error('不支持的平台', method)
        }
      }
    })

    // 注册统一调用方法
    // window.sr.app.invoke('test', {a:1}) => window.sr.test(params)
    this._namespace.app.invoke = (functionName: string, params: any) => {
      const { callback, ...rest } = params || {}
      // 中转回调函数名
      let _callbackName
      const _params = rest
      if (this._namespace[functionName]) {
        if (callback && typeof callback === 'function') {
          _callbackName = `callback_${callback.name}_${this.callbackCounter++}`
          // 往全局对象上面注册回调方法，相当于 window.sr.callback.callback_handlexxx_0 = function() {}
          this._callback[_callbackName] = callback
        }
        if (_callbackName) {
          _params.callback = _callbackName
        }
        this._namespace[functionName](_params)
      } else {
        console.log('没找到对应方法')
      }
    }
  }

  init() {
    // window.xxx.app = {}
    // 注册 js 调用 app 方法
    this.context[this.namespace] = {}
    this.context[this.namespace].app = {}
    // 注册 app 回调 js 方法
    // window.sr.callback = {}
    this.context[this.namespace][this.callbackNamespace] = {}
    this._namespace = this.context[this.namespace]
    this._callback = this._namespace[this.callbackNamespace]
    this.bindMethods()
    // console.log(window.sr, this.context[this.iosBridgeName], 'window.sr')
  }
}

export default JsBridge
