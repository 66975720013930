import counterStore from './counter'
import memberStore from './member'
import orderStore from './orders'
import sessionStore from './session'
import taskStore from './task'
import billStore from './bill'
import equityStore from './equity'
import commonStore from './common'
import offLineStore from './offline'
import expCardReceiveStore from './expCardReceive'
import myExperienceStore from './experience'
import memberGuide from './memberGuide'
import memberTaskStore from './memberTask'

const _store = {
  counterStore,
  sessionStore,
  memberStore,
  orderStore,
  taskStore,
  billStore,
  offLineStore,
  equityStore,
  expCardReceiveStore,
  commonStore,
  myExperienceStore,
  memberGuide,
  memberTaskStore
}

export type StoreType = typeof _store

export const store = _store
