import { apis } from '@/common/http-client'
import session from '@/common/session'
import { RESPONSE_CODE, SystemLanguage } from '@/config/constants'

import words from './zh/words'

type Words = Map<string, string>
type CharPair = Record<'id' | 'simplifiedChinese' | 'traditionalChinese', string>

const isTC = session.getLanguage() === SystemLanguage.TRADITIONAL_CHINESE
const st: Words = new Map()
const ts: Words = new Map()

function transformWords(newWords = words) {
  const len = newWords.length
  let i = 1
  let w = newWords[0]

  while (i < len) {
    const char = newWords[i]
    i++
    if (!w) {
      w = char
      continue
    }
    if (char === ' ') {
      w = ''
      continue
    }

    !st.has(w) && st.set(w, char)
    ts.set(char, w)
  }
}

function replaceOrInsert(words: string, data: CharPair[]): string {
  const wordsArray = words.split(' ')
  const simplifiedMap: Map<string, string> = new Map()

  data.forEach(({ simplifiedChinese, traditionalChinese }) => {
    simplifiedMap.set(simplifiedChinese, traditionalChinese)
  })

  wordsArray.forEach((pair, index) => {
    const simplifiedChar = pair[0]
    if (simplifiedMap.has(simplifiedChar)) {
      wordsArray[index] = simplifiedChar + simplifiedMap.get(simplifiedChar)
      simplifiedMap.delete(simplifiedChar)
    }
  })

  simplifiedMap.forEach((traditionalChinese, simplifiedChinese) => {
    wordsArray.push(simplifiedChinese + traditionalChinese)
  })

  return wordsArray.join(' ')
}

if (isTC) {
  apis
    .getTraditionalCnConfig()
    .then((res) => {
      const { code, data } = res || {}
      if (code === RESPONSE_CODE.SUCCESS && data?.length > 0) {
        const newWords = replaceOrInsert(words, data)
        transformWords(newWords)
      }
      transformWords()
    })
    .catch((e) => {
      console.error(e)
      transformWords()
    })
}

function converter(source: string, words: Words): string {
  const len = source.length
  let target = ''
  let i = 0
  let has = false
  while (i < len) {
    const char = source[i]
    if (!has) {
      target += words.get(char) || char
      i += 1
    } else {
      has = false
    }
  }
  return target
}

/**
 * 简体转换为繁体
 * @param text - 简体文本
 * @returns 繁体文本
 */
export function toTraditional(text: string): string {
  return converter(text, st)
}

/**
 * 繁体转换为简体
 * @param text - 繁体文本
 * @returns 简体文本
 */
export function toSimplified(text: string): string {
  return converter(text, ts)
}
