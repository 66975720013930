import { isInApp, isInMiniProgram, isInWx } from './index'

/** 获取h5浏览器类型 */
export function getBrowserType() {
  const userAgent = navigator?.userAgent?.toLowerCase()
  if (userAgent.indexOf('chrome') !== -1) {
    return 'Chrome'
  } else if (userAgent.indexOf('safari') !== -1 && userAgent.indexOf('chrome') === -1) {
    return 'Safari'
  } else if (userAgent.indexOf('firefox') !== -1) {
    return 'Firefox'
  } else if (userAgent.indexOf('edge') !== -1) {
    return 'Edge'
  } else if (userAgent.indexOf('trident') !== -1) {
    return 'IE'
  } else {
    return 'Unknown'
  }
}

/** 返回浏览器类型 */
export function returnBrowserType() {
  if (isInApp()) {
    return `Sam's`
  } else if (isInWx()) {
    return 'WeChat'
  } else if (isInMiniProgram()) {
    return 'mini'
  } else {
    return getBrowserType()
  }
}
