import { Chan } from './types/chan'
import { SendKeyEnum } from './type-map/index'
import { User } from './types/user'
import { srAdapter } from './sr-adapter'
enum EnumChannel {
  sr = 0 // 有数
}
const adapterPlugins = [srAdapter]
class DataTracking {
  start() {
    adapterPlugins.forEach((p) => {
      p.start()
    })
  }

  /**
   * 设置用户信息
   *
   * @param {User} user
   * @param {(EnumChannel | null | undefined)} channel
   * @memberof DataTracking
   */
  setUser(user: User, channel: EnumChannel | null | undefined) {
    adapterPlugins.forEach((p, index) => {
      if (!channel || channel === index) {
        p.setUser(user)
      }
    })
  }

  /**
   * 设置渠道信息
   *
   * @param {Chan} chan
   * @param {(EnumChannel | null | undefined)} channel
   * @memberof DataTracking
   */
  setChan(chan: Chan, channel: EnumChannel | null | undefined) {
    adapterPlugins.forEach((p, index) => {
      if (!channel || channel === index) {
        p.setChan(chan)
      }
    })
  }

  /**
   * 设置上下文
   *
   * @param {Record<string, any>} obj
   * @param {(EnumChannel | null | undefined)} channel
   * @memberof DataTracking
   */
  setContext(obj: Record<string, any>, channel: EnumChannel | null | undefined) {
    adapterPlugins.forEach((p, index) => {
      if (!channel || channel === index) {
        p.setContext(obj)
      }
    })
  }

  /**
   * 上报信息
   *
   * @template T
   * @param {SendKeyEnum} type
   * @param {T} props
   * @param {Record<string, any>} [extProps={}]
   * @param {(EnumChannel | null | undefined)} channel
   * @memberof DataTracking
   */
  // eslint-disable-next-line max-params
  send<T extends Record<string, any>>(
    type: SendKeyEnum,
    props: T,
    channel: EnumChannel | null | undefined,
    extProps: Record<string, any> = {}
  ) {
    adapterPlugins.forEach((p, index) => {
      if (!channel || channel === index) {
        p.send(type, props, extProps)
      }
    })
  }
}
const dataTracking = new DataTracking()
export { dataTracking, EnumChannel, SendKeyEnum }
