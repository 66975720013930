import {
  PageShare,
  Element,
  BrowseAndLeavePage,
  CommoditySku,
  SkuToCart,
  GetCoupon,
  Order,
  Search,
  Live2goodParams,
  ExposeTaskParams,
  ExposeEquityParams,
  ExposeInviteActivityParams,
  Component
} from './types'
/**
 * 业务埋点定义
 */
import { SendKeyEnum, wxAppTrack } from './wx-app-track'

/**
 * 页面分享
 * @param page
 * @param pageOrComponentThis
 */
function sharePage(page: PageShare, pageOrComponentThis?: any) {
  wxAppTrack.send<PageShare>(SendKeyEnum.PageShareAppMessage, page, pageOrComponentThis)
}

/**
 * 元素事件
 * @param element
 * @param pageOrComponentThis
 */
function element(element: Element, pageOrComponentThis?: any) {
  wxAppTrack.send<Element>(SendKeyEnum.Element, element, pageOrComponentThis)
}

/**
 * 页面浏览
 * @param params
 * @param pageOrComponentThis
 */
function webBrowsePage(params: BrowseAndLeavePage, pageOrComponentThis?: any) {
  // wxAppTrack.send<BrowseAndLeavePage>(SendKeyEnum.WebBrowsePage, params, pageOrComponentThis)
  wxAppTrack.pageOnShow(SendKeyEnum.WebBrowsePage, window, params, pageOrComponentThis)
}

/**
 * 页面浏览2
 * @param params
 * @param pageOrComponentThis
 */
function browseWxappPage(params: BrowseAndLeavePage, pageOrComponentThis?: any) {
  // wxAppTrack.send<BrowseAndLeavePage>(SendKeyEnum.BrowseWxappPage, params, pageOrComponentThis)
  wxAppTrack.pageOnShow(SendKeyEnum.BrowseWxappPage, window, params, pageOrComponentThis)
}

/**
 * 页面退出
 * @param params
 * @param pageOrComponentThis
 */
function leavePage(params: BrowseAndLeavePage, pageOrComponentThis?: any) {
  // wxAppTrack.send<BrowseAndLeavePage>(SendKeyEnum.LeavePage, params, pageOrComponentThis)
  wxAppTrack.pageOnHide(SendKeyEnum.LeavePage, window, params, pageOrComponentThis)
}

/**
 * 页面退出2
 * @param params
 * @param pageOrComponentThis
 */
function LeaveWxappPage(params: BrowseAndLeavePage, pageOrComponentThis?: any) {
  // wxAppTrack.send<BrowseAndLeavePage>(SendKeyEnum.LeaveWxappPage, params, pageOrComponentThis)
  wxAppTrack.pageOnHide(SendKeyEnum.LeaveWxappPage, window, params, pageOrComponentThis)
}

/**
 * 商品页浏览
 * @param browseSku
 * @param pageOrComponentThis
 */
function browseSkuPage(browseSku: CommoditySku, pageOrComponentThis?: any) {
  wxAppTrack.send<CommoditySku>(SendKeyEnum.BrowseSkuPage, browseSku, pageOrComponentThis)
}

/**
 * 商品曝光
 * @param exposeSku
 * @param pageOrComponentThis
 */
function exposeSkuComponent(exposeSku: CommoditySku, pageOrComponentThis?: any) {
  wxAppTrack.send<CommoditySku>(SendKeyEnum.ExposeSkuComponent, exposeSku, pageOrComponentThis)
}

/**
 * 商品卡触发（即点击）
 * @param triggerSku
 * @param pageOrComponentThis
 */
function triggerSkuComponent(triggerSku: CommoditySku, pageOrComponentThis?: any) {
  wxAppTrack.send<CommoditySku>(SendKeyEnum.TriggerSkuComponent, triggerSku, pageOrComponentThis)
}

/**
 * 加购
 * @param addSku
 * @param pageOrComponentThis
 */
function addToCart(addSku: SkuToCart, pageOrComponentThis: any) {
  wxAppTrack.send<SkuToCart>(SendKeyEnum.AddToCart, addSku, pageOrComponentThis)
}

/**
 * 领取优惠券
 * @param coupon
 * @param pageOrComponentThis
 */
function getCoupon(coupon: GetCoupon, pageOrComponentThis?: any) {
  wxAppTrack.send<GetCoupon>(SendKeyEnum.GetCoupon, coupon, pageOrComponentThis)
}

/**
 * 订单状态变化
 * @param order
 * @param pageOrComponentThis
 */
function customOrder(order: Order, pageOrComponentThis?: any) {
  wxAppTrack.send<Order>(SendKeyEnum.CustomOrder, order, pageOrComponentThis)
}

/**
 * 搜索
 * @param search
 * @param pageOrComponentThis
 */
function search(search: Search, pageOrComponentThis?: any) {
  wxAppTrack.send<Search>(SendKeyEnum.Search, search, pageOrComponentThis)
}
/**
 * 直播 跳转商品详情页
 * @param detail
 * @param pageOrComponentThis
 */
function live2GoodDetail(detail: Live2goodParams, pageOrComponentThis?: any) {
  wxAppTrack.send<Live2goodParams>(SendKeyEnum.LiveBrowseSkuPage, detail, pageOrComponentThis)
}
/**
 * 直播 点击跳转直播间
 * @param detail
 * @param pageOrComponentThis
 */
function browseLivePage(detail: Live2goodParams, pageOrComponentThis?: any) {
  wxAppTrack.send<Live2goodParams>(SendKeyEnum.BrowseLivePage, detail, pageOrComponentThis)
}
/**
 * 任务组件曝光
 * @param exposeSku
 * @param pageOrComponentThis
 */
function exposeTaskComponent(exposeTask: ExposeTaskParams, pageOrComponentThis?: any) {
  wxAppTrack.send<ExposeTaskParams>(SendKeyEnum.ExposeSkuComponent, exposeTask, pageOrComponentThis)
}
/**
 * 权益中心权益组件曝光
 * @param exposeEquity
 * @param pageOrComponentThis
 */
function exposeEquityComponent(exposeEquity: ExposeEquityParams, pageOrComponentThis?: any) {
  wxAppTrack.send<ExposeTaskParams>(
    SendKeyEnum.ExposeSkuComponent,
    exposeEquity,
    pageOrComponentThis
  )
}

/**
 * 邀请有礼任务组件曝光
 * @param exposeSku
 * @param pageOrComponentThis
 */
function exposeInviteActivityComponent(
  exposeSku: ExposeInviteActivityParams,
  pageOrComponentThis?: any
) {
  wxAppTrack.send<ExposeInviteActivityParams>(
    SendKeyEnum.ExposeSkuComponent,
    exposeSku,
    pageOrComponentThis
  )
}

/**
 * 组件曝光
 * @param component
 * @param extProps
 */
function exposeComponent(component: Component, extProps?: any) {
  wxAppTrack.send<Component>(SendKeyEnum.ExposeSkuComponent, component, {
    isComponent: true,
    ...extProps
  })
}

/**
 * 广告曝光事件
 * @param element
 * @param pageOrComponentThis
 */
function exposeAds(element: Component, pageOrComponentThis?: any) {
  wxAppTrack.send<Component>(SendKeyEnum.ExposeAds, element, pageOrComponentThis)
}

/**
 * 广告点击事件
 * @param element
 * @param pageOrComponentThis
 */
function triggerAd(element: Component, pageOrComponentThis?: any) {
  wxAppTrack.send<Component>(SendKeyEnum.TriggerAd, element, pageOrComponentThis)
}

export {
  sharePage,
  element,
  webBrowsePage,
  leavePage,
  browseSkuPage,
  exposeSkuComponent,
  triggerSkuComponent,
  addToCart,
  getCoupon,
  customOrder,
  search,
  live2GoodDetail,
  browseLivePage,
  exposeTaskComponent,
  browseWxappPage,
  LeaveWxappPage,
  exposeEquityComponent,
  exposeInviteActivityComponent,
  exposeComponent,
  exposeAds,
  triggerAd,
  wxAppTrack
}
