import Cookie from 'js-cookie'
import { ENV_CONFIG_KEY } from '@/config/index'
import { IEnvConfig } from '@/config/env'

export const setEnvConfig = (_val: any) => {
  try {
    const prevConfig = JSON.parse(Cookie.get('ENV_CONFIG_KEY') ?? '{}')
    Cookie.set(ENV_CONFIG_KEY, {
      ...prevConfig,
      env: _val
    })
  } catch (error) {
    console.error(error)
  }
}

export function getEnvConfig(): IEnvConfig | undefined {
  try {
    const envConfig: IEnvConfig = JSON.parse(Cookie.get(ENV_CONFIG_KEY) ?? '{}')
    return envConfig
  } catch (error) {}
  return undefined
}

export function removeEnvConfig() {
  Cookie.remove(ENV_CONFIG_KEY)
}
