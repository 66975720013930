import SRT from 'sr-sdk-h5' // 有数

const config = {
  /**
   * 有数 - ka‘接入测试用’ 分配的 app_id，对应的业务接口人负责
   */
  token: import.meta.env.VITE_YOUSHU_TOKEN,

  // trackApp: true,

  /**
   * 开启打印调试信息， 默认 false
   */
  debug: false,

  serverUrl: 'https://access.zhls.qq.com/api/report',

  // 建议开启-是否开启页面分享链路自动跟踪
  openSdkShareDepth: true,
  // 建议开启-元素事件跟踪，自动上报元素事件，入tap、change、longpress、confirm
  autoTrack: false,
  autoStart: false
}

const srInstance = new SRT(config)
export { srInstance }
