import { IInterceptor } from '@tencent/retailwe-common-libs-http-client'
import { EnumRequestLevel } from '../types'
import { StatusErrorHandle, SystemErrorHandle } from '../libs/index'

export default function genInterceptor(): IInterceptor {
  return {
    name: 'statusHandler',
    response: async (res) => {
      const { request, status, statusText } = res
      const { level = EnumRequestLevel.Minor } = request
      if ((status >= 200 && status < 300) || status === 304) {
        return res
      }

      // status error
      StatusErrorHandle(level || 0, status, statusText)
      throw res
    },

    // 发生网络错误
    responseError: async (err) => {
      const { request, status, message } = err
      const { level = EnumRequestLevel.Minor } = request
      if (status) {
        // status error: e.g 404:request:fail
        StatusErrorHandle(level || 0, status, message)
      } else {
        // system error: e.g request:fail:timeout
        SystemErrorHandle(level || 0, message)
      }

      throw err
    }
  }
}
