/** @warning 注意：所有jsBridge的回调函数名必须包含"callback"字符，否则打包后会出现函数名被压缩混淆，导致重名覆盖。详情见webpack.prod.ts配置 */

import { NetStatus } from '@/config/constants'
import { aegis } from '@/common/aegis'
import { Message } from '@tencent/tea-component-mobile'
import { compareVersion, isInApp, isInMiniProgram, jsbErrorReport } from '@/utils'
import session from '@/common/session'
import { Chan, ChanCustom } from '../mall-tracking/types/chan'
import { SystemInfo } from '../mall-tracking/types/system'
import {
  addReferPageByNative,
  getDeliveryAddressLocationByNative,
  getLocationByNative
} from './nativeApp'
import { AddReferPageParams, GetAppKeys, ReturnAppDataType } from './types'
import { returnBrowserType } from '@/utils/helper'
import { getAppDataByMiniProgram } from './miniProgram'

export interface PageTitle {
  title: string
  titleEn?: string
}

// 用户相关属性
export interface User {
  /** 用户唯一id */
  UUID?: string
  /** 系统类型，安卓还是ios */
  system?: string
  user_id?: string
  // member_id?: string
  merber_id?: string
  union_id?: string
  IMEI?: string
  idfa?: string
  tag?: [
    {
      tag_id?: string
      tag_name?: string
    }
  ]
}

export interface StoreInfo {
  storeId: number
  storeType: number
}

/** 批量获取appData，内部通过版本号对旧版本APP做兼容 */
export async function getAppDataByBridge<T extends keyof GetAppKeys>(keys: T[]) {
  if (isInMiniProgram()) {
    return getAppDataByMiniProgram(keys)
  } else if (!isInApp()) {
    // 在非小程序和app环境下返回空数据
    return Promise.resolve({} as ReturnAppDataType) // eslint-disable-line
  }

  const appVersion = session.getAppVersion()
  const oldAppVersion = '5.0.23.115' // 116版本才调通
  console.log('通过cookie获取的appVersion: ', appVersion)
  if (appVersion && compareVersion(appVersion, oldAppVersion) > 0) {
    return getAppDataByBridgeNew(keys)
  } else {
    return getAppDataByBridgeOld(keys)
  }
}

/** getAppDataByBridge的调用次数(避免同时调用多次这个方法，造成callback覆盖的问题) */
let getAppDataCallCount = 0
/** 批量获取appData，旧版APP的方法 */
export async function getAppDataByBridgeOld<T extends keyof GetAppKeys>(keys: T[]) {
  getAppDataCallCount += 1
  // 本次调用的回调函数名
  const callbackName = `getAppDataCallback_${getAppDataCallCount}`

  return new Promise<ReturnAppDataType>((resolve) => {
    /** callback调用的次数计数 */
    let count = 0
    const { length } = keys
    /** promise返回的数据 */
    let result: ReturnAppDataType = {}

    // 每次调用getAppData公用的callback(不用担心覆盖的问题，因为逻辑是一样的)
    function getAppDataCallback(jsonResult: string) {
      // 将json数据转成object
      let objResult = {}
      try {
        objResult = JSON.parse(jsonResult)
      } catch (err) {
        console.error('getAppData转化json失败：', err)
        // todo: 上报错误
      }

      // 将返回值存入map
      result = { ...result, ...objResult }
      count += 1
      // 如果callback调用完成则resolve
      if (count === length) {
        console.log('appData转化后的数据：', result)
        resolve(result)
      }
    }

    // 重写回调函数的name值
    Object.defineProperty(getAppDataCallback, 'name', { value: callbackName })

    // 循环调用jsBridge
    keys.forEach((key) => {
      try {
        sr.app.invoke('getAppData', {
          key,
          callback: getAppDataCallback
        })
      } catch (err) {
        console.error('getAppData调用错误：', err)
        resolve({})
      }
    })
  })
}

/** 批量获取appData，新版APP的方法 */
export async function getAppDataByBridgeNew<T extends keyof GetAppKeys>(keys: T[]) {
  const timeout = 2000 // 默认JSB超时时间
  const p1 = new Promise<ReturnAppDataType>((resolve) => {
    function getAppDataCallback(jsonResult: string) {
      try {
        const appData = JSON.parse(jsonResult)
        console.log('appData转化后的数据:', appData)
        resolve(appData)
      } catch (err) {
        console.error('getAppData转化json失败：', err)
        console.log('appData的json数据：', jsonResult)
        resolve({})
        jsbErrorReport({ error: 'getAppData转化json失败' }, JSON.stringify(keys), jsonResult)
        // todo: 上报错误
      }
    }

    try {
      sr.app.invoke('getAppData', {
        key: keys.join(','),
        callback: getAppDataCallback
      })
    } catch (err) {
      console.error('getAppData调用错误：', err)
      resolve({})
    }
  })
  const p2 = new Promise<ReturnAppDataType>((resolve, reject) => {
    setTimeout(() => {
      resolve({})
      jsbErrorReport({ error: 'jsb超时' }, JSON.stringify(keys), '')
    }, timeout)
  })
  // 设置JSB超时，防止某些机器JSB卡住
  return Promise.race([p1, p2])
}

/**
 * 获取渠道信息
 * @export
 * @returns {Chan}
 */
export const getChan = async (): Promise<Chan> => {
  const { chan_shop_id, chan_shop_name, channel } = await getAppDataByBridge([
    'chan_shop_id',
    'chan_shop_name',
    'channel'
  ])
  return {
    chanShopId: chan_shop_id,
    chanShopName: chan_shop_name,
    ...(channel
      ? {
          chanCustom: ({
            chanCustomId: channel
          } as unknown) as ChanCustom
        }
      : undefined)
  }
}

/**
 * 获取用户信息
 * @export
 * @returns {User}
 */
export const getUser = async (): Promise<User> => {
  const {
    UUID,
    // system,
    user_id,
    merber_id,
    IMEI,
    idfa,
    tag_id,
    union_id,
    tag_name
  } = await getAppDataByBridge([
    'UUID',
    // 'system',
    'user_id',
    'merber_id',
    'IMEI',
    'idfa',
    'tag_id',
    'union_id',
    'tag_name'
  ])
  console.log('获取用户信息', {
    UUID,
    // system,
    user_id,
    merber_id,
    union_id,
    IMEI,
    idfa,
    tag: [
      {
        tag_id,
        tag_name
      }
    ]
  })
  return {
    UUID,
    // system,
    user_id,
    merber_id,
    union_id,
    IMEI: IMEI || 'default',
    idfa,
    tag: [
      {
        tag_id,
        tag_name
      }
    ]
  }
}

/**
 * 获取系统信息
 * @export
 * @returns {SystemInfo}
 */
export const getSystemInfo = async (): Promise<SystemInfo> => {
  const { system, system_version, device_model, brand, site_type } = await getAppDataByBridge([
    'system',
    'system_version',
    'device_model',
    'brand',
    'site_type'
    // 'cardType' 埋点SystemInfo里不需要cardType字段
  ])

  const systemInfo: SystemInfo = {
    // system_version中已经自带了system的字段
    system: `${system_version}`,
    // system: `${system} ${system_version}`,
    version: session.getAppVersion(),
    model: device_model,
    brand: brand || 'default',
    browser: returnBrowserType()
    // cardType
  }

  if (site_type) {
    systemInfo['site_type'] = site_type
  }

  return systemInfo
}

/** 获取终端收货地址经纬度 */
export async function getDeliveryAddressLocation() {
  let location: { longitude: number; latitude: number; isUltimateAddress?: boolean } = {
    longitude: 0,
    latitude: 0
  }
  try {
    if (isInApp()) {
      const appVersion = session.getAppVersion()
      const oldAppVersion = '5.0.56'
      if (appVersion && compareVersion(appVersion, oldAppVersion) > 0) {
        location = await getDeliveryAddressLocationByNative()
      } else {
        location = await getLocationByNative()
      }
    }
  } catch (err) {
    console.error('获取地址位置失败', err)
  }
  console.log('首页收货地址的经纬度', location)
  return location
}

/** 将埋点用的页面标题传给终端，用于上报用户浏览路径 */
export function addReferPage(params: AddReferPageParams) {
  try {
    if (isInApp()) {
      addReferPageByNative(params)
    }
  } catch (e) {
    console.error(e)
    aegis.error(e)
  }
}

/** 获取小程序端的refer */
export async function getRefer() {
  const { refer } = await getAppDataByBridge(['refer'])
  console.log('获取小程序端refer', refer)
  return refer
}

/** 获取终端埋点公参的方法 abtest ref相关 */
export function getNativeProperties() {
  const appVersion = session.getAppVersion()
  const oldAppVersion = '5.0.75' // 0328发版版本号
  if (appVersion && compareVersion(appVersion, oldAppVersion) >= 0) {
    return new Promise<Record<string, any>>((resolve) => {
      function getNativePropertiesCallback(jsonResult: string) {
        try {
          const nativeProperties = JSON.parse(jsonResult)
          console.log('nativeProperties转化后的数据：', nativeProperties)
          resolve(nativeProperties)
        } catch (err) {
          console.error('nativeProperties转化json失败：', err)
          console.log('nativeProperties的json数据：', jsonResult)
        }
      }

      try {
        sr.app.invoke('nativePropertiesForH5', {
          callback: getNativePropertiesCallback
        })
      } catch (err) {
        console.error('getNativeProperties调用错误：', err)
        resolve({})
      }
    })
  } else {
    return Promise.resolve<Record<string, any>>({})
  }
}

/** 上报abtest配置信息通知终端 */
export function reportAbTest(info: Record<number, string>) {
  try {
    const appVersion = session.getAppVersion()
    const oldAppVersion = '5.0.82'
    if (isInApp() && compareVersion(appVersion, oldAppVersion) >= 0) {
      sr.app.invoke('addAbtInfo', info)
    }
  } catch (err) {
    aegis.error(err)
  }
}
/** 更换照片 */
export function replaceUploadPhotoByBridge() {
  return new Promise<string>((resolve, reject) => {
    const appVersion = session.getAppVersion()
    const oldAppVersion = '5.0.93' // 20240124发版版本号
    if (appVersion && compareVersion(appVersion, oldAppVersion) >= 0) {
      try {
        sr.app.invoke('replaceUploadPhoto', {
          callback: (data: any) => {
            const { imgBase64 = '' } = JSON.parse((data || '').replace(/\n/g, '') || '{}')
            resolve(imgBase64 ? `data:image/jpeg;base64,${imgBase64}` : '')
          }
        })
      } catch (error) {
        console.error('replaceUploadPhoto调用错误：', error)
        resolve('')
      }
    } else {
      console.error('版本过低不支持replaceUploadPhoto调用')
      reject(new Error())
    }
  })
}

/** 身份认证 */
export function authenticationUploadPhotoByBridge() {
  return new Promise<string>((resolve, reject) => {
    const appVersion = session.getAppVersion()
    const oldAppVersion = '5.0.93' // 20240124发版版本号
    if (appVersion && compareVersion(appVersion, oldAppVersion) >= 0) {
      try {
        sr.app.invoke('authenticationUploadPhoto', {
          callback: (data: any) => {
            const { imgBase64 = '' } = JSON.parse((data || '').replace(/\n/g, '') || '{}')
            resolve(imgBase64 ? `data:image/jpeg;base64,${imgBase64}` : '')
          }
        })
      } catch (error) {
        console.error('authenticationUploadPhoto调用错误：', error)
        resolve('')
      }
    } else {
      console.error('版本过低不支持authenticationUploadPhoto调用')
      reject(new Error())
    }
  })
}

export async function getLocationByWx() {
  return new Promise<any>((resolve, reject) => {
    window.wx.getLocation({
      type: 'wgs84', // 默认为wgs84的 gps 坐标，如果要返回直接给 openLocation 用的火星坐标，可传入'gcj02'
      success: (location) => {
        console.log('微信环境的地理坐标', location)
        resolve({
          latitude: location.latitude.toString(),
          longitude: location.longitude.toString()
        })
      },
      fail: (e) => {
        console.error('微信环境获取地理位置信息失败', e)
        reject(e)
      }
    })
  })
}
