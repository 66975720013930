import { HttpClient } from '@tencent/retailwe-common-libs-http-client'
import registerAPIs from './register-apis'
import registerInterceptors from './register-interceptors'
// import registerEmonitor from './register-emonitor';
import { APIS } from './types'

export * from './types'

export const httpClient = new HttpClient()

// registerEmonitor(httpClient);
httpClient.fly.engine = XMLHttpRequest

// 设置日志的级别
httpClient.logger.level = 'debug'

registerInterceptors(httpClient)

export const apis: APIS = registerAPIs(httpClient)

export default httpClient
