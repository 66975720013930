import { SAMS_APP_AGENT, WE_CHAT_AGENT, RESPONSE_CODE, TVERSION_KEY } from '@/config'
import { SystemLanguage, LinkType, APP_SCHEME_LINK } from '@/config/constants'
import { jumpLinkType } from '@/config/interface'
import session from '@/common/session'
import httpClient, { apis } from '@/common/http-client'
import { navigateToByNative } from '@/common/jsBridge/nativeApp'
import { Level, Modal } from '@tencent/tea-component-mobile'
import md5 from 'md5'
import dayjs from 'dayjs'
import CryptoJS from 'crypto-js'
import { toTraditional } from './zhConvert'
import { aegis } from '@/common/aegis'
import Aegis from 'aegis-web-sdk'

interface Test {
  a: string
  b: string
}

export const getPhone = (): Test => {
  return {
    a: '1',
    b: '2'
  }
}

function isHave(m: string) {
  const userAgent = navigator.userAgent
  return userAgent.includes(m)
}

export const getPageInstance = (_props: any) => {
  return 'asfas'
}

export const isInApp = () => isHave(SAMS_APP_AGENT)

export const isInWx = () => isHave('MicroMessenger')

// 是否为iphone或者ipad
export const isIOS = () => isHave('Mac OS X')

/** 是否为Android */
export const isAndroid = () => {
  return /android/i.test(navigator.userAgent)
}

export const isHarmony = () => {
  return navigator.userAgent.includes('Harmony')
}

// 是否在真机环境
export const isInRealPhone = () => {
  return isIOS() || isAndroid()
}

/** 是否在山姆小程序 */
export function isInMiniProgram() {
  return isHave('miniProgram')
}

// 是否在本地调试环境
export const isLocalDebug = () => window.location.hostname === 'localhost'

/* 获取微信版本号 */
export const getWeixinVersion = () => {
  const wechatInfo = navigator.userAgent.match(/MicroMessenger\/([\d\.]+)/i)
  return wechatInfo ? wechatInfo[1] : ''
}

/**
 * 检测手机号是否正确
 * @param phone
 */
export function checkPhone(phone: string) {
  return /^1[3-9]\d{9}$/.test(phone)
}

/**
 * 检测6位数字的验证码
 * @param captcha
 */
export function checkCaptcha(captcha: string) {
  return /^\d{6}$/.test(captcha)
}
/**
 * 字符串去空格
 * @param str
 */
export function removeSpace(str: string) {
  return `${str}`.replace(/^\s\s*/, '').replace(/\s\s*$/, '')
}
export function fenToYuan(fen: string | number) {
  const _fen = String(fen)
  // eslint-disable-next-line radix
  return ((parseInt(_fen) || 0) / 100).toFixed(2)
}
/**
 * 格式化用户卡号
 * @param cardNumber
 */
export function cardNumberFormat(cardNumber: string, isSingle?: boolean) {
  let cardStr = cardNumber.replace(/((\d{4})(?=\d))/g, '$1 ')
  if (cardNumber.length % 4 === 1 && !isSingle) {
    cardStr = cardStr.replace(/(.*)\s/, '$1')
  }
  return cardStr
}

export function cardNumberFormat2(cardNumber: string) {
  let cardStr = ''
  if (cardNumber.length > 8) {
    cardStr = `${cardNumber.substring(0, 3)} ${cardNumber.substring(3, 9)} ${cardNumber.substring(
      9,
      cardNumber.length
    )}`
  } else {
    cardStr = cardNumber
  }
  return cardStr
}

export const isInWeChat = () => isHave(WE_CHAT_AGENT)

export const wechatAuth = (redirectUrl: string, appid: string, state?: string) => {
  // 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf28bbb0a8ce60b2a&redirect_uri=https%3A%2F%2Fm-qa-sams.walmartmobile.cn%2Froutes&response_type=code&scope=snsapi_base&state=test#wechat_redirect'

  window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
    redirectUrl
  )}&response_type=code&scope=snsapi_base&state=${state}#wechat_redirect`
}

/**
 * 检查身份证是否合法
 * @param idCode
 */
export function checkIDCard(idCode: string) {
  // 加权因子
  const weightFactor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
  // 校验码
  const checkCode = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']
  const code = `${idCode}`
  const last = idCode[17] // 最后一位
  const seventeen = code.substring(0, 17)
  // 判断最后一位校验码是否正确
  const arr = seventeen.split('')
  const len = arr.length
  let num = 0
  for (let i = 0; i < len; i++) {
    num = num + Number(arr[i]) * weightFactor[i]
  }
  // 获取余数
  const rest = num % 11
  const lastNo = checkCode[rest]

  // 格式的正则
  // 正则思路
  /*
  第一位不可能是0
  第二位到第六位可以是0-9
  第七位到第十位是年份，所以七八位为19或者20
  十一位和十二位是月份，这两位是01-12之间的数值
  十三位和十四位是日期，是从01-31之间的数值
  十五，十六，十七都是数字0-9
  十八位可能是数字0-9，也可能是X
  */
  const idCardPatter = /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/
  // 判断格式是否正确
  const format = idCardPatter.test(idCode)
  // 返回验证结果，校验码和格式同时正确才算是合法的身份证号码
  return last === lastNo && format
}

// 检查回乡证是否合法
export function checkCertificate(value: string) {
  return /^([A-Z]\d{6,11}(\(\w{1}\))?)$/.test(value)
}

// 检查护照是否合法
export function checkPassport(value: string) {
  return /^[A-Za-z0-9]{3,20}$/.test(value)
}
// 检查台胞证是否合法
export function checkMTP(value: string) {
  return /^\d{8}|^\d{18}|^[a-zA-Z0-9]{10}$/.test(value)
}

// 检查姓和名是否合法
export function checkName(value: string) {
  if (!value) {
    return false
  }
  return /^([a-zA-Z\u4e00-\u9fa5]+(·|•))*[a-zA-Z\u4e00-\u9fa5]+$/.test(value)
}

// 检查姓和名是否合法 港澳允许输入空格
export function checkNameNew(value: string) {
  if (!value) {
    return false
  }
  return /^(?! )[\u4e00-\u9fa5_a-zA-Z.·• ]*$/.test(value)
}

// 检查卡号是否合法
export function checkCardNo(value: string) {
  return /^[0-9]{17}$/.test(value)
}
/**
 * 根据身份证号码判断性别
 * 15位身份证号码：第7、8位为出生年份(两位数)，第9、10位为出生月份，第11、12位代表出生日
 * 18位身份证号码：第7、8、9、10位为出生年份(四位数)，第11、第12位为出生月份，
 * 第13、14位代表出生日期，第17位代表性别，奇数为男，偶数为女。
 */
// 根据身份证号获取年龄
export function getAge(idNo: string) {
  const len = idNo.length
  let strBirthday = ''
  if (len === 18) {
    // 处理18位的身份证号码从号码中得到生日和性别代码
    strBirthday = idNo.substr(6, 4) + '/' + idNo.substr(10, 2) + '/' + idNo.substr(12, 2)
  }
  if (len === 15) {
    let birthdayValue = ''
    birthdayValue = idNo.charAt(6) + idNo.charAt(7)
    if (parseInt(birthdayValue, 10) < 10) {
      strBirthday = '20' + idNo.substr(6, 2) + '/' + idNo.substr(8, 2) + '/' + idNo.substr(10, 2)
    } else {
      strBirthday = '19' + idNo.substr(6, 2) + '/' + idNo.substr(8, 2) + '/' + idNo.substr(10, 2)
    }
  }
  // 时间字符串里，必须是“/”
  const birthDate = new Date(strBirthday)
  const nowDateTime = new Date()
  let age = nowDateTime.getFullYear() - birthDate.getFullYear()
  // 再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
  if (
    nowDateTime.getMonth() < birthDate.getMonth() ||
    (nowDateTime.getMonth() === birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate())
  ) {
    age--
  }
  return age
}
/**
 * 获取字符长度，中英文
 * @param str 字符
 */
export function getGbLen(str: string) {
  let len = 0
  for (let i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) > 127 || str.charCodeAt(i) === 94) {
      len += 2
    } else {
      len++
    }
  }
  return len
}
/**
 *获取url参数
 */
export function urlParse(urlStr?: string) {
  let url = urlStr || window.location.search || window.location.href
  let obj: any = {}
  let reg = /[?&][^?&]+=[^?&]+/g
  let arr = url.match(reg)
  if (arr) {
    arr.forEach((item) => {
      let tempArr = item.substring(1).split('=')
      console.log(tempArr)
      let key = decodeURIComponent(tempArr[0])
      let val = decodeURIComponent(decodeURIComponent(tempArr[1]))

      obj[key] = val
    })
  }
  return obj
}

/**
 * 格式化价格 分转成元 两位小数，如果是100的倍数则不要小数
 * @param price 分
 */
export function formatPrice(price: number, isFormat?: boolean) {
  const fixed = price % 100 === 0 ? 0 : 2 // 小数点
  const _price = price === 0 ? 0 : (price / 100).toFixed(fixed)
  if (isFormat && _price > 1000) {
    return `${String(_price).slice(0, 1)},${String(_price).slice(1, 4)}`
  } else {
    return _price
  }
}

/**
 * 格式化优惠券价格 拆分小数点前后
 * @param price 分
 */
export function formatPrice2Array(price: number) {
  let newPrice = price
  let pArr = []
  if (newPrice) {
    newPrice = Math.round(parseFloat(String(newPrice)) * Math.pow(10, 8)) / Math.pow(10, 8)
    newPrice = Math.ceil(newPrice)
    pArr[0] = newPrice >= 100 ? String(newPrice).slice(0, -2) : '0'
    pArr[1] = String(newPrice + 100).slice(-2)
    if (pArr[1] === '00') {
      pArr[1] = ''
    } else if (pArr[1][1] === '0') {
      pArr[1] = pArr[1][0]
    }
  }
  return pArr
}

// 版本号对比
export const compareVersion = (v1: string, v2: string) => {
  if (isHarmony()) return 1 // 如果是鸿蒙 跳过业务版本号判断
  const v1List = v1.split('.')
  const v2List = v2.split('.')
  const len = Math.max(v1List.length, v2List.length)

  // 调整两个版本号位数相同
  while (v1List.length < len) {
    v1List.push('0')
  }
  while (v2List.length < len) {
    v2List.push('0')
  }

  // 循环判断每位数的大小
  for (let i = 0; i < len; i++) {
    const num1 = parseInt(v1List[i], 10)
    const num2 = parseInt(v2List[i], 10)

    if (num1 > num2) {
      return 1
    } else if (num1 < num2) {
      return -1
    }
  }

  return 0
}

// 回到顶部按钮控制显隐&方法
export const showScrollTop = (scrolltop: number, callback: (c: any) => void) => {
  let scrollTop = document.body.scrollTop || document.documentElement.scrollTop
  callback(scrollTop > scrolltop)
}
export const scrollToTop = () => {
  let scrollTop =
    window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
  const setI = setInterval(() => {
    scrollTop = scrollTop - scrollTop / 10
    window.scrollTo(0, scrollTop)
    if (scrollTop <= 1) {
      clearInterval(setI)
    }
  }, 10)
}

export function countDown(date: string) {
  if (date.length !== 14) {
    return '00000000'
  }
  let year: any = Number(date.substring(0, 4))
  let month: any = Number(date.substring(4, 6))
  let day: any = Number(date.substring(6, 8))
  let hours: any = Number(date.substring(8, 10))
  let mm: any = Number(date.substring(10, 12))
  let ss: any = Number(date.substring(12, 14))
  let now = new Date()
  let endDate = new Date(year, month - 1, day, hours, mm, ss)
  let leftTime = endDate.getTime() - now.getTime() // 计算剩余的毫秒数
  if (leftTime <= 0) {
    leftTime = 0
  }

  let leftsecond = Number(leftTime / 1000) // 计算剩余的秒数
  day = Math.floor(leftsecond / (60 * 60 * 24))
  let hour: any = Math.floor((leftsecond - day * 24 * 60 * 60) / 3600)
  let minute: any = Math.floor((leftsecond - day * 24 * 60 * 60 - hour * 3600) / 60)
  let second: any = Math.floor((leftTime / 1000) % 60)
  day = day > 9 ? String(day) : `0${day}`
  hour = hour > 9 ? String(hour) : `0${hour}`
  minute = minute > 9 ? String(minute) : `0${minute}`
  second = second > 9 ? String(second) : `0${second}`
  console.log(`${day}天${hour}小时${minute}分钟${second}秒`)
  return `${day}${hour}${minute}${second}`
}

/**
 * 格式化数字成 xx w
 * @param num
 */
export function formatNumToW(num: number) {
  const fixed = num % 10000 === 0 ? 0 : 2 // 小数点
  return num === 0 ? 0 : (num / 10000).toFixed(fixed) + 'w'
}

/**
 * 格式化数字成 xx w ,不要小数且最后一位为0  例子 2345567 =》230w
 * @param num
 */
export function formatToW(num: number) {
  return num === 0 ? 0 : parseInt(String(num / 100000), 10) * 10
}

/**
 * 解析年度账单关键词，根据字符串长度，显示一行或者两行，返回{line1:''，line2:''}，如果line2为空，就是一行显示
 * @param num
 */
export function parseKeyword(keyword: string) {
  let line1 = ''
  let line2 = ''
  const keywordLength = keyword.length
  // 小于等于3个字符，一行显示
  if (keywordLength <= 3) {
    line1 = keyword
  } else if (keywordLength === 4) {
    line1 = keyword.substr(0, 2)
    line2 = keyword.substr(2, 2)
  } else if (keywordLength === 5) {
    if (['感知元宇宙', '人生自定义'].indexOf(keyword) >= 0) {
      // 上2下3：感知元宇宙、人生自定义
      line1 = keyword.substr(0, 2)
      line2 = keyword.substr(2, 3)
    } else if (['不随波逐流', '与时代同行'].indexOf(keyword) >= 0) {
      // 上3下2：不随波逐流、与时代同行
      line1 = keyword.substr(0, 3)
      line2 = keyword.substr(3, 2)
    }
  }
  return {
    line1,
    line2
  }
}

/**
 * 生成从min到max的随机数
 * @param min number
 * @param max number
 */
export function randomNum(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

/**
 * QA环境下url search无法获取参数 使用href
 * @param name
 */
export function getQueryString(name: string) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  const r = location.href.split('?')[1].match(reg)
  if (r !== null) return decodeURI(r[2])
  return ''
}

/**
 * 检测邮箱是否合法
 * @param email
 */
export function checkEmail(email: string) {
  return /^([a-zA-Z]|[0-9])(\w|\-|\.)+@[a-zA-Z0-9-_.]+\.([a-zA-Z]{2,4})$/.test(email)
}

/**
 * 发票填写页校验发票抬头
 */
export function checkInvoiceTitle(buyerName: string) {
  const regexp = /[^\da-zA-Z\u4e00-\u9fa5\~\!\@\#\$\%\^\&\*\.\（\）\！\!\@\#\¥\￥\…\…\…\|\、\ \$\%\*\“\”\，\。\?\？\：\；\、\‘\’\《\》\?\+\-\—\—\<\>\"\'\(\)\[\]\f\-]+/
  return regexp.test(buyerName)
}

export const getValueBySystemLanguage = (
  valueCn: any,
  valueEn: any,
  language: SystemLanguage = session.getSystemLanguage() as SystemLanguage
) => {
  if (language === SystemLanguage.ENGLISH) {
    return valueEn
  } else {
    return valueCn
  }
}

/**
 * 数字转成汉字
 * @params num === 要转换的数字
 * @return 汉字
 * */
export const toChinesNum = (num: any) => {
  let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
  let unit = ['', '十', '百', '千', '万']
  let numTemp = parseInt(num, 10)
  let getWan = (temp: any) => {
    let strArr = temp.toString().split('').reverse()
    let newNum = ''
    let newArr: any = []
    strArr.forEach((item: any, index: number) => {
      newArr.unshift(item === '0' ? changeNum[item] : changeNum[item] + unit[index])
    })
    let numArr: any = []
    newArr.forEach((m: any, n: number) => {
      if (m !== '零') numArr.push(n)
    })
    if (newArr.length > 1) {
      newArr.forEach((m: any, n: number) => {
        if (newArr[newArr.length - 1] === '零') {
          if (n <= numArr[numArr.length - 1]) {
            newNum += m
          }
        } else {
          newNum += m
        }
      })
    } else {
      newNum = newArr[0]
    }

    return newNum
  }
  let overWan = Math.floor(numTemp / 10000)
  let noWan: any = numTemp % 10000
  if (noWan.toString().length < 4) {
    noWan = '0' + noWan
  }
  return overWan ? getWan(overWan) + '万' + getWan(noWan) : getWan(numTemp)
}

/**
 * 预加载图片
 * @params images 图片的src数组
 * @return
 * */
export const preloadImage = (images: string[] = []) => {
  images.forEach((item) => {
    const tmpImg = new Image()
    tmpImg.src = item
  })
}

// 手机号脱敏处理  13466668888    +8613466668888
export const phoneSecurity = (phone: string | number) => {
  const phoneStr = String(phone)
  const strLength = phoneStr.length
  if (!strLength) {
    return '-'
  }
  if (strLength === 11) {
    return `${phoneStr.substr(0, 3)}****${phoneStr.substring(7)}`
  }
  if (strLength === 14) {
    return `${phoneStr.substr(0, 6)}****${phoneStr.substring(10)}`
  }
  return phone
}
/**
 * 点击跳转方法
 * @params jumpLink B端配置跳转组件的参数
 * */
export const jumpToLink = (jumpLink: jumpLinkType) => {
  try {
    if (
      jumpLink.linkType === LinkType.SYSTEM ||
      jumpLink.linkType === LinkType.DISCOVER ||
      jumpLink.linkType === LinkType.DESIGN
    ) {
      if (jumpLink.appLink) {
        // 优先app链接
        console.log('打开app页面', jumpLink.appLink)
        navigateToByNative(jumpLink.appLink || '')
        return
      }
      if (jumpLink.miniLink) {
        // 山姆小程序链接
        console.log('打开小程序页面', jumpLink.miniLink)
        const _miniprogramType = import.meta.env.MODE === 'qa' ? 2 : 0 // 0 正式版  1 开发版  2 体验版
        navigateToByNative(
          `sams://mini_app?userName=${
            import.meta.env.VITE_MINI_ORIGINAL_ID
          }&path=${encodeURIComponent(jumpLink.miniLink)}&miniprogramType=${_miniprogramType}`
        )
        return
      }
    } else if (jumpLink.linkType === LinkType.MINIAPP) {
      if (jumpLink.appLink?.startsWith('http')) {
        sr.app.invoke('viewInBrowser', {
          openUrl: jumpLink.appLink || ''
        })
      } else if (jumpLink.miniId && jumpLink.miniLink) {
        const _miniprogramType = import.meta.env.MODE === 'qa' ? 2 : 0 // 0 正式版  1 开发版  2 体验版
        if (jumpLink.miniId.startsWith('gh_')) {
          // 配置原始id可以直接跳转
          navigateToByNative(
            `sams://mini_app?userName=${jumpLink.miniId}&path=${encodeURIComponent(
              jumpLink.miniLink
            )}&miniprogramType=${_miniprogramType}&memCode=${jumpLink.memCode}`
          )
          return
        } else {
          // 配置appid需要经过山姆小程序跳转
          jumpToExternalMini(jumpLink.miniId, jumpLink.miniLink)
        }
      }
      console.error('跳转链接配置错误', jumpLink)
    } else if (jumpLink.linkType === LinkType.H5) {
      navigateToByNative(jumpLink.h5Link || '')
    }
  } catch (error) {
    console.log('跳转链接数据解析错误')
  }
}

/**
 * 判断一个元素是否在可视区域中
 * @params element 元素
 * */
export const isInViewPort = (element: any) => {
  const viewWidth = window.innerWidth || document.documentElement.clientWidth
  const viewHeight = window.innerHeight || document.documentElement.clientHeight
  const { top, right, bottom, left } = element.getBoundingClientRect()

  return top >= 0 && left >= 0 && right <= viewWidth && bottom <= viewHeight
}

/**
 * 数组中根据某个字段进行分组  类似sql中的group by
 * @params arr 原数组  name 分组依据的字段名字
 * */

export const mapLoction = (arr: any, name: string) => {
  const newArr: any = []
  arr.forEach((oldData: any, i: number) => {
    let index = -1
    const alreadyExists = newArr.some((newData: any, j: number) => {
      if (oldData[name] === newData[name]) {
        index = j
        return true
      }
      return false
    })
    if (!alreadyExists) {
      newArr.push({
        [name]: oldData[name],
        res: [oldData]
      })
    } else {
      newArr[index].res.push(oldData)
    }
  })
  return newArr
}

// 会员任务的任意分享页面上报  功能保留
export const arbitraryShare = async (params: any) => {
  const uid = session.getUid()
  console.log('给终端的数据为:', params)
  try {
    sr.app.invoke('configShareInfo', {
      ...params
    })

    // const events = [
    //   {
    //     eventType: 8,
    //     uid,
    //     eventData: JSON.stringify({ articleId, articleType })
    //   }
    // ]
    // const { code, data } = await apis.task.taskreport({
    //   events
    // })
    // console.log('code为:', code)
    // if (code === RESPONSE_CODE.SUCCESS) {
    //   console.log('任意分享成功')
    // }
  } catch (error) {}
}

/**
 * 中英文混合字符串的截取
 * @param str
 * @param length
 * @returns string
 */
export const strProcess = (str: string, length: number) => {
  if (!str) {
    return ''
  }
  const regexp = /[^\x00-\xff]/g
  if (str.replace(regexp, 'aa').length <= length) {
    return str
  }
  const m = Math.floor(length / 2)
  const j = str.length
  for (let i = m; i < j; i++) {
    if (str.substring(0, i).replace(regexp, 'aa').length >= length) {
      return str.substring(0, i) + '...'
    }
  }
  return str
}

/**
 * 等待time毫秒后再执行后续代码
 * @param time 要等待的时间 单位 毫秒
 * @returns
 */
export function sleep(time: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, time || 0)
  })
}

/** 是否在webview(山姆APP或山姆小程序) */
export function isInWebview() {
  return isInApp() || isInMiniProgram()
}

export function isHttpUrl(url: string) {
  return /^http(|s)\:\/\//.test(url)
}

export function getHttpUrlByUrl(url: string) {
  if (url.startsWith(APP_SCHEME_LINK)) {
    return window.decodeURIComponent(url.replace(APP_SCHEME_LINK, ''))
  } else {
    return url
  }
}

/** 在url里插入泳道标识 */
export function insertTversion(url: string) {
  // 本地开发环境不变更url

  if (process.env.NODE_ENV === 'development') return url

  const httpUrl = getHttpUrlByUrl(url)
  // 如果不是http链接则不做任何处理（比如小程序原生链接）
  if (!isHttpUrl(httpUrl)) return url

  const tversion = localStorage.getItem(TVERSION_KEY) || ''

  // 将http链接注入到a标签进行解析
  const location = document.createElement('a')
  location.href = httpUrl
  const { origin, pathname, search, hash } = location
  // 如果tversion存在并且跳转的是同源页面，则在url里插入tversion
  if (tversion && origin === window.location.origin) {
    // console.log(origin, 'origin', pathname, 'pathname')
    if (pathname.includes(tversion)) {
      // 已存在tversion
      // console.log('已存在tversion', `${origin}${pathname}${search}${hash}`)
      return `${origin}${pathname}${search}${hash}`
    } else {
      // console.log('不存在tversion', `${origin}/${tversion}${pathname}${search}${hash}`)
      return `${origin}/${tversion}${pathname}${search}${hash}`
    }
  } else {
    return url
  }
}

/** 返回浏览器类型 */

export function returnBrowserType() {
  if (isInApp()) {
    return `Sam's`
  } else if (isInWx()) {
    return 'WeChat'
  } else if (isInMiniProgram()) {
    return 'mini'
  } else {
    return navigator.userAgent
  }
}

// 过滤对象空值
export function filterEmptyValue(obj: any = {}) {
  for (const key in obj) {
    // obj[key]为（空字符串，null, undefined，空对象，空数组）就删除
    if (
      obj[key] === '' ||
      obj[key] === null ||
      obj[key] === undefined ||
      (Object.prototype.toString.call(obj[key]) === '[object Object]' &&
        Object.keys(obj[key]).length === 0) ||
      (Array.isArray(obj[key]) && obj[key].length === 0)
    ) {
      delete obj[key]
    }
  }
  return obj
}

// 跳转外部小程序
export const jumpToExternalMini = async (miniId: string, miniPage: string) => {
  try {
    sr.app.invoke('showLoading', {
      showLoading: true
    })
    const { code, data } = await apis.message.getMiniURLScheme({
      query: `miniId=${miniId}&miniLink=${miniPage}`,
      miniPage: '/pages/discoverWebView/index',
      envVersion: import.meta.env.MODE === 'prod' ? 'release' : 'trial' // 'trial' 'release'
    })
    sr.app.invoke('showLoading', {
      showLoading: false
    })
    if (code === RESPONSE_CODE.SUCCESS) {
      sr.app.invoke('viewInBrowser', {
        openUrl: data
      })
    }
  } catch (e) {}
}

/**
 * 金额最小值为0
 * @param amount number | string
 */
export function sanitizeAmount(amount = 0 || '0') {
  return Math.max(0, Number(amount))
}

/**
 * 安全加密
 * @params params
 * @params salt
 */
export const getSafeSign = (
  params: { [index: string]: string },
  salt = import.meta.env.VITE_SHORTURL_SALT
) => {
  const valueArr: string[] = []
  Object.keys(params)
    .sort()
    .forEach((key: string) => {
      valueArr.push(`${key}=${params[key]}`)
    })
  const valueStr = valueArr.join('&')
  const originalStr = md5(valueStr) + salt
  return md5(originalStr)
}

// 金额三位间隔
export const amountInterval = (key: number, splitSymbol = ',') => {
  let amount = String(key)
  let x = amount.split('.')
  let x1 = x[0]
  let x2 = x.length > 1 ? '.' + x[1] : ''
  let reg = /(\d+)(\d{3})/
  while (reg.test(x1)) {
    x1 = x1.replace(reg, `${'$1'}${splitSymbol}${'$2'}`)
  }
  return x1 + x2
}

/**
 * 格式化时间戳(年月日)
 * @param {string | number} value 时间戳
 */
export const formatDate = (value: string | number) => {
  const date = dayjs(Number(value))
  if (!date.isValid()) return ''
  const year = date.year()
  const month = date.month() + 1
  const day = date.date()

  return `${year}年${month.toString().padStart(2, '0')}月${day.toString().padStart(2, '0')}日`
}

/**
 * 千位符逗号并保留1-2位小数
 * @param {number} num
 * @return {*}
 */
export const formatPriceWithCommas = (num: number) => {
  let price = (num / 100).toFixed(2)
  price = price.replace(/\.00$/, '').replace(/(\.\d)0$/, '$1')
  price = price.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return price
}

/**
 * 价格小数点处理
 * 传入的参数需要是分  /100
 * @param price
 */
export function dealPrice(price: number) {
  if (!price) {
    return { upPriceData: 0, DataPrice: 0 }
  }
  const newPrice = (price / 100).toFixed(2)
  // 通过小数点分段
  const priceArray = String(newPrice).split('.')
  return { upPriceData: priceArray[0], DataPrice: priceArray[1] }
}

// 上报实验信息
export const reportExperiment = async (params: any[] | object) => {
  try {
    const timestamp = new Date().getTime()
    let abTestList: any = []
    // 列表多个上报
    if (Array.isArray(params)) {
      abTestList = params.map((item: any) => {
        return {
          ...item,
          timestamp
        }
      })
    } else {
      // 单个上报
      abTestList = [{ ...params, timestamp }]
    }
    await apis.experiment.report(abTestList)
  } catch (e) {
    console.log(e)
  }
}

/**
/**
 * 在小程序里H5的离开埋点（需要页面是单独打开的 webview）
 * @param params 上报数据
 */
export const miniH5LeaveTracking = (params: any) => {
  if (isInMiniProgram()) {
    // 不加延迟会报找不到微信方法
    setTimeout(() => {
      const props = {
        ...params,
        start_time: Date.now(), // 记录页面开始时间,
        event_name: 'sam_h5_leave_page'
      }
      window.wx.miniProgram.postMessage({
        data: {
          type: 'leave', // 与小程序端约定的type
          props
        }
      })
    }, 500)
  }
}

/*
 * 毫克转千克
 */
export const mgToKg = (num: number) => {
  if (!Number(num)) return 0
  // 1 千克(kg)=1000000 毫克(mg)
  return num / 1000000
}

/**
 * @description 多语言翻译
 * @param {string} value
 * @return {string}
 */
export const multiTranslation = (value: string) => {
  if (!value) return ''
  /** 当前系统语言-繁体语言 */
  const isTC = session.getLanguage() === SystemLanguage.TRADITIONAL_CHINESE
  if (isTC) {
    return toTraditional(value)
  }
  return value
}

/**
 * 版本检查弹窗，不符合的版本就提示
 * @param functionalVersion 功能版本
 * @param tips 提示语
 * @returns
 */
export const checkVersionModal = (functionalVersion: string, tips?: string) => {
  if (!functionalVersion) return
  // 检查版本是否符合要求
  let isNewVersion = true
  const currentVersion = session.getAppVersion()
  if (currentVersion && compareVersion(currentVersion, functionalVersion) < 0) {
    Modal.show({
      title: '',
      content: tips || '请先更新至最新版本',
      hideCancel: true
    })
    isNewVersion = false
  }
  return isNewVersion
}

/**
 * 根据语言返回中文或英文
 * @param isEn 当前语言是英文还是中文
 * @param cnStr 中文字符串
 * @param enStr 英文字符串
 * return string
 */
export function getCnOrEn(isEn: boolean, cnStr: string, enStr: string) {
  return enStr ? (isEn ? enStr : cnStr) : cnStr
}

// 敏感信息加解密 20241031
/**
 * https://tapd.tencent.com/tapd_fe/20418442/story/detail/1120418442119105779
 */
export function MessageEncrypt(data: string, key = 'tencent_smmember', iv = 'tencent_memberiv') {
  // 编码
  const key_word = CryptoJS.enc.Utf8.parse(key)
  const iv_word = CryptoJS.enc.Utf8.parse(iv)
  const data_word = CryptoJS.enc.Utf8.parse(data)
  // 加密模式为CBC，补码方式为Pkcs7
  let encrypted = CryptoJS.AES.encrypt(data_word, key_word, {
    iv: iv_word,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  // 返回base64
  return CryptoJS.enc.Base64.stringify(encrypted.ciphertext)
}

export function MessageDecrypt(data: string, key = 'tencent_smmember', iv = 'tencent_memberiv') {
  // 编码
  const key_word = CryptoJS.enc.Utf8.parse(key)
  const iv_word = CryptoJS.enc.Utf8.parse(iv)
  // Base64编码
  const base64 = CryptoJS.enc.Base64.parse(data)
  const base64Str = CryptoJS.enc.Base64.stringify(base64)
  const decrypt = CryptoJS.AES.decrypt(base64Str, key_word, {
    iv: iv_word,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
  return decryptedStr.toString()
}
export const getHelpCenterContent = async (id: string | number) => {
  try {
    const getContent = httpClient.registerApi({
      url: `/v1/sams/sams-user/help-center/${id}`,
      config: {
        forceLogin: false // 帮助中心的协议暂时无需登录态
      }
    })
    const { data, code } = await getContent()
    if (code === RESPONSE_CODE.SUCCESS && data) {
      const { title } = data
      // 过滤空标签
      const emptyTag = /<([a-z]+?)(?:\s+?[^>]*?)?>\s*?<\/\1>/gi
      const content = data.content.replace(emptyTag, '')
      return {
        title,
        content
      }
    }
  } catch (error) {
    console.log('获取内容失败', error)
  }
}

// 上报
export const commonReport = (info: object, url: string, type: 'res' | 'req') => {
  try {
    aegis.report({
      level: Aegis.logType.EVENT, // 自定义日志
      msg: JSON.stringify(info || {}),
      ext1: url || '',
      ext2: type || ''
    })
  } catch (err) {}
}

export const jsbErrorReport = (info: object, methodName: string, originJSON: string) => {
  try {
    aegis.report({
      level: Aegis.logType.BRIDGE_ERROR,
      msg: JSON.stringify(info || {}),
      ext1: methodName || '',
      ext2: originJSON
    })
  } catch (err) {}
}
