import { IInterceptor } from '@tencent/retailwe-common-libs-http-client'
import { EnumRequestLevel } from '../types'
import { bizErrorHandle } from '../libs/index'
import { RESPONSE_CODE } from '@/config/constants'

export default function genInterceptor(): IInterceptor {
  return {
    name: 'bizResHandler',
    response: async (res) => {
      const { request, data } = res
      const { level = EnumRequestLevel.Minor } = request
      const code = data?.code || ''
      /**
       * 添加参数 控制是否透出错误提示 为不影响旧功能
       * hideErrorToast 隐藏 接口 success 为false 的fail弹窗 初始值为undefined
       */
      if (code !== RESPONSE_CODE.SUCCESS) {
        if (!request?.hideErrorToast) await bizErrorHandle(level || 0, data)

        // 根据配置，遇到业务错误码，抛出异常
        if (request.throwWhileBizCodeError) throw data
        return data
      }

      return res.data
    }
  }
}
