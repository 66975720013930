import { AUTH_KEY_NAME, H5_KEY, RESPONSE_CODE, UID_KEY } from '@/config'
import { IInterceptor, HttpClient } from '@tencent/retailwe-common-libs-http-client'
import Cookies from 'js-cookie'
import { isInApp } from '@/utils'
import { aegis } from '@/common/aegis'

export default function genInterceptor(httpClient: HttpClient): IInterceptor {
  return {
    name: 'auth',
    request: async function (this: any, req) {
      // 登录态拦截 通过 req.forceLogin 进行强制登录开关
      if (req.forceLogin === false) {
        return req
      }
      if (isInApp()) {
        // App
        // TODO 读写 cookie，判断 token 有效性
        const token = Cookies.get(AUTH_KEY_NAME) ?? ''
        if (!token && sr) {
          sr.app.invoke('login')
        }
      }
      return req
    },

    response: async (res) => {
      const { data } = res
      const code = (data?.code || '').toUpperCase()

      if (code === RESPONSE_CODE.AUTH_FAIL) {
        if (isInApp() && sr) {
          // App
          sr.app.invoke('login')
        } else {
          // H5
          try {
            const _loginData = sessionStorage.getItem('__loginData')
            if (_loginData) {
              const loginData = JSON.parse(_loginData)
              const {
                inviteCode,
                firstName,
                encryptionInfo,
                lastName,
                memType,
                shareCash
              } = loginData
              let url
              if (inviteCode) {
                // 邀请好友
                url = `/h5/invite-friend?inviteCode=${inviteCode}&firstName=${firstName}&lastName=${lastName}`
              }
              if (encryptionInfo) {
                // 亲友卡
                url = `/h5/family-card?encryptionInfo=${encryptionInfo}&firstName=${firstName}&lastName=${lastName}&memType=${memType}&shareCash=${shareCash}`
              }
              if (url) {
                window.location.href = url
              }
            }
          } catch (error) {
            aegis.error('sessionStorage getItem error')
          }
        }
      }
      return res
    }
  }
}
