import {
  UID_KEY,
  APP_VERSION_KEY,
  LAN_KEY,
  SystemLanguage,
  TVERSION_KEY,
  EXT_LAN_KEY
} from '@/config'
import Cookies from 'js-cookie'
class Session {
  getSession: () => any = () => {
    // TODO 获取本地用户信息
  }

  getOpenId() {
    // TODO 获取 openId
    return ''
  }

  getUid() {
    // TODO 获取 uid
    return Cookies.get(UID_KEY) || ''
  }

  getAppVersion() {
    return Cookies.get(APP_VERSION_KEY) || ''
  }
  getSystemLanguage(): SystemLanguage | '' {
    return (Cookies.get(LAN_KEY) as SystemLanguage) || 'CN'
  }
  getIsEn(): boolean {
    return this.getSystemLanguage() === 'EN'
  }
  getTversion() {
    return Cookies.get(TVERSION_KEY) || ''
  }
  getLanguage(): SystemLanguage | '' {
    return (Cookies.get(EXT_LAN_KEY) as SystemLanguage) || 'CN'
  }
}

export default new Session()
