// 标准上报key值索引
enum SendKeyEnum {
  // 页面浏览
  BrowseWxappPage = 'browse_wxapp_page',
  // 页面离开
  LeaveWxappPage = 'leave_wxapp_page',
  // 页面浏览2
  WebBrowsePage = 'WebBrowse_page',
  // 页面离开2
  LeavePage = 'leave_page',
  // 页面下拉刷新
  PagePullDownRefresh = 'page_pull_down_refresh',
  // 页面上拉触底
  PageReachBottom = 'page_reach_bottom',
  // 页面分享
  PageShareAppMessage = 'page_share_app_message',
  // 元素事件
  Element = 'element',
  // 商品页浏览
  BrowseSkuPage = 'browse_sku_page',
  // 用户注册，一期去掉
  RegisterWxapp = 'register_wxapp',
  // 用户登录，一期去掉
  LoginWxapp = 'login_wxapp',
  // 商品曝光
  ExposeSkuComponent = 'expose_sku_component',
  // 商品卡触发（即点击）
  TriggerSkuComponent = 'trigger_sku_component',
  // 加购
  AddToCart = 'add_to_cart',
  // 领取优惠券
  GetCoupon = 'get_coupon',
  // 订单状态变更（前端上报下单行为）
  CustomOrder = 'custom_order',
  // 搜索
  Search = 'search',
  // 直播：商品页浏览
  LiveBrowseSkuPage = 'browse_sku_page',
  // 直播：进入直播间
  BrowseLivePage = 'browse_live_page',
  // 广告曝光
  ExposeAds = 'expose_ads',
  // 广告点击 815 埋点产品说统一都改成ads
  TriggerAd = 'trigger_ads'
}
export { SendKeyEnum }
