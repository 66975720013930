/**
 * 同盾风控埋点上报
 */
import { getAppDataByBridge } from '@/common/jsBridge/jsBridgeApi'
import { srInstance } from '@/common/mall-tracking/sr-adapter'
import { isInApp } from '@/utils'

/**
 * 同盾参数请求头的key
 */
export const TONGDUN_REPORT_KEY = 'tongDunReportData'

/**
 * 初始化同盾sdk
 */
export const initTongDun = () => {
  window._fmOpt = {
    partner: 'sam',
    appName: 'web',
    token: 'sams-' + new Date().getTime() + '-' + Math.random().toString(16).substr(2),
    fpHost: import.meta.env.VITE_TONGDUN_HOST_URL as string, // 'https://tongdun-fingerprint-qa.walmartmobile.cn',
    fmb: true,
    success: function (data: string) {
      window._fmOpt.tdblackbox = data // 把设备指纹写入window对象
    }
  }
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = window._fmOpt.fpHost + '/static/fm.js?ver=0.1'
  document.getElementsByTagName('body')[0].appendChild(script)
}

/**
 * 获取设备指纹 同盾风控
 *
 */
export const getblackbox = () => {
  return window._fmOpt.tdblackbox || (window._fmOpt.getinfo ? window._fmOpt.getinfo() || '' : '')
}

/**
 * 调用类型
 */
export enum ApiModel {
  /**
   * 实时无状态 事中上报
   */
  IN_EVENT = 10,
  /**
   * 实时状态上报 业务结果上报
   */
  EVENT_RESULT = 11
}

/**
 * 策略编码
 * @param params
 */
export enum PolicyCode {
  /**
   * 注册
   */
  APP_REGISTER = 'app_register',
  /**
   * 登录
   */
  APP_LOGIN = 'app_login',
  /**
   * 发送验证短信 短信验证事件
   */
  APP_MSNVALID = 'app_msnvalid',
  /**
   * 购卡事件
   */
  APP_PURCHASE = 'app_purchase',
  /**
   * 绑卡事件
   */
  APP_BINDING = 'app_binding',
  /**
   * 赠卡事件
   */
  APP_GIFT = 'app_gift',
  /**
   * 亲友卡激活事件
   */
  APP_ACTIVATE = 'app_activate',
  /**
   * 上传头像事件
   */
  APP_UPLOAD = 'app_upload',
  /**
   * 会籍交易事件
   */
  APP_RENEWAL = 'app_renewal',
  /**
   * 邀新事件 APP/小程序：邀请好友，点击“现在邀请”按钮后，选择微信分享或生成图片时调用
   */
  APP_INVITE = 'app_invite',
  /**
   * 交易事件 APP/小程序：下单结算或用户主动取消下单
   */
  APP_TRADE = 'app_trade',
  /**
   * 支付事件 APP/小程序：选择支付方式后，点击“确认支付” 时调用
   */
  APP_PAYMENT = 'app_payment',
  /**
   * 退款事件
   */
  APP_REFUND = 'app_refund'
}

/**
 * 生成同盾相关参数
 */
export const createReportParams = async (params: any = {}) => {
  const blackbox = await getblackbox()
  const srUserInfo = srInstance.getUser() || {} // 有数sdk里的userInfo

  const {
    provinceCode = '',
    cityCode = '',
    receiverAddress = '',
    tongdunParam = ''
  } = await getAppDataByBridge(['provinceCode', 'cityCode', 'receiverAddress', 'tongdunParam'])

  const tongdunInitValue = {
    latitude: 0,
    longitude: 0,
    gpsProv: provinceCode,
    gpsCity: cityCode,
    gpsAddr: receiverAddress
  }
  if (isInApp()) {
    //  在APP内获取终端传递过来加密的tongdunParam 通过base64解密 获取上报的参数  不在APP内的公参 走原有逻辑
    try {
      const tongdunStr = Base64.decode(tongdunParam)
      const { latitude = 0, longitude = 0, gpsProv, gpsCity, gpsAddr } =
        JSON.parse(tongdunStr) || {}
      tongdunInitValue['gpsProv'] = gpsProv || provinceCode
      tongdunInitValue['gpsCity'] = gpsCity || cityCode
      tongdunInitValue['gpsAddr'] = gpsAddr || receiverAddress
      tongdunInitValue['latitude'] = latitude
      tongdunInitValue['longitude'] = longitude
    } catch (e) {}
  }

  const header = {
    blackbox,
    UUID: '',
    ...tongdunInitValue,
    appChannel: 'h5',
    appType: 'web',
    localid: srUserInfo.local_id,
    ...params[TONGDUN_REPORT_KEY] // 如果请求参数里有同盾的其他参数，也一起编码
  }
  console.log('同盾参数', header) // 为了调试方便，mr时要删掉
  try {
    const headerStr = base64Encode(unescape(encodeURIComponent(JSON.stringify(header))))
    return headerStr
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
  return ''
}

export const tongdunApiList: string[] = [
  `/v1/sams/sams-user/authorization/send_code`,
  `/v1/sams/sams-user/authorization/mobile`,
  `/v1/sams/sams-user/membership/gift_card/register`,
  `/v1/sams/sams-user/membership/trans/renew`,
  `v1/sams/sams-user/membership/trans/save_worry_renew_new`,
  `/v1/sams/sams-user/membership/trans/buy`,
  '/v1/sams/activity/exchange-code/exchange',
  '/v1/sams/activity/experience-card-launch/queryShareDetai',
  '/v1/sams/activity/experience-card-nuclear/doInvite',
  '/v1/sams/sams-user/experience-card/qualification/share/get',
  '/v1/sams/sams-user/experience-card/qr/get',
  '/v1/sams/sams-user/experience-card/result/query',
  '/v1/sams/sams-user/experience-card/qualification/create_code',
  '/v1/sams/activity/explore-handbook/lockTask'
]

/**
 * base64编码字符串
 * @param str 字符串
 * @returns string
 */
function base64Encode(str: string) {
  let c1: number
  let c2: number
  let c3: number
  const base64EncodeChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
  let i = 0
  let string = ''
  const len = str.length
  while (i < len) {
    c1 = str.charCodeAt(i++) & 0xff
    if (i === len) {
      string += base64EncodeChars.charAt(c1 >> 2)
      string += base64EncodeChars.charAt((c1 & 0x3) << 4)
      string += '=='
      break
    }
    c2 = str.charCodeAt(i++)
    if (i === len) {
      string += base64EncodeChars.charAt(c1 >> 2)
      string += base64EncodeChars.charAt(((c1 & 0x3) << 4) | ((c2 & 0xf0) >> 4))
      string += base64EncodeChars.charAt((c2 & 0xf) << 2)
      string += '='
      break
    }
    c3 = str.charCodeAt(i++)
    string += base64EncodeChars.charAt(c1 >> 2)
    string += base64EncodeChars.charAt(((c1 & 0x3) << 4) | ((c2 & 0xf0) >> 4))
    string += base64EncodeChars.charAt(((c2 & 0xf) << 2) | ((c3 & 0xc0) >> 6))
    string += base64EncodeChars.charAt(c3 & 0x3f)
  }
  return string
}

/**
 * base64解码
 * @param input 字符串
 * @returns string
 */

const Base64 = {
  keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',

  decode(input: string) {
    let output = ''
    let chr1
    let chr2
    let chr3
    let enc1
    let enc2
    let enc3
    let enc4
    let i = 0
    const inputStr = input.replace(/[^A-Za-z0-9+/=]/g, '')
    while (i < inputStr.length) {
      enc1 = Base64.keyStr.indexOf(inputStr.charAt(i++))
      enc2 = Base64.keyStr.indexOf(inputStr.charAt(i++))
      enc3 = Base64.keyStr.indexOf(inputStr.charAt(i++))
      enc4 = Base64.keyStr.indexOf(inputStr.charAt(i++))
      chr1 = (enc1 << 2) | (enc2 >> 4)
      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2)
      chr3 = ((enc3 & 3) << 6) | enc4
      output = output + String.fromCharCode(chr1)
      if (enc3 !== 64) {
        output = output + String.fromCharCode(chr2)
      }
      if (enc4 !== 64) {
        output = output + String.fromCharCode(chr3)
      }
    }
    output = Base64.utf8Decode(output)
    return output
  },

  utf8Decode(utfString: string) {
    let string = ''
    let i = 0
    let c = 0
    let c2 = 0
    let c3 = 0
    while (i < utfString.length) {
      c = utfString.charCodeAt(i)
      if (c < 128) {
        string += String.fromCharCode(c)
        i++
      } else if (c > 191 && c < 224) {
        c2 = utfString.charCodeAt(i + 1)
        string += String.fromCharCode(((c & 31) << 6) | (c2 & 63))
        i += 2
      } else {
        c2 = utfString.charCodeAt(i + 1)
        c3 = utfString.charCodeAt(i + 2)
        string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63))
        i += 3
      }
    }
    return string
  }
}
