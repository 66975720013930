/** 视口单位 */
export type Unit = 'px' | 'vw' | 'vh' | 'vmin' | 'vmax'
/** 标准屏的屏幕宽度（x2），本项目默认为750 */
export const normalScreenWidth = 750

export interface Options {
  /** 需要转换的单位，默认为"px" */
  unitToConvert?: Unit
  /** 设计稿的视口宽度，本项目默认为750 */
  viewportWidth?: number
  /** 单位转换后保留的精度，默认为5 */
  unitPrecision?: number
  /** 希望使用的视口单位，默认为"vw"" */
  viewportUnit?: Unit
  /** 设置最小的转换数值，如果为1的话，只有大于1的值会被转换 */
  minPixelValue?: number
}
interface GetRealPxOptions {
  /** 输出值的单位 */
  unit: 'vw' | 'px'
  /** 输出值的精度（小数点位数） */
  precision?: number
}

/** 默认配置 */
export const defaultOptions: Required<Options> = {
  unitToConvert: 'px',
  viewportWidth: normalScreenWidth,
  unitPrecision: 5,
  viewportUnit: 'vw',
  minPixelValue: 1
}

/** 根据视口单位生成正则表达式 */
export function getUnitRegexp(unit: Unit) {
  return new RegExp('"[^"]+"|\'[^\']+\'|url\\([^\\)]+\\)|(\\d*\\.?\\d+)' + unit, 'g')
}

/** 取小数点后N位 */
function toFixed(number: number, precision: number): number {
  const multiplier = Math.pow(10, precision + 1)
  const wholeNumber = Math.floor(number * multiplier)
  return (Math.round(wholeNumber / 10) * 10) / multiplier
}

/** 单位换算（目前只支持px转vw） */
export function pxToViewport(value: number | string | undefined, options: Options = {}) {
  if (!value) return value

  const opts = { ...defaultOptions, ...options } // 合并配置项
  const pxRegexp = getUnitRegexp(opts.unitToConvert) // 生成对应的正则表达式
  // 根据value的类型，补全单位后缀
  const strValue = typeof value === 'number' ? `${value}${opts.unitToConvert}` : value

  return strValue.replace(pxRegexp, (m, $1) => {
    // 如果正则不匹配则原路返回
    if (!$1) return m

    // 如果小于等于最小值，则原路返回
    const pixels = parseFloat($1)
    if (pixels <= opts.minPixelValue) return m

    // 根据参数进行单位换算
    const parsedVal = toFixed((pixels / opts.viewportWidth) * 100, opts.unitPrecision)
    return parsedVal === 0 ? '0' : parsedVal + opts.viewportUnit
  })
}

/** 根据其他的长度单位获取真正的px（如果传入的是px值，需要按750的屏幕标准进行换算） */
export function getRealPx(value: number, options: { unit: 'vw' | 'px'; precision?: number }) {
  const { unit = 'vw', precision = 2 } = options || {}
  if (unit === 'vw') return window.innerWidth * value
  if (unit === 'px') return toFixed((value / normalScreenWidth) * window.innerWidth, precision)
  return 0
}

export function getRealPxByVw(value: number) {
  return getRealPx(value, { unit: 'vw' })
}

export function getRealPxByPx(value: number) {
  return getRealPx(value, { unit: 'px' })
}
/** 根据实际的px或vw值算出它在UI稿上的px值（按750屏幕） */
export function getUiPx(value: number, options: GetRealPxOptions) {
  const { unit = 'vw', precision = 2 } = options || {}
  if (unit === 'vw') return normalScreenWidth * value
  if (unit === 'px') return toFixed((value / window.innerWidth) * normalScreenWidth, precision)
  return 0
}
/** 根据实际的px值算出它在UI稿上的px值 */
export function getUiPxByRealPx(value: number) {
  return getUiPx(value, { unit: 'px' })
}
