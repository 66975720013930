import { makeAutoObservable } from 'mobx'
interface UserInfo {
  phone?: string
  name: string
}

class Session {
  authToken = ''
  openId = ''
  authUrl = '/'
  userInfo: UserInfo = {
    name: 'testName'
  }

  constructor() {
    makeAutoObservable(this)
  }

  setUserName(name: string) {
    this.userInfo.name = name
  }

  setAuthToken(token: string) {
    this.authToken = token
  }

  setAuthUrl(url: string) {
    this.authUrl = url
  }
}

export default new Session()
