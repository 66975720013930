import { get } from 'lodash-es'
import { makeAutoObservable, runInAction } from 'mobx'
import { apis } from '@/common/http-client'
import { RESPONSE_CODE } from '@/config'
import { Toast } from '@/components/Toast'
import seal1 from '@/assets/member-task-v2/lottery-results/seal-1.png'
import seal2 from '@/assets/member-task-v2/lottery-results/seal-2.png'
import { navigateToByNative } from '@/common/jsBridge/nativeApp'
import {
  mockActivityDetail,
  mockExploreDetail,
  mockExploreValue,
  mockTaskList
} from '@/pages/memberTaskV2/mock'

const { VITE_DOMAIN } = (import.meta as any).env

const HAD_MEMBER_TASK_GUIDE = 'hadMemberTaskGuide'
const HAVE_MEMBER_TASK_AGREEMENT = 'haveMemberTaskAgreement'
export const MEMBER_TASK_DO_TASK_INFO = 'memberTaskDoTaskInfo'
export const IS_UPDATE_EXPLORE_VALUE = 'isUpdateExploreValue'
const hadMemberTaskGuide = localStorage.getItem(HAD_MEMBER_TASK_GUIDE) ?? ''
const haveMemberTaskAgreement = localStorage.getItem(HAVE_MEMBER_TASK_AGREEMENT) ?? ''
const memberTaskDoTaskInfo: string = localStorage.getItem(MEMBER_TASK_DO_TASK_INFO) ?? '{}'

let doTaskIds: string[] = []
let exploreCoins = '0'
let taskListIds: string[] = []
let localStorageActivityId = ''
try {
  doTaskIds = JSON.parse(memberTaskDoTaskInfo)?.doTaskIds || []
  exploreCoins = JSON.parse(memberTaskDoTaskInfo)?.exploreCoins || '0'
  taskListIds = JSON.parse(memberTaskDoTaskInfo)?.taskListIds || []
  localStorageActivityId = JSON.parse(memberTaskDoTaskInfo)?.activityId || ''
} catch (error) {}

// 会员状态
export enum MemTypeEnum {
  '普通会籍' = 1,
  '卓越会籍' = 2,
  '未开通会员' = 90,
  '会员已过期' = 91,
  '体验卡' = 92,
  '卡冻结' = 93
}

// 会员卡状态
export enum CardTypeEnum {
  '主卡' = 1,
  '亲友免费卡' = 2,
  '副卡' = 3,
  '公司卡' = 4,
  '企业副卡配偶' = 5,
  '员工卡' = 6,
  '商业主卡' = 7
}

// 活动状态
export enum ActivityStatusEnum {
  '待发布' = 1,
  '未开始' = 2,
  '进行中' = 3,
  '已结束' = 4,
  '已过期' = 5
}

// 活动中奖状态
export enum ActivityRewardStatusEnum {
  '未抽奖' = 0,
  '未中奖' = 1,
  '已中奖' = 2,
  '已失效' = 3
}

export enum TaskStatusEnum {
  '未领取' = 1,
  '进行中' = 2,
  '已完成' = 3
}

export enum PageStatus {
  DEFAULT = 'default',
  LOTTERY_BEGIN = 'lottery-begin',
  LOTTERY_RESULT = 'lottery-result',
  ACTIVITY_END = 'activity-end',
  ACTIVITY_RESULT = 'activity-result'
}

export const lotteryBtnText: Record<number, string> = {
  [ActivityRewardStatusEnum.未抽奖]: '立即抽奖',
  [ActivityRewardStatusEnum.未中奖]: '未中奖，下期更精彩'
}

export const unJoinPopupTipsInfo = {
  [MemTypeEnum.未开通会员]: {
    popupTipsText: '开通会员后可参与活动',
    leftBtnText: '先看看',
    rightBtnText: '去开通',
    jumpLink: `sams://buyMembership`,
    leftTrack: {
      elementId: 'nonmember_continue_browsing',
      eventName: 'sam_h5_memberTask_nonmember_continue_browsing_tap'
    },
    rightTrack: {
      elementId: 'click_go_open',
      eventName: 'sam_h5_memberTask_click_go_open_tap'
    }
  },
  [MemTypeEnum.会员已过期]: {
    popupTipsText: '您的会籍已过期，续费后可参与活动动',
    leftBtnText: '先看看',
    rightBtnText: '去续费',
    jumpLink: `${VITE_DOMAIN}/sams/renewV2`,
    leftTrack: {
      elementId: 'member_continue_browsing',
      eventName: 'sam_h5_memberTask_member_continue_browsing_tap'
    },
    rightTrack: {
      elementId: 'click_go_renew',
      eventName: 'sam_h5_memberTask_click_go_renew_tap'
    }
  },
  [MemTypeEnum.体验卡]: {
    popupTipsText: '开通会员后可参与活动',
    leftBtnText: '先看看',
    rightBtnText: '去开通',
    jumpLink: `sams://buyMembership`,
    leftTrack: {
      elementId: 'nonmember_continue_browsing',
      eventName: 'sam_h5_memberTask_nonmember_continue_browsing_tap'
    },
    rightTrack: {
      elementId: 'click_go_open',
      eventName: 'sam_h5_memberTask_click_go_open_tap'
    }
  },
  [MemTypeEnum.卡冻结]: {
    popupTipsText: '暂无法参与活动',
    leftBtnText: '知道了',
    rightBtnText: '',
    jumpLink: ''
  }
}

export const cardUnJoinPopupTipsInfo = {
  [CardTypeEnum.公司卡]: {
    popupTipsText: '工会会员与商业会员暂无法参与活动',
    leftBtnText: '我知道了',
    rightBtnText: '',
    jumpLink: ''
  },
  [CardTypeEnum.商业主卡]: {
    popupTipsText: '工会会员与商业会员暂无法参与活动',
    leftBtnText: '我知道了',
    rightBtnText: '',
    jumpLink: ''
  }
}

// 0未开始 1进行中 2已结束
// (已兑奖后 1开始3位数 代表用户券状态) 101-未使用，102-已锁定，103-已使用，104-已过期，105-已作废
export const LotteryResultBtnText: Record<number, any> = {
  101: {
    text: '立即使用',
    sealImg: ''
  },
  102: {
    text: '已使用',
    sealImg: seal2
  },
  103: {
    text: '已使用',
    sealImg: seal2
  },
  104: {
    text: '已失效',
    sealImg: seal1
  },
  105: {
    text: '已失效',
    sealImg: seal1
  }
}

export const activityStatusText: Record<number, string> = {
  [ActivityStatusEnum.未开始]: '活动未开始',
  [ActivityStatusEnum.已结束]: '活动已结束'
}

class MemberTaskStore {
  baseUrl = 'https://sam-retail-static-1302115363.file.myqcloud.com/lottie/memberTask/' // 静态资源cos桶地址
  containerId = 'member-task-v2' // 容器id
  guideStep = 0 // 引导步骤
  isLottiePopupPlay = false // 抽奖弹窗动画播放
  exploreCoins = exploreCoins // 总探索值
  isExchangeListExpand = false // 是否展开兑换列表
  isShowGuideAnimation = false // 是否显示引导动画
  activityId = '' // 活动id
  activityName = '' // 活动名称
  memberType = 1 // 会员状态  1-普卡 2-卓越 90-未开通会员 91-已过期 92-体验卡 93-冻结
  cardType = 1 // 会员卡状态  1-主卡 2-亲友免费卡 3-副卡 4-公司卡 5-企业副卡配偶 6-员工卡 7-商业主卡
  activityRewardStatus = 0 // 活动中奖状态  0-未抽奖 1-未中奖 2-已中奖
  activityStatus = 0 // 活动状态 0-默认 1-待发布 2-未开始 3-进行中 4-已结束 5-已过期
  endTime = 0 // 活动结束时间戳
  startTime = 0 // 活动开始时间戳
  pageInfo: any = undefined // 页面配置信息
  rewardList: any = [] // 活动奖品列表
  winRewardList: any = [] // 中奖奖品列表
  exchangeRewardList: any = [] // 活动主页兑换奖品列表
  exploreValue = 0 // 总探索值
  exploreDetailList: any = [] // 探索值明细列表
  isHaveExploreDetailList = 0 // 是否有探索值明细列表
  popupInfo: any = undefined // 拦截弹窗信息
  isShowInterface = false // 是否显示拦截弹窗
  winPriceInfo: any = {} // 中奖弹窗信息
  exploreValueInCurrentActivity = '0' // 本期所获探索值
  lotteryResult = 0 // 抽奖结果 0未中奖，1已中奖
  taskList: any = [] // 任务列表 - 注意返回的数据要生成id
  pageStatus: PageStatus = PageStatus.DEFAULT // 页面状态
  translateValues: number[] = [] // 任务列表位移值
  remainSecond = '' // 倒计时秒数
  isError = false
  timer: any = null
  shouldPoll = true
  failureCount = 0
  completeTaskIds: number[] = [] // 完成任务id
  isWinLottery = false // 是否中奖
  previousTaskIds: number[] = []
  userCouponInfo: any = {} // 抽中奖品信息
  isShowNetworkTips = false // 是否显示网络提示
  agreementVisibleModal = !haveMemberTaskAgreement // 同意弹窗
  descId = '177' // 活动规则Id
  confirmPushModalVisible = false // 确认push通知是否开启
  isLoading = true
  imagesQueue: Record<'images' | 'priority', any>[] = [] // 图片队列
  loadedImages = new Set()

  constructor() {
    makeAutoObservable(this)
  }

  /**
   * 将图片数组添加到图像队列中，并指定优先级。
   * @param images - 要添加到队列中的图片URL数组。
   * @param priority - 图片的优先级，数字越小优先级越高。
   */
  addToImagesQueue(images: string[], priority: number) {
    this.imagesQueue.push({ images, priority })
    this.imagesQueue.sort((a, b) => a.priority - b.priority)
  }

  // 最后一组图片预加载后调用
  async preloadImages() {
    for (const { images } of this.imagesQueue) {
      for (const src of images) {
        if (!this.loadedImages.has(src)) {
          await this.loadImage(src)
          this.loadedImages.add(src)
        }
      }
    }
  }

  loadImage(src: string) {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.src = src
      img.onload = resolve
      img.onerror = reject
    })
  }

  startPolling({
    requestFn,
    interval = 1000,
    maxFailures = 3,
    stopConditionFn
  }: {
    requestFn: () => Promise<any>
    interval?: number
    maxFailures?: number
    stopConditionFn?: () => boolean
  }) {
    this.shouldPoll = true
    Toast.loading('')
    const poll = async () => {
      if (!this.shouldPoll) return

      try {
        await requestFn()
      } finally {
        this.failureCount += 1
      }

      if (this.failureCount >= maxFailures) {
        this.stopPolling()
        return
      }

      if (stopConditionFn?.()) {
        this.stopPolling()
        return
      }

      if (this.shouldPoll) {
        this.timer = setTimeout(poll, interval)
      }
    }

    poll()
  }

  stopPolling() {
    Toast.hide()
    this.shouldPoll = false
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
    this.failureCount = 0
  }

  updateIsLottiePopupPlay(play: boolean) {
    this.isLottiePopupPlay = play
  }

  updateInitGuideStep() {
    if (
      this.activityRewardStatus === ActivityRewardStatusEnum.未抽奖 &&
      this.taskList.length > 0 &&
      !hadMemberTaskGuide
    ) {
      this.guideStep = 1
    }
  }

  // 指引步骤
  updateGuideStep(step: number) {
    if (step === 3) {
      this.guideScrollInView()
    }
    this.guideStep = step
  }

  // 更新展开兑换列表
  updateIsExchangeListExpand(expand: boolean) {
    this.isExchangeListExpand = expand
  }
  // 是否展示指引动画
  updateIsShowGuideAnimation(isShow: boolean) {
    this.isShowGuideAnimation = isShow
  }

  // 拦截弹窗是否展示
  updateIsShowInterface(isShow: boolean) {
    this.isShowInterface = isShow
  }

  // 只要展示一次指引动画
  saveMemberTaskGuide() {
    localStorage.setItem(HAD_MEMBER_TASK_GUIDE, 'true')
  }

  // 只要展示一次指引动画
  saveHaveAgreement() {
    localStorage.setItem(HAVE_MEMBER_TASK_AGREEMENT, 'true')
  }

  // 弹窗信息
  updatePopupTipsInfo() {
    if ([CardTypeEnum.公司卡, CardTypeEnum.商业主卡].includes(this.cardType)) {
      this.popupInfo = get(cardUnJoinPopupTipsInfo, `${this.cardType}`)
    } else {
      this.popupInfo = get(unJoinPopupTipsInfo, `${this.memberType}`)
    }
  }

  updateTaskList(list: any[]) {
    this.taskList = list
  }

  getTaskListLength() {
    return this.taskList?.length || 0
  }

  updateIsShowNetworkTips(isShow: boolean) {
    this.isShowNetworkTips = isShow
  }
  updateCompose() {
    if (this.isError) {
      this.pageStatus = PageStatus.ACTIVITY_END
      return
    }
    this.pageStatus = get(
      {
        [ActivityStatusEnum.进行中]: {
          [ActivityRewardStatusEnum.未抽奖]: PageStatus.LOTTERY_BEGIN,
          [ActivityRewardStatusEnum.未中奖]: PageStatus.LOTTERY_BEGIN,
          [ActivityRewardStatusEnum.已中奖]: PageStatus.LOTTERY_RESULT
        },
        [ActivityStatusEnum.已结束]: {
          [ActivityRewardStatusEnum.已失效]: {}
        },
        [ActivityStatusEnum.未开始]: {
          [ActivityRewardStatusEnum.已失效]: {}
        }
      },
      `${this.activityStatus}.${this.activityRewardStatus}`
    )
    if (typeof this.pageStatus === 'object') {
      this.pageStatus = this.isHaveExploreDetailList
        ? PageStatus.ACTIVITY_RESULT
        : PageStatus.ACTIVITY_END
    }
  }

  setAgreementVisibleModal(flag: boolean) {
    this.agreementVisibleModal = flag
  }

  setdescId(id: string) {
    this.descId = id
  }

  setPushModalVisible(visible: boolean) {
    this.confirmPushModalVisible = visible
  }
  // 跳转兑换中心
  toRedemptionCenter = (isPreview?: boolean) => {
    navigateToByNative(
      `${import.meta.env.VITE_DOMAIN}/sams/redemption-center?activityId=${this.activityId}&descId=${
        this.descId
      }${isPreview ? '&isPreview=true' : ''}`
    )
  }
  // 探索手册-活动详情
  async updateActivityDetail(id: string, isPreview?: string) {
    try {
      const { code, data, msg } = await apis.memberTaskV2.getActivityDetail({
        activityId: id,
        ...(isPreview === 'true' ? { viewType: 'preView' } : undefined)
      })
      if (code === RESPONSE_CODE.SUCCESS) {
        // const data: any = mockActivityDetail
        const {
          activityId,
          activityName,
          cardType,
          memberType,
          activityRewardStatus = 0,
          activityStatus = 0,
          endTime,
          startTime,
          pageInfo,
          nonLottery = {}, // activityRewardStatus = 0（未抽奖）时不为null
          loseLottery = {}, // activityRewardStatus = 1（未中奖）时不为null
          winLottery = {}, // activityRewardStatus = 2（已中奖）时不为null
          remainSecond = '',
          descId = ''
        } = data || {}

        runInAction(() => {
          this.activityId = activityId
          this.activityName = activityName
          this.cardType = cardType
          this.memberType = memberType
          this.activityRewardStatus =
            typeof activityRewardStatus === 'number' ? activityRewardStatus : 3
          this.activityStatus = activityStatus
          this.endTime = endTime
          this.startTime = startTime
          this.remainSecond = remainSecond
          this.pageInfo = pageInfo
          this.exchangeRewardList = loseLottery?.exchangeRewardList || []
          this.descId = descId

          switch (activityRewardStatus) {
            case 0:
              this.rewardList = nonLottery?.rewardList
              break
            case 1:
              this.rewardList = loseLottery?.rewardList
              break
            case 2:
              this.winRewardList = winLottery?.winRewardList
              break
          }
        })
      } else {
        runInAction(() => {
          this.isError = true
        })

        Toast.text(msg)
      }
    } catch (e) {
      runInAction(() => {
        this.isError = true
      })
      console.log(e)
    }
  }
  // 探索手册-任务列表
  async updateTaskInfoList(id: string, init = false, isPreview?: string) {
    try {
      const { code, data = [], msg } = await apis.memberTaskV2.taskList({
        activityId: id,
        ...(isPreview === 'true' ? { viewType: 'preView' } : undefined)
      })
      if (code === RESPONSE_CODE.SUCCESS) {
        // const data: any = mockTaskList
        runInAction(() => {
          const newData = data.map((item: any, index: number) => ({ ...item, id: index + 1 }))
          if (init) {
            // 缓存的任务ids
            const unfinishedTaskList = newData.filter(
              (item: { finished: TaskStatusEnum }) => item.finished !== TaskStatusEnum.已完成
            )
            const completedTaskIds = newData
              .map((task: { taskId: string; finished: TaskStatusEnum }, index: number) =>
                doTaskIds.includes(task.taskId) && task.finished === TaskStatusEnum.已完成
                  ? index + 1
                  : null
              )
              .filter((index: number) => index !== null)

            const stashTaskList =
              taskListIds.length > 0
                ? newData.filter((task: { taskId: string }) => taskListIds.includes(task.taskId))
                : newData

            if (localStorageActivityId !== id) localStorage.removeItem(MEMBER_TASK_DO_TASK_INFO)

            this.taskList = completedTaskIds?.length > 0 ? stashTaskList : unfinishedTaskList
            this.previousTaskIds = unfinishedTaskList.map((item: { id: number }) => item.id)

            if (completedTaskIds?.length > 0) {
              const outerTimer = setTimeout(() => {
                this.scrollToBottom()

                const timer = setTimeout(() => {
                  this.completeTaskIds = completedTaskIds
                  clearTimeout(outerTimer)
                  clearTimeout(timer)
                }, 500)
              }, 100)
            }
          } else {
            if (!this.previousTaskIds?.length) return
            if (!newData.length) {
              localStorage.removeItem(MEMBER_TASK_DO_TASK_INFO)
              this.updateActivityDetail(id)
              this.taskList = []
              return
            }
            const timer = setTimeout(() => {
              this.taskList = newData.filter((item: { id: number }) =>
                this.previousTaskIds.includes(item.id)
              )
              clearTimeout(timer)
            }, 0)

            // 随机返回，测试用
            // const randomSelect = (arr: any[]) => arr.sort(() => 0.5 - Math.random()).slice(0, 1)
            // this.completeTaskIds = randomSelect(
            //   this.taskList?.map((item: { id: number }) => item?.id) || []
            // )
            this.completeTaskIds =
              this.taskList
                .filter((item: any) => item.finished === TaskStatusEnum.已完成)
                ?.map((item: { id: number }) => item?.id) || []
          }
        })
      } else {
        console.log(msg)
      }
    } catch (e) {
      Toast.text('网络异常，请尝试刷新')
      console.log(e)
    }
  }

  // 探索手册-领取任务
  async updateLockTask(id: string) {
    try {
      const { code, data, msg } = await apis.memberTaskV2.lockTask({
        activityId: this.activityId,
        taskId: id
      })
      if (code === RESPONSE_CODE.SUCCESS) {
        const { lockResult } = data || {}
        if (lockResult === 1) {
          return true
        } else {
          return false
        }
      } else {
        Toast.text(msg || '领取失败')
        return false
      }
    } catch (e) {
      Toast.text('网络异常，请尝试刷新')
    }
  }

  // 探索手册-探索值明细
  async updateExploreValueList(param: any) {
    try {
      const { code, data, msg } = await apis.memberTaskV2.queryDetail(param)
      if (code === RESPONSE_CODE.SUCCESS) {
        // const data: any = mockExploreDetail
        const { explorationValueDetailVos = [] } = data || {}
        const isHaveExploreDetailList = Number(explorationValueDetailVos?.length > 0)
        runInAction(() => {
          this.exploreDetailList = explorationValueDetailVos
          this.isHaveExploreDetailList = isHaveExploreDetailList
        })
      } else {
        console.log(msg)
      }
    } catch (e) {
      console.log(e)
    }
  }

  // 探索值
  async updateExploreCoins() {
    try {
      const { code, data, msg } = await apis.memberTaskV2.getExplore({})
      if (code === RESPONSE_CODE.SUCCESS && data) {
        // const data: any = mockExploreValue
        const { explorationValue } = data || {}
        runInAction(() => {
          this.exploreCoins = explorationValue
        })
      } else {
        console.log('获取探索值失败', msg)
      }
    } catch (e) {
      console.log(e)
    }
  }

  // 抽奖
  async updateLottery(id: string) {
    Toast.loading(' ')
    try {
      const { code, data, msg } = await apis.memberTaskV2.lottery({ activityId: id })
      Toast.hide()
      if (code === RESPONSE_CODE.SUCCESS) {
        const {
          imgUrl = '',
          rewardName = '',
          rewardPrice = 0,
          exploreValueInCurrentActivity = '0',
          lotteryResult,
          userCouponInfo
        } = data || {}
        runInAction(() => {
          this.exploreValueInCurrentActivity = exploreValueInCurrentActivity
          this.winPriceInfo = {
            imgUrl: imgUrl,
            rewardName: rewardName,
            rewardPrice: rewardPrice
          }
          this.lotteryResult = lotteryResult
          this.isLottiePopupPlay = true
          this.isWinLottery = !!lotteryResult
          this.userCouponInfo = userCouponInfo
          // this.isShowNetworkTips = false
        })
      } else if (code === 'SENDING_REWARDS') {
        runInAction(() => {
          this.isLottiePopupPlay = false
          this.isShowNetworkTips = true
        })
      } else {
        Toast.text(msg)
        runInAction(() => {
          this.isLottiePopupPlay = false
          // this.isShowNetworkTips = false
        })
        console.log(msg)
      }
    } catch (e) {
      console.log(e)
      Toast.text('网络异常，请尝试刷新')
      // this.isShowNetworkTips = false
    }
  }

  // 更新任务列表的item位移值
  updateTranslateValues(values: number[]) {
    this.translateValues = values
  }

  updateIsLoading(isLoading: boolean) {
    this.isLoading = isLoading
  }

  // 是否获取到完成的任务id
  isUpdateCompleteTaskIds() {
    return this.completeTaskIds.length > 0
  }

  updateCompleteTaskIds(completeTaskIds: number[]) {
    this.completeTaskIds = completeTaskIds
  }

  updatePreviousTaskIds(ids: number[]) {
    this.previousTaskIds = ids
  }

  scrollToBottom() {
    const bottomEndEle = document.getElementById('member-task-list')
    bottomEndEle?.scrollIntoView({ behavior: 'smooth' })
  }

  private guideScrollInView() {
    const height = window.innerHeight
    if (height < 820) {
      window.scrollTo(0, 150)
    }
  }
}

export default new MemberTaskStore()
