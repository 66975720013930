// 线下活动
import { makeAutoObservable } from 'mobx'
import { apis } from '@/common/http-client'
import { RESPONSE_CODE } from '@/config'
import { Toast } from '@/components/Toast'
import { CardType } from '@/config/index'

import { concat } from 'lodash-es'
import dayjs from 'dayjs'
import { getAppDataByBridge, getDeliveryAddressLocation } from '@/common/jsBridge/jsBridgeApi'
import { ElementType } from '@/common/mall-tracking/types'
import { element } from '@/common/mall-tracking'

export enum ERRORS_TYPE_ENUM {
  /** 没有定位权限，定位为空 */
  NO_ADDRESS = 'no_address',
  /** 没有活动 */
  NO_ACTIVITY = 'no_activity',
  /** 我的报名列表没有活动 */
  NO_MY_ACTIVITY = 'no_my_activity',
  /** 系统异常 */
  SYSTEM_ERROR = 'system_error',
  /** 网络异常  */
  NETWORK_ERROR = 'network_error'
}

/** 最近查询活动提醒的日期 例：20230308 */
const REMIND_COOKIE_KEY = 'offline_remind_query_day'

/** 待评价活动弹窗显示日期 */
export const COMMENT_KEY = '__activity_comment_key'

export const Activity_Tag: Record<number, string> = {
  1: '亲子体验',
  2: '互动体验',
  3: '健康中心',
  4: '山姆厨房',
  5: '讲座课堂',
  6: '限时路演',
  7: '门店试吃',
  8: '会员服务',
  9: '山姆会员动态',
  10: '探索山姆',
  11: '会员福利',
  12: '精彩推荐',
  13: '直播活动'
}
/** 我的报名 0待参与  1已结束 */
export enum MY_LIST_TYPE {
  /** 待参与 */
  PENDING = 0,
  /** 已结束 */
  END = 1
}
/** 报名是否成功  0 成功 1 有爽约记录 失败 */
enum ResponseType {
  /** 成功 */
  YES = 0,
  /** 失败 */
  NO = 1
}
export enum SIGN_ERROR_TYPE {
  /** 签到成功  */
  SUCCESS = 0,
  /** 活动未开始  */
  NO_BEGIN = 1,
  /** 活动已结束  */
  IS_END = 2,
  /** 活动未报名  */
  NO_APPLY = 3,
  /** 会籍失效  */
  IS_EXPIRED = 4,
  /** 会员已降级  */
  IS_LOWER = 5,
  /** 已解绑  */
  IS_UNBIND = 6,
  /** 重复签到  */
  REPEAT = 7
}

export enum MEMBER_STATUS {
  /** 不是山姆会员  */
  NO_MEMBER = 0,
  /** 体验卡用户 */
  IS_EXPERIENCECARD = 1,
  /** 普通会员  */
  IS_VIP = 2,
  /** 卓越会员  */
  IS_SVIP = 3
}
export enum CORWD_TYPE {
  /** 所有用户  */
  ALL = 0,
  /** 卓越会员 */
  SVIP = 1
}

export enum ACTIVITY_STATUS {
  /** 无需报名 */
  NO_APPLY = 0,
  /** 报名未开始 */
  NO_BEGIN = 1,
  /** 可报名 */
  CAN_APPLY = 2,
  /** 已报名 */
  IS_APPLY = 3,
  /** 已签到 */
  IS_SIGN = 4,
  /** 已满员 */
  IS_FULL = 5,
  /** 已过期 */
  IS_EXPIRED = 6,
  /** 报名已结束 */
  APPLY_END = 7,
  /** 活动已结束 */
  ACTIVITY_END = 8,
  /** 活动已取消 */
  ACTIVITY_CANCEL = 9,
  /** 活动未开始 */
  NO_STRAT = 10,
  /** 活动进行中 */
  ACTIVITY_ING = 11,
  /** 已爽约 */
  BREAK_THE_PROMISE = 12,
  /** 异常 */
  ERRPR_STATE = 99
}

/** 活动整体状态 */
export enum STATUS {
  /** 默认 */
  DEFAULT = 0,
  /** 待发布 */
  TO_RELEASE = 1,
  /** 待预约 */
  PENDDING = 2,
  /** 预约中 */
  APPOINTMENT = 3,
  /** 未开始 */
  NO_BEGIN = 4,
  /** 进行中 */
  IN_PROGRESS = 5,
  /** 已结束 */
  IS_END = 6,
  /** 删除 */
  IS_DELETE = 7
}
/** 活动类型：0-无需报名活动，1-可报名活动，2-线上直播活动 */
export enum ACTIVITY_TYPE {
  /** 无需报名活动 */
  NO_APPLY = 0,
  /** 可报名活动 */
  CAN_APPLY = 1,
  /** 线上直播活动 */
  ACTIVITY_ONLINE = 2
}

export const ACTIVITY_STATUS_MAPS: Record<any, any> = {
  [ACTIVITY_STATUS.NO_APPLY]: '无需报名',
  [ACTIVITY_STATUS.NO_BEGIN]: '报名未开始',
  [ACTIVITY_STATUS.CAN_APPLY]: '需报名',
  [ACTIVITY_STATUS.IS_APPLY]: '已报名',
  [ACTIVITY_STATUS.IS_SIGN]: '已签到',
  [ACTIVITY_STATUS.IS_FULL]: '已满员',
  [ACTIVITY_STATUS.IS_EXPIRED]: '已过期',
  [ACTIVITY_STATUS.APPLY_END]: '报名已结束',
  [ACTIVITY_STATUS.ACTIVITY_END]: '活动已结束',
  [ACTIVITY_STATUS.ACTIVITY_CANCEL]: '活动已取消',
  [ACTIVITY_STATUS.NO_STRAT]: '未开始',
  [ACTIVITY_STATUS.ACTIVITY_ING]: '进行中',
  [ACTIVITY_STATUS.BREAK_THE_PROMISE]: '已爽约',
  [ACTIVITY_STATUS.ERRPR_STATE]: ''
}

export enum MEMBER_EXPIRED {
  /**   有效期内 */
  EFFECTIVE = 1,
  /**   已过期 */
  EXPIRED = 2
}

export enum LIST_SIGNUP_STATUS {
  //  未报名
  NO_APPLY = 0,
  /** 已报名当前场次  */
  IS_APPLY = 1,
  /** 已报名其他场次 */
  IS_APPLY_OTHER = 2
}

export const LIST_SIGNUP_STATUS_MAPS: Record<any, any> = {
  [LIST_SIGNUP_STATUS.NO_APPLY]: '未报名',
  [LIST_SIGNUP_STATUS.IS_APPLY]: '已报名',
  [LIST_SIGNUP_STATUS.IS_APPLY_OTHER]: '已报名其他场次'
}

export enum SIGNUP_STATUS {
  /** 无需报名 */
  NO_APPLY = 0,
  /** 可报名 */
  CAN_APPLY = 1,
  /** 已报名 */
  IS_APPLY = 2,
  /** 分享详情可报名状态  */
  SHARE_CAN = 3
}

export enum PAGE_NAME {
  LIST = 'list',
  MYLIST = 'myList',
  DETAIL = 'detail',
  SIGN = 'sign',
  SHARE = 'share'
}
interface CommentActivityInfo {
  images?: string
  activityId?: string
  activityTitle?: string
  activityStartTime?: number | string
  activityEndTime?: string | number
}

export interface ActivityList {
  /** 日历时间 */
  calendarTime: number
  /** 背景图片 */
  backgroundImage: string
  /** 活动标签 */
  activityTag: number
  /** 开始时间 */
  activityStartTime: number
  /** 结束时间 */
  activityEndTime: number
  /** 活动标题 */
  activityTitle: string
  /** 活动状态 */
  activityStatus: ACTIVITY_STATUS
  /** 活动地址 */
  storeName: string
  /** 0 所有人群，1卓越会籍 */
  crowd: CORWD_TYPE
  /** 门店id */
  storeId: number
  /** 活动id */
  activityId: string
  /** 活动模板id */
  templateId: string
  /** 用户报名状态  */
  signUpStatus: LIST_SIGNUP_STATUS
  /** 是否展示门店信息,0:隐藏,1:展示 */
  displayStoreInfo: number
  /** 活动类型：0-无需报名活动，1-可报名活动，2-线上直播活动 */
  activityType: number
}
interface DetailData {
  storeName: string
  storeAddress: string
  storePhone: string | number
  latitude: string | number // 经度
  longitude: string | number // 纬度
  images: string[]
  activityName: string
  activityTag: number
  activityStartTime: string | number // 活动开始时间
  activityEndTime: string | number // 活动结束时间
  activityTitle: string // 活动标签
  activityStatus: ACTIVITY_STATUS // 0-无需报名、1-报名未开始、2-可报名、3-已报名、4-已签到、5-已满员、6-已过期、7-报名已结束、8-活动已结束、9-活动已取消、10-未开始,11-进行中
  crowd: CORWD_TYPE // 0-所有人群，1-卓越会籍
  memberStatus: MEMBER_STATUS // 0-非会员，1-体验卡，2-普通会员，3-卓越会员
  memberExpired: MEMBER_EXPIRED // 1: 有效期内，2：即将过期，3：已过期，4：已失效，5：已冻结
  activityDetails: string
  activityNotice: string
  issue: string
  signUpDate: any[]
  signUpStatus: SIGNUP_STATUS // 0-无需报名，1-未报名，2-已报名
  signUpChildQuantity: number // 小孩报名数量
  signUpAdultQuantity: number // 大人报名数量
  signUpActivityStatTime: string | number // 报名的活动开始时间
  signUpActivityEndTime: string | number // 报名的活动结束时间
  activityId?: string
  status: STATUS
  currentTheme?: string // 当前主题
  signLocation?: string // 签名地点
  activityType?: number //	活动类型：0-无需报名活动，1-可报名活动，2-线上直播活动
  activityDetailUrl?: string // 活动详情图
  buttonText?: string // 按钮文字,activityType为2时展示
  onlineActivityAddress?: string // 跳转视频号线上活动地址,activityType为2时展示
  displayStoreInfo?: number // 是否展示门店信息,0:隐藏,1:展示
  cardType?: CardType
  breakPromiseText?: string // 爽约文案
}

interface TimeItemData {
  activityId: string // 子活动id
  signUpStartTime: number // 报名开始时间
  signUpEndTime: number // 报名结束时间
  startTime: number // 活动开始时间
  endTime: number // 活动结束时间
  adultGrantQuantity: number // 大人每次报名数量
  childGrantQuantity: number // 小孩每次报名数量
  adultLimitQuantity: number // 大人总报名数量
  childLimitQuantity: number // 小孩总报名数量
  childRemainQuantity: number // 小孩剩余报名数量
  adultRemainQuantity: number // 大人剩余报名数量
  adultLimitLeastQuantity: string // 大人最少报名人数
  childLimitLeastQuantity: string // 小孩最少报名人数
  currentTheme: string // 本期主题
  signLocation: string // 签到地点
  activityStatus: ACTIVITY_STATUS
}

interface SignParamsType {
  templateId?: string | number // 模板id，
  activityId: string | number // 活动id
  type: 0 | 1 // 0-报名，1-取消报名
  childQuantity?: number
  adultQuantity?: number
}

/** 活动提醒 */
interface ActivityRemind {
  /** 活动数量,等于0则不透出弹窗 */
  activityNum: number
  /** 活动名称 */
  activityName?: string
  /** 门店名称 */
  storeName?: string
  /** 活动开始时间 */
  activityStartTime?: number
  /** 活动结束时间 */
  activityEndTime?: number
  /** 活动id */
  activityId?: string
}

class OfflineStore {
  /** 活动详情 */
  detail: DetailData | Record<any, any> = {}
  /** 详情页loading */
  detailLoading = true
  /** 预约时间列表数据 */
  dateTimeList: TimeItemData[] = []
  /** 签到信息 */
  signData: Record<any, any> = {}
  /** 是否重复签到  */
  isRepeatSign = false
  /* 成年人报名数量 默认为1  */
  personNum = 1
  /* 未成年人 默认为0  */
  childNum = 0
  /** 当前选择的时间段报名开始时间 */
  currentSignUpStartTime: number | undefined = 0
  /** 预约时间选择模板下标  */
  currentSignIndex = 0
  /** 当前活动详情选择时间段状态 详情卡做展示 */
  currentActivityStatus: ACTIVITY_STATUS | undefined = undefined
  /** 路由获取的参数  */
  urlData: any = {}
  /** 当前选择的日期下标 */
  currentDateIndex: number | undefined = 0
  /** 报名人数弹窗可见 */
  applyVisible = false
  /** 报名提示弹窗是否可见 */
  applyHintVisible = false
  /** 山姆会员专享弹窗是否可见  */
  isVipVisible = false
  /** 页面错误类型集合 */
  errorMaps: Record<PAGE_NAME, ERRORS_TYPE_ENUM | ''> = {
    /** 活动列表 */
    list: '',
    /** 我的报名列表 */
    myList: '',
    /** 活动详情 */
    detail: '',
    /** 签到页 */
    sign: '',
    /** 分享页  */
    share: ''
  }
  /** 活动列表 */
  list: ActivityList[] = []
  /** 活动列表页码 */
  listPage = 1
  /** 活动列表是否已经加载完成 */
  activityListIsEnd = false
  lock = false
  /** 当前城市名称 */
  cityName = ''
  /** 门店列表 */
  storeIdList: [] | string[] = []
  /** 活动通知弹窗是否可见 */
  notePopupVisible = false
  /** 我的报名 tab选择 待参与0 已结束 1 */
  myListActiveTab = MY_LIST_TYPE.PENDING
  /** 待参与活动列表 */
  myPendingList: ActivityList[] = []
  /**  已结束活动列表 */
  myEndList: ActivityList[] = []
  /** 待参与活动列表是否加载完毕 */
  myPendingListEnd = false
  /** 已结束活动列表是否加载完毕 */
  myEndListEnd = false
  /** 待参与列表页码 */
  pendingListPage = 1
  /** 已结束列表页码 */
  endListPage = 1
  /** 活动提醒 */
  remind: ActivityRemind = {
    activityNum: 0
  }
  /** 人数选择提示是否可见 */
  personToastVisible = false
  /** 人数选择提示文字  */
  personToastText = ''
  /** 是否确认要提交  */
  isSureSubmit = false
  /** 是否从提交按钮触发选择人数 */
  isSubmitToPerson = false
  /** 保存loading  */
  signLoading = false
  /** 状态栏高度 */
  statusBarHeight = 0
  /** 爽约记录弹窗是否可见 */
  noteTipsVisible = false
  /** 有爽约记录后点击报名的提示文案 */
  noteTipsText = ''
  /** 提示文案类型 */
  noteType = ''

  /** 已选择的评价词id */
  commentSelected: string[] = []

  /** 评价步骤 */
  commentStep = 1

  /** 评价词列表 */
  activityCommentList: any[] = []

  /** 待评价的活动详情 */
  commentActivityInfo: CommentActivityInfo = {}

  commentPopupVisible = false

  constructor() {
    makeAutoObservable(this)
  }

  /** 是否从提交按钮触发选择人数 */
  updateSsSubmitToPerson(visible: boolean) {
    this.isSubmitToPerson = visible
  }

  /** 是否确认直接提交 */
  updateIsSureSubmit(visible: boolean) {
    this.isSureSubmit = visible
  }

  /** 人数选择提示是否可见更新 */
  updatePersonToastVisible(visible: boolean) {
    this.personToastVisible = visible
  }
  /** 人数选择提示文字更新 */
  updatePersonToastText(text: string) {
    this.personToastText = text
  }

  /** 报名人数弹窗显示隐藏 */
  updateApplyVisible(visible: boolean) {
    this.applyVisible = visible
  }

  /** 报名确认弹窗显示隐藏 */
  updateApplyHintVisible(visible: boolean) {
    this.applyHintVisible = visible
  }
  /** 山姆会员专享弹窗显示隐藏 */
  updateIsVipVisible(visible: boolean) {
    this.isVipVisible = visible
  }

  saveUrlData(param: any) {
    this.urlData = param
  }

  /** 选择日期 */
  handleChoseDate(index: number) {
    this.currentSignIndex = index
    this.currentSignUpStartTime = this.detail?.signUpDate[index]
  }

  /** 详情页获取报名时间
   *  @param signUpTime?: number
   *  @param activityId?: number
   */
  async getTimeList(signUpTime?: string | number, activityId?: string) {
    const {
      urlData: { templateId, storeId }
    } = this
    const commonParams = {
      templateId: templateId,
      storeId: storeId
    }
    try {
      const { code, data } = await apis.offLine.getSignUpActivityList({
        ...commonParams,
        signUpTime,
        activityId
      })
      if (code === RESPONSE_CODE.SUCCESS) {
        this.dateTimeList = data
        this.currentActivityStatus = this.dateTimeList?.[0]?.activityStatus
        this.currentSignIndex = 0
        if (activityId) {
          this.currentSignIndex = this.dateTimeList.findIndex((item: any) => {
            return item?.activityId === activityId
          })
        }
        const currentItem = this.dateTimeList?.[this.currentSignIndex]
        this.currentActivityStatus = currentItem?.activityStatus
        this.currentSignUpStartTime = currentItem?.signUpStartTime
        // 当小孩每人限制最小预约人数为0时 且剩余小孩人数也为0
        if (
          Number(currentItem?.childLimitLeastQuantity) <= 0 &&
          currentItem?.childRemainQuantity === 0
        ) {
          this.childNum = 0
          return
        }

        if (currentItem?.childLimitQuantity > 0) {
          this.childNum = 1
        }
      }
    } catch (err) {}
  }

  async getStoreList() {
    try {
      const { chan_shop_id } = await getAppDataByBridge(['chan_shop_id'])

      // const chan_shop_id = '6580,6505,4830,1123'
      // const chan_shop_id = '4805,6543,8148,4809,6690,9990'
      // 是否为兜底地址
      const { isUltimateAddress } = await getDeliveryAddressLocation()
      // const isUltimateAddress = undefined
      // 为兜底地址时不调接口
      if (isUltimateAddress === true) {
        this.errorMaps[PAGE_NAME.LIST] = ERRORS_TYPE_ENUM.NO_ADDRESS
        return
      }
      const { code, data } = await apis.offLine.getActivityStoreList({
        storeIdList: chan_shop_id ? chan_shop_id.split(',') : []
      })
      if (code === RESPONSE_CODE.SUCCESS) {
        const { storeInfoList = [], cityName } = data
        if (storeInfoList.length === 0) {
          this.setErrorMap(PAGE_NAME.LIST, ERRORS_TYPE_ENUM.NO_ACTIVITY)
        }
        this.updateStoreIdList(storeInfoList.map((item: any) => item.storeId))
        this.updateCityName(cityName)
      } else {
        this.setErrorMap(PAGE_NAME.LIST, ERRORS_TYPE_ENUM.SYSTEM_ERROR)
      }
    } catch (error) {
      this.setErrorMap(PAGE_NAME.LIST, ERRORS_TYPE_ENUM.SYSTEM_ERROR)
    }
  }
  /**
   * 获取活动列表
   */
  async getActivityList() {
    if (this.lock) {
      return
    }
    if (this.storeIdList.length === 0) {
      // 没有门店 提示暂无活动
      this.setErrorMap(PAGE_NAME.LIST, ERRORS_TYPE_ENUM.NO_ACTIVITY)
      return
    }
    Toast.loading('')
    this.lock = true
    const params = {
      pageSize: 10,
      pageNum: this.listPage,
      storeIdList: this.storeIdList
    }
    try {
      const { code, data } = await apis.offLine.getActivityList(params)
      this.lock = false
      if (code === RESPONSE_CODE.SUCCESS) {
        const { end, pageList, totalCount } = data
        if (totalCount === 0) {
          this.setErrorMap(PAGE_NAME.LIST, ERRORS_TYPE_ENUM.NO_ACTIVITY)
        }
        this.updateList(concat(this.list, pageList))
        this.updateActivityListIsEnd(end)
        Toast.hide()
      } else {
        this.setErrorMap(PAGE_NAME.LIST, ERRORS_TYPE_ENUM.SYSTEM_ERROR)
      }
    } catch (err) {
      this.lock = false
      Toast.hide()
      this.setErrorMap(PAGE_NAME.LIST, ERRORS_TYPE_ENUM.SYSTEM_ERROR)
    }
  }
  getNextActivityPage() {
    if (!this.activityListIsEnd) {
      this.listPage = this.listPage + 1
      this.lock = false
      this.getActivityList()
    }
  }

  /**
   * 活动提醒
   */
  async queryActivityRemind() {
    const dateTemplate = 'YYYYMMDD'
    const now = dayjs().format(dateTemplate)
    const record = localStorage.getItem(REMIND_COOKIE_KEY) ?? ''
    if (now === record) {
      /** activityNum 大于0的情况下，一天只调用一次接口 */
      return
    }
    try {
      const { code, data } = await apis.offLine.activityRemind({})
      if (code === RESPONSE_CODE.SUCCESS) {
        const {
          activityNum,
          activityName,
          storeName,
          activityStartTime,
          activityEndTime,
          activityId
        } = data
        this.updateRemind({
          activityNum,
          activityName,
          storeName,
          activityStartTime,
          activityEndTime,
          activityId
        })
        if (activityNum > 0) {
          localStorage.setItem(REMIND_COOKIE_KEY, now)
        }
      }
    } catch (err) {}
  }

  /**
   * 待评价活动
   * activityId 为空时查最近一次的待评价活动 不为空时 查对应的活动是否需要评价
   */
  async queryBeRate(activityId?: string) {
    const dateTemplate = 'YYYYMMDD'
    const now = dayjs().format(dateTemplate)
    const record = localStorage.getItem(COMMENT_KEY) ?? ''
    if (now === record) {
      /** 显示过一次弹窗，就不调接口 */
      return
    }
    try {
      const { code, data } = await apis.offLine.getActivityInfo({ activityId, queryType: 1 })
      const { signInActivityAndCommentList } = data
      if (signInActivityAndCommentList && code === RESPONSE_CODE.SUCCESS) {
        const {
          images,
          activityId,
          activityTitle,
          activityStartTime,
          activityEndTime,
          activityCommentList
        } = signInActivityAndCommentList

        this.activityCommentList = activityCommentList
        this.commentActivityInfo = {
          images,
          activityId,
          activityTitle,
          activityStartTime,
          activityEndTime
        }
        this.updateCommentPopupVisible(true)
        localStorage.setItem(COMMENT_KEY, now)
        // const now = dayjs().format(dateTemplate)
        // const storage = localStorage.getItem(COMMENT_LIST_KEY)
        // let list = storage ? JSON.parse(storage) : []
        // if (!list[now]) {
        //   list = {
        //     [now]: []
        //   }
        // }
        // if (list[now].indexOf(activityId) === -1) {
        //   this.updateCommentPopupVisible(true)
        //   list[now].push(activityId)
        //   localStorage.setItem(COMMENT_LIST_KEY, JSON.stringify(list))
        // }
      }
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * 评价活动
   *
   */
  async doComment(score: number, activityId: string, commentWordIds: string[]) {
    try {
      const { code, data } = await apis.offLine.doComment({
        score,
        activityId,
        commentWordIds
      })
      if (code === RESPONSE_CODE.SUCCESS) {
        this.updateCommentStep(3)
      }
    } catch (err) {}
  }
  /**
   * 获取已报名活动列表
   */
  async getMyActivityList() {
    if (this.lock) {
      return
    }
    Toast.loading('')
    this.lock = true
    const params = {
      pageSize: 10,
      pageNum:
        this.myListActiveTab === MY_LIST_TYPE.PENDING ? this.pendingListPage : this.endListPage,
      type: this.myListActiveTab
    }
    try {
      const { code, data } = await apis.offLine.getUserActivityList(params)
      this.lock = false
      if (code === RESPONSE_CODE.SUCCESS) {
        const { end, pageList, totalCount } = data
        if (totalCount === 0) {
          this.setErrorMap(PAGE_NAME.MYLIST, ERRORS_TYPE_ENUM.NO_MY_ACTIVITY)
        } else {
          this.setErrorMap(PAGE_NAME.MYLIST, '')
        }
        if (this.myListActiveTab === MY_LIST_TYPE.PENDING) {
          this.myPendingList = concat(this.myPendingList, pageList)
          this.myPendingListEnd = end
        } else {
          this.myEndList = concat(this.myEndList, pageList)
          this.myEndListEnd = end
        }
        Toast.hide()
      } else {
        this.setErrorMap(PAGE_NAME.LIST, ERRORS_TYPE_ENUM.SYSTEM_ERROR)
      }
    } catch (err) {
      this.lock = false
      Toast.hide()
      this.setErrorMap(PAGE_NAME.MYLIST, ERRORS_TYPE_ENUM.SYSTEM_ERROR)
    }
  }

  getNextMyActivityPage() {
    if (this.myListActiveTab === MY_LIST_TYPE.PENDING) {
      if (!this.myPendingListEnd) {
        this.pendingListPage = this.pendingListPage + 1
        this.lock = false
        this.getMyActivityList()
      }
    }
    if (this.myListActiveTab === MY_LIST_TYPE.END) {
      if (!this.myEndListEnd) {
        this.endListPage = this.endListPage + 1
        this.lock = false
        this.getMyActivityList()
      }
    }
  }

  /**
   * 获取活动详情
   */
  async getActivityDetail() {
    Toast.loading('')
    const {
      urlData: { templateId, storeId, activityId, calendarTime, isSign }
    } = this
    const commonParams = {
      templateId: templateId,
      storeId: storeId,
      activityId,
      storeIdList: this.storeIdList
    }
    try {
      const { code, data } = await apis.offLine.getActivityDetail(commonParams, {
        level: 3
      })
      Toast.hide()
      if (code === RESPONSE_CODE.SUCCESS) {
        const newImages = data?.images ? JSON.parse(data?.images) : []
        this.detail = {
          ...data,
          images: newImages
        }
        // 可报名状态为1 才查询
        if (calendarTime) {
          this.currentDateIndex = this.detail?.signUpDate.findIndex((item: any) => {
            return dayjs(Number(item)).isSame(dayjs(Number(calendarTime)), 'day')
          })
        }
        Number(this.currentDateIndex) < 0 && (this.currentDateIndex = 0)
        this.detail.signUpStatus === SIGNUP_STATUS.CAN_APPLY &&
          this.getTimeList(this.detail?.signUpDate?.[this.currentDateIndex || 0], activityId)
        if ([SIGNUP_STATUS.IS_APPLY, SIGNUP_STATUS.NO_APPLY].includes(this.detail.signUpStatus)) {
          this.currentActivityStatus = this.detail.activityStatus
        }
        // 重复签到

        if (isSign) {
          Toast.text('您已签到成功, 请勿重复签到')
        }
      }
      this.handleCodeError(code, PAGE_NAME.DETAIL)
      this.detailLoading = false
    } catch (err) {
      Toast.hide()
      this.detailLoading = false
      this.handleCodeError((err as any)?.code, PAGE_NAME.DETAIL)
    }
  }

  /** 详情页获取报名时间无需鉴权
   *  @param signUpTime?: number
   *  @param activityId?: number
   */
  async getSignUpActivityListWithoutAuth(signUpTime?: string | number, activityId?: string) {
    const {
      urlData: { templateId, storeId }
    } = this
    const commonParams = {
      templateId: templateId,
      storeId: storeId
    }
    try {
      const { code, data } = await apis.offLine.getSignUpActivityListWithoutAuth({
        ...commonParams,
        signUpTime,
        activityId
      })
      if (code === RESPONSE_CODE.SUCCESS) {
        this.dateTimeList = data
        this.currentActivityStatus = this.dateTimeList?.[0]?.activityStatus
        this.currentSignIndex = 0
        if (activityId) {
          this.currentSignIndex = this.dateTimeList.findIndex((item: any) => {
            return item?.activityId === activityId
          })
        }
        const currentItem = this.dateTimeList?.[this.currentSignIndex]
        this.currentActivityStatus = currentItem?.activityStatus
        this.currentSignUpStartTime = currentItem?.signUpStartTime
        // 当小孩每人限制最小预约人数为0时 且剩余小孩人数也为0
        if (
          Number(currentItem?.childLimitLeastQuantity) <= 0 &&
          currentItem?.childRemainQuantity === 0
        ) {
          this.childNum = 0
          return
        }
        if (currentItem?.childLimitQuantity > 0) {
          this.childNum = 1
        }
      }
    } catch (err) {}
  }

  /**
   * 获取活动详情无需鉴权
   */
  async getActivityDetailWithoutAuth() {
    Toast.loading('')
    const {
      urlData: { templateId, storeId, activityId, calendarTime, isSign }
    } = this
    const commonParams = {
      templateId: templateId,
      storeId: storeId,
      activityId
    }
    try {
      const { code, data } = await apis.offLine.getActivityDetailWithoutAuth(commonParams, {
        level: 3
      })
      Toast.hide()
      if (code === RESPONSE_CODE.SUCCESS) {
        const newImages = data?.images ? JSON.parse(data?.images) : []
        this.detail = {
          ...data,
          images: newImages
        }
        // 可报名状态为1 才查询
        if (calendarTime) {
          this.currentDateIndex = this.detail?.signUpDate.findIndex((item: any) => {
            return dayjs(Number(item)).isSame(dayjs(Number(calendarTime)), 'day')
          })
        }
        Number(this.currentDateIndex) < 0 && (this.currentDateIndex = 0)
        this.detail.signUpStatus === SIGNUP_STATUS.SHARE_CAN &&
          this.getSignUpActivityListWithoutAuth(
            this.detail?.signUpDate?.[this.currentDateIndex || 0],
            activityId
          )
        if ([SIGNUP_STATUS.IS_APPLY, SIGNUP_STATUS.NO_APPLY].includes(this.detail.signUpStatus)) {
          this.currentActivityStatus = this.detail.activityStatus
        }
      }
      this.handleCodeError(code, PAGE_NAME.SHARE)
      this.detailLoading = false
    } catch (err) {
      Toast.hide()
      this.detailLoading = false
      this.handleCodeError((err as any)?.code, PAGE_NAME.SHARE)
    }
  }

  handleCodeError(code: string, page: PAGE_NAME) {
    if (code === 'InternalError') {
      this.setErrorMap(page, ERRORS_TYPE_ENUM.SYSTEM_ERROR)
    } else if (code === 'NETWORK_ERROR') {
      this.setErrorMap(page, ERRORS_TYPE_ENUM.NETWORK_ERROR)
    } else if (code === 'OFFLINE_NOT_TEMPLATE') {
      this.setErrorMap(page, ERRORS_TYPE_ENUM.SYSTEM_ERROR)
    }
  }

  /**
   * 报名取消报名
   * @param queryData
   */
  async updateSignStatus(queryData: SignParamsType) {
    Toast.loading('')
    this.signLoading = true
    const { activityId } = queryData
    try {
      const { code, msg, data } = await apis.offLine.userSignUp({
        ...queryData
      })
      if (code === RESPONSE_CODE.SUCCESS) {
        const { responseType, text } = data
        if (responseType === ResponseType.NO) {
          // 有爽约记录，报名失败
          this.updateApplyHintVisible(false)
          this.updateNoteTipsVisible(true)
          this.noteTipsText = text
          this.noteType = ''
          Toast.hide()
          element(
            {
              type: ElementType.Browse,
              elementId: 'reminder_pop',
              eventName: 'sam_h5_activePageDetail_reminder_pop_browse',
              pageType: '线下活动',
              pageTitle: '线下活动详情页',
              pageId: activityId
            },
            {
              business: {
                business_id: 'default',
                business_name: '爽约提示',
                business_type: '线下活动报名提醒弹窗'
              }
            }
          )
        } else {
          this.getActivityDetail()
          this.applyHintVisible = false
          setTimeout(() => {
            // 报名成功
            queryData.type === 0 && Toast.text('报名成功，我们活动上见')
            queryData.type === 1 && Toast.text('取消报名成功')
          }, 500)
          this.childNum = 0
          this.personNum = 1
          window.scrollTo(0, 0)
        }
      } else if (code === 'OFFLINE_SIGN_UP_TEMPLATE_CONFLICT') {
        // 已报名相同模板的活动，报名失败
        this.updateApplyHintVisible(false)
        this.updateNoteTipsVisible(true)
        Toast.hide()
        this.noteType = 'same'
        this.noteTipsText = ''
        element(
          {
            type: ElementType.Browse,
            elementId: 'reminder_pop',
            eventName: 'sam_h5_activePageDetail_reminder_pop_browse',
            pageType: '线下活动',
            pageTitle: '线下活动详情页',
            pageId: activityId
          },
          {
            business: {
              business_id: 'default',
              business_name: '活动模板冲突',
              business_type: '线下活动报名提醒弹窗'
            }
          }
        )
      } else {
        Toast.text(msg || '报名失败请重新报名')
      }

      this.signLoading = false
    } catch (err) {
      Toast.text((err as any)?.msg || '报名失败请重新报名')
      this.signLoading = false
    }
  }

  /**
   * 签到接口
   */

  async signActivity(templateId: string, activityId: string) {
    Toast.loading('')
    try {
      const { code, data, msg } = await apis.offLine.userSignIn(
        {
          templateId,
          activityId
        },
        {
          level: 3
        }
      )
      if (code === RESPONSE_CODE.SUCCESS) {
        this.signData = data
        if (data?.status === SIGN_ERROR_TYPE.REPEAT) {
          // 重复签到 跳转详情页
          location.replace(
            `${import.meta.env.VITE_DOMAIN}/sams/offline-activity/detail?storeId=${
              data?.storeId
            }&activityId=${data?.activityId}&templateId=${templateId}&isSign=true`
          )
        }
      } else if (code === 'InternalError') {
        this.setErrorMap(PAGE_NAME.SIGN, ERRORS_TYPE_ENUM.SYSTEM_ERROR)
      } else if (code === 'NETWORK_ERROR') {
        this.setErrorMap(PAGE_NAME.SIGN, ERRORS_TYPE_ENUM.NETWORK_ERROR)
      } else {
        Toast.text(msg || '签到失败, 请重新签到')
      }
      this.detailLoading = false
      Toast.hide()
    } catch (err) {
      Toast.hide()
      this.detailLoading = false
      if ((err as any)?.code === 'InternalError') {
        this.setErrorMap(PAGE_NAME.SIGN, ERRORS_TYPE_ENUM.SYSTEM_ERROR)
      } else if ((err as any)?.code === 'NETWORK_ERROR') {
        this.setErrorMap(PAGE_NAME.SIGN, ERRORS_TYPE_ENUM.NETWORK_ERROR)
      } else {
        Toast.text((err as any)?.msg || '签到失败, 请重新签到')
      }
    }
  }
  async setStatusBarHeight() {
    const { statusBarHeight } = await getAppDataByBridge(['statusBarHeight'])
    this.statusBarHeight = Number(statusBarHeight)
  }

  setCurrentSignIndex(num: number) {
    this.currentSignIndex = num
  }

  setCurrentDateIndex(num: number) {
    this.currentDateIndex = num
  }

  /** 增加成年人人数 */
  setPersonNum(num: number) {
    this.personNum = num
  }

  /** 减少成年人人数 */
  setChildNum(num: number) {
    this.childNum = num
  }

  /** 更新活动详情当前选择时间段活动状态 */
  upDateCurrentStatus(index: number) {
    this.currentActivityStatus = this.dateTimeList[index]?.activityStatus
    this.currentSignUpStartTime = this.dateTimeList[index]?.signUpStartTime
  }

  /** 关闭活动通知弹窗 */
  closeNotePopup() {
    this.updateRemind({
      activityNum: 0
    })
  }
  /** 更新我的报名tab */
  updateMyListActiveTab(tab: 0 | 1) {
    this.myListActiveTab = tab
  }

  setErrorMap(page: PAGE_NAME, type: ERRORS_TYPE_ENUM | '') {
    this.errorMaps[page] = type
  }
  updateActivityListIsEnd(end: boolean) {
    this.activityListIsEnd = end
  }
  updateList(list: any) {
    this.list = list
  }
  updateStoreIdList(storeIdList: [] | string[]) {
    this.storeIdList = storeIdList
  }
  updateRemind(remind: ActivityRemind) {
    this.remind = remind
  }
  updateCityName(cityName: string) {
    this.cityName = cityName
  }
  updateNoteTipsVisible(visible: boolean) {
    this.noteTipsVisible = visible
  }
  updateRateSelected(id: string) {
    const newData = this.commentSelected
    const findIndex = newData.indexOf(id)
    if (findIndex === -1) {
      newData.push(id)
    } else {
      newData.splice(findIndex, 1)
    }
    this.commentSelected = newData
  }
  updateCommentStep(step: number) {
    this.commentStep = step
  }
  updateCommentPopupVisible(visible: boolean) {
    sr.app.invoke('pullRefreshByH5', {
      pullRefreshByH5: visible
    })
    this.commentPopupVisible = visible
  }
}

export default new OfflineStore()
