import { apis } from '@/common/http-client'
import { InvoiceChannelEnum, RESPONSE_CODE, OrderTypeEnum, InvoiceTypeEnum } from '@/config'
import { concat, indexOf, update } from 'lodash-es'
import { makeAutoObservable } from 'mobx'
import { TitleTypeEnum } from '@/config/index'
import { Toast } from '@/components/Toast'
import { removeSpace } from '@/utils'

interface OrderOv {
  samsChannel: any
  orderNo: string
  paymentAmount?: string
  orderItemVOs: any[]
}
interface IvnoiceData {
  email?: string
  titleType: TitleTypeEnum
  buyerName?: string
  buyerTaxNo?: string
  sellerBankName?: string
  buyerBankAccount?: string
  sellerAddress?: string
  buyerPhone?: string
}

interface OrderNo {
  orderNo?: string
  invoiceAmount?: string
  category?: any[]
  subCategory?: any[]
}

class OrderStore {
  orderScene = OrderTypeEnum.ONLINE
  pageNum = 1
  totalPage = 1 // 总页数
  selectAllStatus = false
  orderList: OrderOv[] = []
  selectedOrderNo: OrderNo[] = []
  curInvoiceType = InvoiceTypeEnum.ORDINARY
  ivnoiceData: IvnoiceData = {
    titleType: TitleTypeEnum.COMPANY,
    buyerName: '',
    buyerTaxNo: '',
    email: '',
    sellerBankName: '',
    buyerBankAccount: '',
    sellerAddress: '',
    buyerPhone: ''
  }
  numberValidationMsg = '' // 识别号验证信息
  isCanOpenVAT = false // 是否能开增值税发票
  isNumberPassed = false // 识别号是否效验通过
  needSetValue = true
  apiLock = false

  constructor() {
    // console.log('init store')
    makeAutoObservable(this)
  }
  resetIvnoiceData() {
    this.ivnoiceData = {
      titleType: TitleTypeEnum.PERSONAL,
      buyerName: '',
      buyerTaxNo: '',
      email: '',
      sellerBankName: '',
      buyerBankAccount: '',
      sellerAddress: '',
      buyerPhone: ''
    }
  }
  updateNeedSetValue(setValue: boolean) {
    this.needSetValue = setValue
  }
  updateOrderScene(orderScene: OrderTypeEnum) {
    this.orderScene = orderScene
    this.orderList = []
    this.selectedOrderNo = []
    this.pageNum = 1
    this.totalPage = 1
  }
  updataTitleType(titleType: TitleTypeEnum) {
    this.ivnoiceData.titleType = titleType
  }
  saveIvnoiceData(data: IvnoiceData) {
    data.buyerName = data.buyerName ? removeSpace(data.buyerName) : ''
    data.buyerTaxNo = data.buyerTaxNo ? removeSpace(data.buyerTaxNo) : ''
    data.email = data.email ? removeSpace(data.email) : ''
    data.sellerBankName = data.sellerBankName ? removeSpace(data.sellerBankName) : ''
    data.buyerBankAccount = data.buyerBankAccount ? removeSpace(data.buyerBankAccount) : ''
    data.sellerAddress = data.sellerAddress ? removeSpace(data.sellerAddress) : ''
    data.buyerPhone = data.buyerPhone ? removeSpace(data.buyerPhone) : ''
    this.ivnoiceData = data
  }
  updateIvnoiceData(data: IvnoiceData) {
    this.ivnoiceData = data
  }
  updateNumberValidationMsg(data: string) {
    this.numberValidationMsg = data
  }
  updateIsCanOpenVAT(data: boolean) {
    this.isCanOpenVAT = data
  }
  updateIsNumberPassed(data: boolean) {
    this.isNumberPassed = data
  }
  updateInvoiceType(value: InvoiceTypeEnum) {
    this.curInvoiceType = value
  }
  // 获取发票信息
  async queryInvoice(orderNo: string) {
    Toast.loading('')
    try {
      const res = await apis.order.queryInvoice({
        orderNo
      })
      console.log(' res.data: ', res.data)
      Toast.hide()
      if (res.code === RESPONSE_CODE.SUCCESS) {
        const {
          titleType,
          buyerName,
          buyerTaxNo,
          email,
          sellerBankName,
          buyerBankAccount,
          sellerAddress,
          buyerPhone
        } = res.data
        this.updateIvnoiceData({
          ...this.ivnoiceData,
          titleType,
          buyerName,
          buyerTaxNo,
          email,
          sellerBankName,
          buyerBankAccount,
          sellerAddress,
          buyerPhone
        })
      }
    } catch (error) {
      Toast.hide()
    }
  }
  // 能否申请增值发票 or 效验增值税号（传入门店就效验门店，传入识别号就效验识别号）
  isCanAppreciationInvoice = async (
    params: { storeId?: string; purchaser?: string },
    callback?: (isCan: boolean) => void
  ) => {
    try {
      const res = await apis.order.isCanAppreciationInvoice({
        channel: '0', // 0:APP 1:小程序
        ...params
      })
      if (res.code === RESPONSE_CODE.SUCCESS) {
        const { success = false, samsInvoiceResultResp = {} } = res?.data
        this[params?.purchaser ? 'updateIsNumberPassed' : 'updateIsCanOpenVAT'](success)
        // 验证纳税识别号的情况
        if (params?.purchaser && success && samsInvoiceResultResp) {
          // 增值税号通过验证，保存发票数据
          this.saveIvnoiceData({
            ...this.ivnoiceData,
            buyerName: samsInvoiceResultResp?.purchaserName,
            buyerTaxNo: samsInvoiceResultResp?.purchaserTaxNo,
            sellerBankName: samsInvoiceResultResp?.purchaserBankName,
            buyerBankAccount: samsInvoiceResultResp?.purchaserBankAccount,
            sellerAddress: samsInvoiceResultResp?.purchaserAddress,
            buyerPhone: samsInvoiceResultResp?.purchaserTel
          })
          this.updateInvoiceType(InvoiceTypeEnum.VAT)
        }
        // 验证门店是否可开票的情况
        if (params?.storeId) {
          callback?.(success)
        }
      }
      if (res.msg) {
        this.updateNumberValidationMsg(res.msg)
      }
    } catch (err) {
      console.error(err)
    }
  }
  async queryList() {
    if (this.apiLock) {
      return
    }
    Toast.loading('')
    const pageSize = 10
    this.apiLock = true
    try {
      const res = await apis.order.queryOrderList({
        orderScene: this.orderScene,
        page: {
          pageNum: this.pageNum,
          pageSize
        }
      })
      Toast.hide()
      if (res.code === RESPONSE_CODE.SUCCESS) {
        const {
          data: { orders, totalCount }
        } = res

        // 计算商品数量
        orders.forEach((item: any, index: number) => {
          const { orderItemVOs } = item
          let buyQuantity = 0
          if (orderItemVOs) {
            orderItemVOs.forEach((item: any) => {
              buyQuantity = buyQuantity + item.buyQuantity
            })
          }
          orders[index].buyQuantity = buyQuantity
        })

        this.totalPage = Math.ceil(totalCount / pageSize)
        this.addOrderList(orders)
        this.apiLock = false
      }
    } catch (error) {
      Toast.hide()
    }
  }
  setOrderList(orders: OrderOv[]) {
    this.orderList = orders
  }
  nextPage() {
    if (this.pageNum <= this.totalPage && !this.apiLock) {
      this.pageNum++
      this.queryList()
    }
  }
  addOrderList(data: OrderOv[]) {
    this.orderList = concat(this.orderList, data)
    this.praseSelectAll()
  }
  selectNo(orderNo: OrderNo) {
    const selectedOrderNoList: Array<String | undefined> = []
    this.selectedOrderNo.map((item) => selectedOrderNoList.push(item.orderNo))
    const index = indexOf(selectedOrderNoList, orderNo.orderNo)
    if (index !== -1) {
      this.selectedOrderNo.splice(index, 1)
    } else {
      this.selectedOrderNo.push(orderNo)
    }
    this.praseSelectAll()
  }
  // 判断是否全选
  praseSelectAll() {
    // 超过30条就算全选
    this.selectAllStatus =
      this.selectedOrderNo.length === this.orderList.length || this.selectedOrderNo.length >= 30
  }
  selectAll() {
    let selectedOrderNo: OrderNo[] = []
    this.orderList.forEach((item, index) => {
      if (index < 30) {
        const category = item.orderItemVOs.map((item) => item.category || '')
        const subCategory = item.orderItemVOs.map((item) => item.subCategory || '')
        // 全选选择前30条
        selectedOrderNo.push({
          orderNo: item.orderNo,
          invoiceAmount: item.paymentAmount,
          category,
          subCategory
        })
      }
    })
    this.selectedOrderNo = selectedOrderNo
    this.selectAllStatus = true
  }
  toggleSelectAllStatus() {
    this.selectAllStatus = !this.selectAllStatus
  }
  clearAll() {
    this.selectedOrderNo = []
    this.selectAllStatus = false
  }
  getInvoiceData() {
    const {
      titleType,
      buyerName,
      buyerTaxNo,
      email,
      sellerBankName,
      buyerBankAccount,
      sellerAddress,
      buyerPhone
    } = this.ivnoiceData
    let returnData = {
      titleType,
      buyerName,
      buyerTaxNo,
      email,
      sellerBankName,
      buyerBankAccount,
      sellerAddress,
      buyerPhone
    }
    if (titleType === TitleTypeEnum.PERSONAL) {
      delete returnData.buyerTaxNo
    }
    console.log(returnData)
    return returnData
  }
}

export default new OrderStore()
