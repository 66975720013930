import { checkIDCard, checkCertificate, checkMTP, checkName, checkCardNo } from '@/utils'
import {
  RESPONSE_CODE,
  CloseSealPopupNextStepEnum,
  IsMemberEnum,
  IdType,
  TaskUIConfig,
  TaskConfig,
  StageStateEnum,
  TaskInterface,
  TaskTypeEnum,
  CouponData,
  TaskStatusEnum,
  QueryStatus,
  LotteryFailInfo
} from '@/config'
import { makeAutoObservable, runInAction } from 'mobx'
import { apis } from '@/common/http-client'

class TaskStore {
  showSwiper = true
  currentTask = 0 // 当前滑动到第几个任务
  showGuidePopup = false // 是否显示引导弹窗
  isTheLastSeal = false // 是否为最后一个印章
  queryCouponStatus = '' // 奖品信息查询是否结束
  hasError = false // 详情接口是否有报错
  needUpdateStatusTaskId = '' // 需要改变状态的任务id，开启push通知的弹窗用
  errorMsg = '' // 接口报错信息
  intervalId = 0 // 轮询id
  intervalCount = 0 // 轮询次数
  showBlack = false // 领取印章弹窗背面
  showSealPopup = false // 领取印章弹窗
  // showSealPopupBtnTips = false // 翻转更精彩tip
  showSealPopupCoupon = false // 印章弹窗是否显示顶部优惠券信息
  tipModalVisible = false // 提示弹窗（联系客服）
  updateAppVisible = false
  errorMsgVisible = false // 非会员不能参加活动弹窗显示隐藏
  confirmPushModalVisible = false // 确认push通知是否开启
  showGif = false // 优惠券弹窗底部撒花特性，1.12秒后消失
  setTimeoutId: any = 0
  closeSealPopupNextStep: CloseSealPopupNextStepEnum = CloseSealPopupNextStepEnum.NONE // 关闭印章弹窗以后的操作
  sealpopupData: TaskInterface = {
    taskAbbreviation: '',
    stampImg: '',
    taskId: '',
    taskName: '',
    taskNote: '',
    taskState: 0,
    taskReceiveState: false,
    taskLink: '',
    taskProgress: '',
    activityTaskId: '',
    taskButtonName: '',
    taskType: TaskTypeEnum.FIRST_ORDER,
    pageViewCurrentTime: 0,
    pageViewLimitTime: 0,
    pageCounts: 0
  } // 领取印章弹窗的数据
  showCouponPopup = false // 领取优惠券弹窗
  showlotteryFailPopup = false // 未中奖弹窗
  tipContent = '' // 提示弹窗的内容文案
  couponData: CouponData[] | [] = [
    {
      conditionValue: 1,
      amount: 1,
      prizeName: '优惠券名称',
      expireEnd: 0,
      expireStart: 0,
      writeOffChannelDescId: 1,
      prizeType: 1
    }
  ] // 优惠券弹窗里的奖品信息
  stageStateData: any = {} // 奖品信息的原始数据

  tabIndex = 0 // 展示哪个tab页
  uiConfig: TaskUIConfig = {
    // 活动页ui配置
    guideImg: '', // 引导图
    progressColor: '', // 进度条颜色
    progressBgColor: '', // 进度条背景色
    sealPopupBgImg: '', // 领取印章弹窗背景
    lotteryPopupSwitch: false, // 是否开启开奖动效
    lotteryEffectImg: '', // 开奖动效图

    mainVisual: '',
    mainVisualGif: '',
    stageBgColor: '',
    stageSelectedBgColor: '',
    stageFontColor: '',
    taskPoolBg: '',
    taskPoolTitleColor: '',
    taskPoolFontColor: '',
    taskListBgColor: '',
    btnToCompletedImg: '',
    btnToCompletedColor: '',
    btnReceiveImg: '',
    btnReceiveColor: '',
    sealPopupBtnImg: '',
    sealPopupBtnColor: '',
    sealPopupBtnText: '',
    sealPopupFontColor: '',
    couponPopupBgImg: '',
    couponPopupFontColor: '',
    couponPopupBtnColor: '',
    stampTurnSwitch: false,
    couponPopupLinkColor: '',
    btnPrizeHintBgColor: '', // 领奖提示背景颜色
    btnPrizeHintTextColor: '', // 领奖提示文案颜色
    hasExhibit: '',
    awardImg: '',
    percent100PrizeBgColor: '',
    luckyPrizeBgColor: '',
    prizeTitleBgColor: '',
    prizeTitleTextColor: '',
    countdownBgColor: '',
    countdownTextColor: ''
  }

  lotteryFailInfo: LotteryFailInfo = {
    lotteryFail: false,
    image: '',
    desc: ''
  }

  // 任务配置
  taskConfig: TaskConfig = {
    // activityRuleId: ,
    activityName: '',
    activityId: '',
    activityStages: [],
    memType: 0,
    prizePool: { minimumPrizes: [], randomPrizes: [] },
    beginTime: '',
    endTime: ''
  } // 任务配置

  // 轮播数据
  carouselData: any = [{ prizeName: '', usernamets: '' }]

  constructor() {
    // console.log('init store')
    makeAutoObservable(this)
  }
  updateShowBlack(value: boolean) {
    this.showBlack = value
  }
  // 更新优惠券信息
  updateCouponData(data: CouponData[]) {
    this.updateCouponStatus(QueryStatus.DONE)
    this.couponData = data
  }
  // 更新queryStageState查回来的奖品信息
  updateStageState(data: any) {
    this.stageStateData = data.stage
  }

  // 更新未中奖信息
  updatLotteryFailInfo(lotteryFailInfo: any) {
    this.lotteryFailInfo = lotteryFailInfo
  }

  // updateShowSealPopupBtnTips(value: boolean) {
  //   this.showSealPopupBtnTips = value
  // }
  updateShowSealPopupCoupon(value: boolean) {
    this.showSealPopupCoupon = value
  }
  updateIsTheLastSeal(value: boolean) {
    this.isTheLastSeal = value
  }
  updateCouponStatus(status: QueryStatus) {
    console.log('查询奖品信息updateCouponStatus', status)
    this.queryCouponStatus = status
  }

  updateNextStep(step: CloseSealPopupNextStepEnum) {
    console.log('关闭印章弹窗以后的操作', step)
    this.closeSealPopupNextStep = step
  }
  setTipModalVisible(visible: boolean) {
    this.tipModalVisible = visible
  }
  setShowGuidePopup(visible: boolean) {
    this.showGuidePopup = visible
  }
  setUpdateAppVisible(visible: boolean) {
    this.updateAppVisible = visible
  }
  setErrorMsgVisible(visible: boolean) {
    this.errorMsgVisible = visible
  }
  setPushModalVisible(visible: boolean) {
    this.confirmPushModalVisible = visible
  }
  updateTipContent(msg: string) {
    this.updateNextStep(CloseSealPopupNextStepEnum.TIP)
    this.tipContent = msg
  }
  updateIntervalId(id: any) {
    this.intervalCount = 0
    this.intervalId = id
  }
  updateIntervalCount() {
    this.intervalCount = this.intervalCount + 1
    // 只轮询奖品信息3次
    if (this.intervalCount === 3 && this.intervalId) {
      // 更新查询状态为已完成
      this.updateCouponStatus(QueryStatus.DONE)
      clearInterval(this.intervalId)
    }
  }
  // 更新领取印章弹窗数据
  updateSealPopupData(data: TaskInterface) {
    this.sealpopupData = data
  }
  // 更新指定任务的数据
  updateTaskDataById(activityTaskId: string, data: TaskInterface) {
    // activityTaskId 不会重复，taskid可能重复
    const taskConfig = this.taskConfig
    const { activityStages } = taskConfig
    // 从所有任务里找到要更改状态的那个任务
    activityStages.forEach((item, index) => {
      const { activityTasks } = item
      activityTasks.forEach((taskItem, taskIndex) => {
        if (taskItem.activityTaskId === activityTaskId) {
          // 更改任务状态
          activityStages[index].activityTasks[taskIndex] = data
        }
      })
    })
    taskConfig.activityStages = activityStages
    this.updateTaskData(taskConfig)
    // this.taskConfig = taskConfig
  }
  // 更新任务状态
  updateTaskStatus(activityTaskId: string, status: TaskStatusEnum) {
    // activityTaskId 不会重复，taskid可能重复
    const taskConfig = this.taskConfig
    const { activityStages } = taskConfig
    // 从所有任务里找到要更改状态的那个任务
    activityStages.forEach((item, index) => {
      const { activityTasks } = item
      activityTasks.forEach((taskItem, taskIndex) => {
        if (taskItem.activityTaskId === activityTaskId) {
          // 更改任务状态
          activityStages[index].activityTasks[taskIndex].taskState = status
        }
      })
    })
    taskConfig.activityStages = activityStages
    this.updateTaskData(taskConfig)
    // this.taskConfig = taskConfig
  }
  // 更新任务数据
  updateTaskData(data: TaskConfig) {
    const { activityStages } = data
    activityStages.forEach((item, index) => {
      const { activityTasks } = item
      let completeTaskNum = 0 // 已完成的任务数量
      activityTasks.forEach((taskItem, taskIndex) => {
        if (
          item.stageState === StageStateEnum.PROCESSING &&
          taskItem.taskState === TaskStatusEnum.NOTOPENED &&
          taskItem.isNeedDraw === 1
        ) {
          // 如果IsNeedDraw为1 status 为0 代表任务待领取
          activityStages[index].activityTasks[taskIndex].taskState =
            TaskStatusEnum.AWAITINGACTIVATION
        }
        if (taskItem.taskState === TaskStatusEnum.COMPLETED) {
          completeTaskNum++
        }
      })
      activityStages[index].completeTaskNum = completeTaskNum
    })
    data.activityStages = activityStages
    this.taskConfig = data
  }

  // 更新任务数据
  updateCarouselData(data: any) {
    const { list = [] } = data
    this.carouselData = list
  }

  setTaskId(activityTaskId: string) {
    this.needUpdateStatusTaskId = activityTaskId
  }
  setCurrentTask(current: number) {
    this.currentTask = current
  }
  updateUiData(data: TaskConfig) {
    const pageConfigInfo = JSON.parse(data.pageConfigInfo)
    console.log('模板数据', pageConfigInfo)
    const {
      home: { page, stageTheme, taskPool, receiveStampPopup, couponPopup, lotteryInfo }
    } = pageConfigInfo
    this.uiConfig = {
      guideImg: page.guideImg, // 引导图
      progressColor: stageTheme.progressColor?.hex, // 进度条颜色
      progressBgColor: stageTheme.progressBgColor?.hex, // 进度条背景色
      sealPopupBgImg: receiveStampPopup.popupBgImg, // 领取印章弹窗背景
      lotteryPopupSwitch: couponPopup.lotteryPopupSwitch, // 是否开启开奖动效
      lotteryEffectImg: couponPopup.lotteryEffectImg, // 开奖动效图
      mainVisual: page.img, // 主视觉图
      mainVisualGif: page.dynamicImg, // 主视觉图动效
      stageBgColor: stageTheme.unSelectBgColor?.hex, // 阶段背景颜色 -非选中状态
      stageSelectedBgColor: stageTheme.selectBgColor?.hex, //  阶段背景颜色 -选中状态
      stageFontColor: stageTheme.textColor.hex, // 阶段文字颜色
      taskPoolBg: taskPool.bgImg, // 任务池背景图片
      taskPoolTitleColor: taskPool.titleColor?.hex, // 任务池标题文案颜色
      taskPoolFontColor: taskPool.textColor?.hex, // 任务池文案颜色
      taskListBgColor: taskPool.listBgColor?.hex, // 任务列表背景颜色
      btnToCompletedImg: taskPool.todoButtonImg, // 任务按钮图片-待完成
      btnToCompletedColor: taskPool.todoButtonTextColor?.hex, // 任务按钮-待完成 -文字颜色
      btnReceiveImg: taskPool.receiveButtonImg, // 任务按钮图片-领取
      btnReceiveColor: taskPool.receiveButtonTextColor?.hex, // 任务按钮-领取 -文字颜色
      sealPopupBtnText: receiveStampPopup.popupButtonText, // 领取印章弹窗按钮文案
      sealPopupBtnImg: receiveStampPopup.popupButtonImg, // 领取印章弹窗按钮图片
      sealPopupBtnColor: receiveStampPopup.popupButtonTextColor?.hex, // 领取印章弹窗按钮文案颜色
      sealPopupFontColor: receiveStampPopup.popupTextColor.hex, // 领取印章弹窗文案颜色
      stampTurnSwitch: receiveStampPopup.stampTurnSwitch || false, // 印章翻转开关
      couponPopupBgImg: couponPopup.couponBgImg, // 优惠券弹窗单张券时的背景图片
      couponPopupFontColor: couponPopup.popupTextColor?.hex, // 优惠券弹窗文字颜色
      couponPopupBtnColor: couponPopup.useTextColor?.hex, // 优惠券弹窗立即使用文案颜色
      couponPopupLinkColor: couponPopup.queryCouponTextColor?.hex, // 优惠券弹窗查看优惠券文案颜色
      btnPrizeHintBgColor: stageTheme.prizeHintBgColor?.hex, // 请选择领奖提示背景颜色
      btnPrizeHintTextColor: stageTheme.prizeHintTextColor?.hex, // 领奖提示文案颜色
      awardImg: page.hasExhibit, // 是否显示奖励池 ：'1'-是 ， '0'-否 ；
      hasExhibit: page.hasExhibit, // 保底奖励视觉图
      percent100PrizeBgColor: stageTheme.percent100PrizeBgColor?.hex, // 100%保底奖励背景色
      luckyPrizeBgColor: stageTheme.luckyPrizeBgColor?.hex, // 幸运抽奖池背景色
      prizeTitleBgColor: stageTheme.prizeTitleBgColor?.hex, // 普通奖品标题栏背景色
      prizeTitleTextColor: stageTheme.prizeTitleTextColor?.hex, // 普通奖品标题文案颜色
      countdownBgColor: stageTheme.countdownBgColor?.hex, // 倒计时背景颜色
      countdownTextColor: stageTheme.countdownTextColor?.hex, // 倒计时文字颜色
      lotteryFailImage: lotteryInfo.lotteryFailImage, // 谢谢参与奖励图片
      lotteryFailDesc: lotteryInfo.lotteryFailDesc // 谢谢参与奖励文案
    }
  }
  async queryData(id: string) {
    let res
    try {
      const resp = await apis.task.queryDetail({ activityId: id })
      if (resp.code === RESPONSE_CODE.SUCCESS) {
        res = resp
      }
    } catch (error: any) {
      res = error.response.data
    }

    return res
  }
  // 查询中奖信息  轮播用
  async queryPrizeRecord(id: string) {
    try {
      const { code, data, msg } = await apis.task.getLatestPrizeRecord({ activityId: id })
      if (code === RESPONSE_CODE.SUCCESS) {
        this.updateErrorStatus(false)
        this.updateCarouselData(data)
        this.updateUiData(data)
      } else if (code === 'REQUIRED_MEMBERSHIP') {
        this.updateErrorStatus(true)
        this.setErrorMsgVisible(true)
      } else {
        console.log(msg)
      }
    } catch (error: any) {
      console.log(error)
    }
  }

  async queryTaskData(id: string, needUpdateTabIndex = false) {
    try {
      const { code, data, msg } = await apis.task.queryDetail({ activityId: id })
      if (code === RESPONSE_CODE.SUCCESS) {
        this.updateErrorStatus(false)
        this.updateTaskData(data)
        this.updateUiData(data)

        if (needUpdateTabIndex) {
          this.setAutoTab()
        }

        // if (data) {
        //   await apis.task.queryDetail({ activityId: id })
        // }
      } else if (code === 'REQUIRED_MEMBERSHIP') {
        this.updateErrorStatus(true)
        this.setErrorMsgVisible(true)
      } else {
        this.updateErrorStatus(true)
        this.errorMsg = msg
      }
    } catch (error: any) {
      console.log(error)
      const { msg } = error
      this.updateErrorStatus(true)
      this.errorMsg = msg ? msg : '获取活动详情失败，请重试'
    }
  }

  // 查询奖品状态
  async queryStage(activityId: string, stageId: string) {
    try {
      const { code, data } = await apis.task.queryStageState({ activityId, stageId })
      if (code === RESPONSE_CODE.SUCCESS) {
        this.updateStageState(data)
        // 问题3
        // this.updateCouponData([...data.stage.minimumPrizes, ...data.stage.randomPrizes])
      }
    } catch (error) {}
  }

  async getUserAward(id: string, needUpdateTabIndex = false) {
    try {
      let { code, data, msg } = await apis.task.getUserAward({ activityId: id })
      let prizesData: any = { minimumPrizes: [], randomPrizes: [] }

      const flag = data?.stages.every(
        (item: any) => item.minimumPrizes?.length === 0 && item.randomPrizes?.length === 0
      )
      if (flag) {
        throw new Error('')
      }
      if (code === RESPONSE_CODE.SUCCESS) {
        data?.stages?.forEach((item: any) => {
          if (item.minimumPrizes?.length > 0) {
            item.minimumPrizes.map((node: any) => {
              node.stageId = item.stageId
              return node
            })
            prizesData.minimumPrizes = [...prizesData.minimumPrizes, ...item.minimumPrizes]
          }
          if (item.randomPrizes?.length > 0) {
            item.randomPrizes.map((node: any) => {
              node.stageId = item.stageId
              return node
            })
            prizesData.randomPrizes = [...prizesData.randomPrizes, ...item.randomPrizes]
          }
        })
        this.updateErrorStatus(false)
        if (needUpdateTabIndex) {
          this.setAutoTab()
        }
      } else if (code === 'REQUIRED_MEMBERSHIP') {
        this.updateErrorStatus(true)
        this.setErrorMsgVisible(true)
      } else {
        this.updateErrorStatus(true)
        this.errorMsg = msg
      }
      prizesData.activityName = data.activityName
      prizesData.productPrizeInfo = data.productPrizeInfo
      return prizesData
    } catch (error: any) {
      console.log(error)
      const { msg } = error
      this.updateErrorStatus(true)
      this.errorMsg = msg ? msg : '暂无活动奖励'
    }
  }

  updateErrorStatus(status: boolean) {
    this.hasError = status
  }

  // 默认展示进行中的阶段
  setAutoTab() {
    const { activityStages } = this.taskConfig
    activityStages.forEach((item: any, index: number) => {
      if (item.stageState === StageStateEnum.PROCESSING) {
        this.setTabIndex(index)
      }
    })
  }
  setTabIndex(index: number) {
    if (this.tabIndex !== index) {
      this.showSwiper = false
      setTimeout(() => {
        this.showSwiper = true
      })
      this.currentTask = 0
      this.tabIndex = index
    }
  }
  setShowSealPopup(show: boolean) {
    this.showSealPopup = show
    this.showBlack = false // 弹窗初始显示正面
  }

  setLotteryFailPopup(show: boolean) {
    this.showlotteryFailPopup = show
    this.setShowGif(show)
    if (show) {
      // 打开弹窗时 1.12秒后隐藏撒花动效
      this.setTimeoutId = setTimeout(() => {
        this.setShowGif(false)
      }, 1120)
    } else {
      // 关闭弹窗时，清除定时器
      if (this.setTimeoutId) {
        clearTimeout(this.setTimeoutId)
      }
    }
    // 显示优惠券弹窗时禁用下拉刷新，关闭时恢复
    sr.app.invoke('pullRefreshByH5', {
      pullRefreshByH5: show
    })
  }

  setShowGif(show: boolean) {
    this.showGif = show
  }
  setShowCouponPopup(show: boolean) {
    this.showCouponPopup = show
    this.setShowGif(show)
    if (show) {
      // 打开弹窗时 1.12秒后隐藏撒花动效
      this.setTimeoutId = setTimeout(() => {
        this.setShowGif(false)
      }, 1120)
    } else {
      // 关闭弹窗时，清除定时器
      if (this.setTimeoutId) {
        clearTimeout(this.setTimeoutId)
      }
    }
    // 显示优惠券弹窗时禁用下拉刷新，关闭时恢复
    sr.app.invoke('pullRefreshByH5', {
      pullRefreshByH5: show
    })
  }
}

export default new TaskStore()
