import defaultAvatar from '@/assets/defaultAvatar.svg'
import { getRealPxByPx, pxToViewport } from '@/utils/px-to-viewport'
import qrCode from 'qrcode'
import {
  CreateCanvasParams,
  DrawRectParams,
  DrawParams,
  DrawTextParams,
  DrawQueue,
  DrawQrParams,
  DrawTextListParams,
  LineParams
} from './types'
import { IMAGE_SERVER_DOMAIN } from '@/config/constants'

export interface ImageCompressOptions {
  /** 缩小图片宽高 */
  thumbnail?: string
  /** 失败时是否直接返回原图，1代表返回原图，0代表不返回原图 */
  'ignore-error'?: number
  /** 降低图片质量，取值1-100 */
  quality?: number
  /** 限制图片大小（此属性只对jpg有用，所以用它时建议加上 format: 'jpg'） */
  'size-limit'?: string
  /** 转化图片格式 */
  format?: string
  /** 去除图片元信息 */
  strip?: boolean
  /** 根据原图EXIF信息将图片自适应旋转回正 */
  'auto-orient'?: boolean
  /** 是否转化gif */
  isIgnoreGif?: boolean
}
const defaultImageCompressOptions = {
  'ignore-error': 1,
  strip: true,
  'auto-orient': true
}

/** 获取压缩后的图片路径 */
export function getImageUrlCompressed(url?: string, options?: ImageCompressOptions) {
  if (!url) return ''
  if (!options) return url
  const { isIgnoreGif = true } = options
  if (isIgnoreGif && /\.gif/.test(url)) return url // 本项目不对gif做任何处理

  // 如果不是腾讯云上的图片，则直接返回
  if (!new RegExp(IMAGE_SERVER_DOMAIN).test(url)) {
    return url
  }

  const realOptions = { ...defaultImageCompressOptions, ...options }

  let result = url
  const optionList = Object.entries(realOptions)
  if (optionList.length > 0) {
    result = `${result}?imageMogr2`
    optionList.forEach(([key, value]) => {
      if (typeof value === 'boolean') {
        // 如果value是布尔值，则value只用来控制key是否显示，value本身不显示
        result = `${result}${value ? `/${key}` : ''}`
      } else {
        result = `${result}/${key}/${value}`
      }
    })
  }
  return result
}

/** 用于绘制分享海报图的canvas工具类 */
export class CreateCanvas {
  canvas: HTMLCanvasElement
  ctx: CanvasRenderingContext2D
  scale: number
  defaultAvatar: string
  isDrawing: boolean
  queue: DrawQueue[]

  distance = 0

  constructor(params: CreateCanvasParams) {
    const { width, height, backgroundColor, borderRadius } = params
    /** 缩放比例，解决canvas生成图片模糊问题 */
    this.scale = 2
    /** 默认兜底头像 */
    this.defaultAvatar = defaultAvatar
    /** 是否绘制中 */
    this.isDrawing = false
    /** 绘图队列 */
    this.queue = []
    // 初始化
    this.canvas = document.createElement('canvas')
    this.canvas.style.width = pxToViewport(width) as string
    this.canvas.style.height = pxToViewport(height) as string
    // canvas画布的实际像素宽高
    this.canvas.width = getRealPxByPx(width) * this.scale
    this.canvas.height = getRealPxByPx(height) * this.scale
    // canvas2d对象
    this.ctx = this.canvas.getContext('2d') as CanvasRenderingContext2D
    // 渲染画布背景
    if (backgroundColor) {
      this.drawRect({ x: 0, y: 0, width, height, backgroundColor, borderRadius, isGlobal: true })
    }
  }

  transformNumber(num: number) {
    return getRealPxByPx(num) * this.scale
  }

  drawRect(params: DrawRectParams) {
    if (this.isDrawing) {
      this.queue.push({
        rect: true,
        params
      })
      return
    }
    this.isDrawing = true

    const realParams: DrawRectParams = { ...params }
    Object.entries(params).forEach(([key, value]) => {
      if (typeof value === 'number') {
        // @ts-ignore：重组params
        realParams[key] = this.transformNumber(value)
      }
    })

    const { width: w, height: h, x, y, backgroundColor, isGlobal = false } = realParams
    let { borderRadius: r } = realParams
    if (backgroundColor) this.ctx.fillStyle = backgroundColor
    if (r) {
      if (!isGlobal) this.ctx.save()
      if (w < 2 * r) r = w / 2
      if (h < 2 * r) r = h / 2
      this.ctx.beginPath()
      this.ctx.moveTo(x + r, y)
      this.ctx.arcTo(x + w, y, x + w, y + h, r)
      this.ctx.arcTo(x + w, y + h, x, y + h, r)
      this.ctx.arcTo(x, y + h, x, y, r)
      this.ctx.arcTo(x, y, x + w, y, r)
      this.ctx.closePath()
      this.ctx.clip()
    }
    this.ctx.fillRect(x, y, w, h)
    if (!isGlobal) this.ctx.restore()
    this.drawEnd()
  }

  // 绘图函数
  drawImage(src: string, params: DrawParams) {
    if (this.isDrawing) {
      this.queue.push({ src, params })
      return
    }

    this.isDrawing = true
    const img = new Image()
    img.setAttribute('crossOrigin', 'anonymous')
    img.src = src
    img.onload = () => {
      // 默认配置参数
      const defaultOption = {
        x: 0,
        y: 0,
        width: 0,
        height: 0
      }
      Object.assign(defaultOption, params)
      this.ctx.restore()
      this.ctx.save()
      // 头像裁剪
      if (params.radius) {
        this.drawRadius()
        this.ctx.clip()
      }
      this.ctx.drawImage(
        img,
        this.transformNumber(defaultOption.x),
        this.transformNumber(defaultOption.y),
        this.transformNumber(defaultOption.width),
        this.transformNumber(defaultOption.height)
      )
      // 触发绘图结束，遍历队列，进行下一个绘图处理
      this.drawEnd()
    }
  }

  // 绘制头像外圆
  drawRadius() {
    this.ctx.beginPath()
    this.ctx.fillStyle = '#FFFFFF'
    this.ctx.arc(36 * this.scale, 258 * this.scale, 22 * this.scale, 0, Math.PI * 2, false)
    this.ctx.fill()
    // 绘制头像内圆
    this.ctx.beginPath()
    this.ctx.fillStyle = '#EFEFEF'
    this.ctx.arc(36 * this.scale, 258 * this.scale, 20 * this.scale, 0, Math.PI * 2, false)
    this.ctx.fill()
  }

  // 绘图通用处理函数
  drawEnd() {
    this.isDrawing = false
    const queueImg = this.queue.shift()
    if (queueImg) {
      const { src, params, text = '', rect, line, callback } = queueImg
      if (callback) {
        callback.apply(this, [text, params])
      } else if (src) {
        this.drawImage(src, params)
      } else if (text) {
        this.drawText(text, params)
      } else if (rect) {
        this.drawRect(params as DrawRectParams)
      } else if (line) {
        this.drawLine(params)
      }
    }
  }

  // 绘字函数
  drawText(text: string, params: DrawTextParams) {
    if (this.isDrawing) {
      this.queue.push({
        text,
        params
      })
      return
    }
    this.isDrawing = true
    // 默认配置参数
    const defaultOption = {
      fontSize: 24,
      fontWeight: 'normal',
      fontFamily: 'Arial',
      lineHeight: 24,
      fillStyle: '#000',
      textAlign: 'left',
      textBaseline: 'middle',
      maxLine: 1
    }
    // 将参数里的尺寸值都转换一遍
    const options = { ...defaultOption, ...params }
    ;['fontSize', 'lineHeight', 'maxWidth'].forEach((key) => {
      // @ts-ignore：重组params
      options[key] = this.transformNumber(options[key])
    })
    const {
      fontSize,
      fontWeight,
      fontFamily,
      lineHeight,
      maxWidth,
      maxLine,
      x,
      y,
      noTransform = false,
      ...otherOption
    } = options
    // 处理定位
    const x1 = !noTransform ? this.transformNumber(x) : x
    const y1 = this.transformNumber(y + fontSize / 2 + (lineHeight - fontSize) / 2)
    // 处理字体样式
    this.ctx.font = `${fontWeight} ${fontSize}px ${fontFamily}`
    Object.entries(otherOption).forEach(([key, value]) => {
      // @ts-ignore：重组params
      this.ctx[key] = value
    })
    // 处理换行
    if (maxWidth && this.ctx.measureText(text).width > maxWidth) {
      /** 存放每行应该渲染的文本 */
      const lines: string[] = []
      /** 标志位：当前所在的行数 */
      let line = 0
      for (const str of text) {
        const { width: lineWidth } = this.ctx.measureText(lines[line] + str)
        /** 是否超出最大宽度 */
        if (lineWidth >= maxWidth) {
          /** 是否超出最大行数 */
          if (line + 1 >= maxLine && str) {
            lines[line] = lines[line].substring(0, lines[line].length - 1) + '...'
            break
          }
          line += 1
        }

        lines[line] = (lines[line] || '') + str
      }

      // 遍历lines渲染每行的文本
      lines.forEach((lineText, i) => {
        this.ctx.fillText(lineText.trim(), x1, y1 + i * lineHeight)
      })
    } else {
      this.ctx.fillText(text, x1, y1)
    }
    this.drawEnd()
  }

  /**
   * 绘制单行多段文字 目前只适用于会员指南海报 */
  drawLineText(texts: DrawTextListParams[]) {
    let totalWidth = 0
    texts.forEach((item) => {
      const defaultOption = {
        fontSize: 24,
        fontWeight: 'normal',
        fontFamily: 'Arial'
      }
      const { linePadding = 0 } = item.params
      const options = { ...defaultOption, ...item.params }

      // 转换一下字体大小
      options['fontSize'] = this.transformNumber(options['fontSize'])
      const { fontSize, fontWeight, fontFamily } = options
      this.ctx.font = `${fontWeight} ${fontSize}px ${fontFamily}`
      // 计算当前文本宽度
      const textWidth = this.ctx.measureText(item.text).width
      const padding = linePadding
      // 左右padding
      totalWidth += textWidth + padding
    })

    let startX = (this.canvas.width - totalWidth) / 2

    texts.forEach((item) => {
      const { linePadding = 0 } = item.params
      const padding = linePadding || 0
      const text = item.text
      startX += padding
      // 绘制文本
      this.drawText(text, {
        ...item.params,
        x: startX,
        //  换成真实像素会导致 有误差变大 前面计算已经是真实像素了
        noTransform: true
      })

      const defaultOption = {
        fontSize: 24,
        fontWeight: 'normal',
        fontFamily: 'Arial'
      }
      const options = { ...defaultOption, ...item.params }
      // 转换一下字体大小
      options['fontSize'] = this.transformNumber(options['fontSize'])
      const { fontSize, fontWeight, fontFamily } = options
      this.ctx.font = `${fontWeight} ${fontSize}px ${fontFamily}`
      // 计算当前文本宽度
      const textWidth = this.ctx.measureText(text).width
      // 下一段文本 起始文字
      startX += textWidth + padding
    })
  }

  /**
   * 绘制虚线
   */
  drawLine(params: LineParams) {
    if (this.isDrawing) {
      this.queue.push({ params, line: true })
      return
    }
    const { borderColor, borderWidth, x, y, lineWidth = 100 } = params
    this.ctx.strokeStyle = borderColor || '#eee'
    this.ctx.lineWidth = borderWidth || 1
    this.ctx.setLineDash([10, 5])
    // 开始绘制路径
    this.ctx.beginPath()
    // 移动到起点
    this.ctx.moveTo(this.transformNumber(x), this.transformNumber(y))
    // 绘制到终点
    this.ctx.lineTo(this.transformNumber(x + lineWidth), this.transformNumber(y))
    this.ctx.stroke()
    this.drawEnd()
  }

  // 绘制头像
  drawAvatar(headUrl: string, params: DrawParams) {
    params.radius = 20
    this.drawImage(headUrl || this.defaultAvatar, params)
  }

  drawQrCode(params: DrawQrParams) {
    const { url, ...rest } = params
    try {
      qrCode.toDataURL(
        url,
        {
          margin: 0,
          errorCorrectionLevel: 'L'
        },
        (err: Error, url: string) => {
          this.drawImage(url, rest)
        }
      )
    } catch (e) {
      console.error(e)
    }
  }

  // 保存canvas，并追加到dom
  show(target: HTMLBaseElement) {
    if (target) {
      target.appendChild(this.canvas)
    } else {
      const div = document.createElement('div')
      div.style.position = 'fixed'
      div.style.left = '0'
      div.style.top = '0'
      div.style.width = '100%'
      div.style.height = '100%'
      div.style.zIndex = '5'
      div.appendChild(this.canvas)
      document.body.appendChild(div)
    }
  }

  toBase64(callback: Function, type = 'image/jpeg') {
    let timeOut = setInterval(() => {
      if (!this.isDrawing && this.queue.length === 0) {
        const base64 = this.canvas.toDataURL(type, 0.5)
        callback(base64)
        clearInterval(timeOut)
      }
    }, 300)
  }
}
