/**
 * 根据 api 配置文件，注册 API 方法
 */

import { HttpClient } from '@tencent/retailwe-common-libs-http-client'
import { api } from '@/config/api'

const DEFAULT_FLY_CONFIG = {
  method: 'POST'
}

export default function registerAPIs(httpClient: HttpClient) {
  function loop(obj: any) {
    // 字符串类型，注册API
    if (typeof obj === 'string') {
      return httpClient.registerApi({ url: obj, config: DEFAULT_FLY_CONFIG })
    }

    // 对象类型，深度遍历
    if (typeof obj === 'object') {
      // 如果是 API 类型的obj，注册 API
      if (obj.url) {
        obj.config = { ...DEFAULT_FLY_CONFIG, ...(obj.config || {}) }
        return httpClient.registerApi(obj)
      }

      // 仅考虑简单嵌套的深度遍历
      const myObj: any = {}
      for (const key in obj) {
        if (obj[key]) myObj[key] = loop(obj[key])
      }

      return myObj
    }

    return undefined
  }

  const apis: any = loop(api)

  return apis
}
